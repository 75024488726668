import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import DetailControllerService from "../../../../API/details/DetailControllerService";
import {constantsErrorMessages} from "../../../../Constants";
import CustomerRequestsCuttingEstimateItem from "../../../customer/orders/CustomerRequestsCuttingEstimateItem";
import CustomerDetailsTable from "../../../../components/UI/table/customer-detales/CustomerDetailsTable";
import EstimateItem from "../estimate/EstimateItem";
import CustomerRequestsCuttingService
    from "../../../../API/orders/optimization/customer/CustomerRequestsCuttingService";
import TechnicalMapTable from "../../../../components/UI/table/orders/organization/technicalmaptable/TechnicalMapTable";
import JobService from "../../../../API/orders/job/JobService";

const OrderCloudItem = () => {

        const params = useParams();
        const navigate = useNavigate();

        const [value, setValue] = useState('6');
        const year = params.year;
        const customerOrderNumber = params.customerOrderNumber;
        const orderId = params.orderId;
        const orderIdHeader = params.idHeader;
        const [change, setChange] = useState(null);

        const redirectOrders = () => {
            navigate('/customer/orders/');
        };

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const [data, setData] = useState([]);
        const [unable, setUnable] = useState({unableTurn: {}, unablePackage: {}});
        const [isLoading, setIsLoading] = useState(true);
        const [isError, setIsError] = useState(false);
        const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'
        const [technicalMapData, setTechnicalMapData] = useState([]);

        useEffect(() => {
            JobService.getJobsByOrderId(orderId).then(function (response) {
                setTechnicalMapData(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleError = (error) => {
            setIsError(true)
            setAlertData({
                type: 'error',
                open: true,
                message: constantsErrorMessages[error.response.status] || error.message
            })
        }

        useEffect(() => {
            setIsLoading(true)
            DetailControllerService.getAllByOrderId(orderId)
                .then(function (response) {
                    const data = response?.data
                    setData(data);
                    setUnable(({unableTurn, unablePackage}) => {
                        data.forEach(row => {
                            unableTurn[row.id] = row.turn;
                            unablePackage[row.id] = row.package;
                        })
                        return {unableTurn, unablePackage}
                    })
                    setIsError(false)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => setIsLoading(false))
        }, [change]);

        return (
            <div>

                <Typography variant="h6" component="h6">
                    Заказ: {customerOrderNumber}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        paddingLeft: '0.5rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.2rem',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        size="small"
                        color="secondary"
                        onClick={redirectOrders}
                        variant="contained"
                    >
                        Вернуться в заказы
                    </Button>
                </Box>
                <TabContext value={value}>
                    <Box sx={{
                        borderBottom: 1, borderColor: 'divider'
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Файлы" value='1'/>
                            <Tab label="Заявки на раскрой" value='2'/>
                            <Tab label="Обработка" value='3'/>
                            <Tab label="Кромки" value='4'/>
                            <Tab label="Фурнитура" value='5'/>
                            <Tab label="Общая смета" value='6'/>
                            <Tab label="Тех карта" value='7'/>
                        </Tabs>
                    </Box>
                    <TabPanel value='1'>
                        <Box
                            sx={{display: ' block', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                            <Divider/>
                            {/*   <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'проекта'}
                                label={'Загрузить файлы проекта'}
                                getAllFile={FileService.getAllProjectFileNamesByOrderId}
                                fileNameType={'PROJECT'}
                            />
                            <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'замера'}
                                label={'Загрузить файлы замера'}
                                getAllFile={FileService.getAllMeasuringFileNamesByOrderId}
                                fileNameType={'MEASURING'}
                            />*/}
                        </Box>
                    </TabPanel>
                    <TabPanel value='2'>
                        <CustomerRequestsCuttingEstimateItem
                            getAll={CustomerRequestsCuttingService.getAllByCustomerOrderId}
                            isOptimization={false}
                            isRedirectRequestsCutting={false}
                            orderId={orderId}
                            /* nameOrder={nameOrder}*/
                            idHeader={orderIdHeader}
                            year={year}
                            isOrder={true}
                            isButtonAddRequestsCutting={true}
                            isEstimate={true}
                            isCreateOptimizationCalculation={false}
                            isSetForOptimization={false}
                        />

                    </TabPanel>
                    <TabPanel value='3'>
                        <CustomerDetailsTable
                            data={data}
                            unableTurn={unable.unableTurn}
                            unablePackage={unable.unablePackage}
                            pageSize={100}
                            isError={isError}
                            setData={setData}
                            enableEditing={true}
                            orderId={orderId}
                        />
                    </TabPanel>
                    <TabPanel value='4'>
                    </TabPanel>
                    <TabPanel value='5'>
                    </TabPanel>
                    <TabPanel value='6'>
                        <EstimateItem
                            orderId={orderId}
                            isButtonPurchase={true}
                            enableEditing={true}
                            isActual={true}
                            inWork={true}
                            orderCloud={true}
                        />
                    </TabPanel>
                    <TabPanel value='7'>
                        <h3>Техническая карта заказа: {customerOrderNumber}</h3>
                        <TechnicalMapTable
                            data={technicalMapData}
                            setData={setTechnicalMapData}
                            estimateIsCurrent={false}
                        />
                    </TabPanel>
                </TabContext>
            </div>
        );
    }
;

export default OrderCloudItem;
