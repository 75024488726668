import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import MaterialWarehouseService from "../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import {Button, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ModalUpdateCutOff from "./ModalUpdateCutOff";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import CellCheckboxWithUnableWithComment from "./CellCheckboxWithUnableWithComment";
import ErrorModal from "../../../modal/ErrorModal";

const MaterialWarehouseCutOffsTable = ({
                                           isPlace,
                                           data,
                                           setData,
                                           unableCut,
                                           setUnableCut
                                       }) => {


        const [newValue, setNewValue] = useState(null);
        const [unableCheckbox, setUnableCheckbox] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRow, setCurrentEditRow] = useState(null);
        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [modalErrorOpen, setModalErrorOpen] = useState(false);
        const [materialPriceUnit, setMaterialPriceUnit] = useState(null);

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'length',
                    header: 'W',
                    accessorKey: 'materialPriceUnit.length',
                    minSize: 10,
                    maxSize: 10,
                },
                {
                    id: 'width',
                    header: 'H',
                    accessorKey: 'materialPriceUnit.width',
                    minSize: 10,
                    maxSize: 10,
                },

                {
                    id: 'confirmedAfterCutting',
                    header: 'Подт.',
                    accessorKey: 'confirmedAfterCutting',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnableWithComment
                        unable={unableCut}
                        setUnable={handleUnableCheckbox}
                        setModalOpen={setCreateOrUpdateModalOpen}
                        setUnableCheckbox={setUnableCheckbox}
                        setNewValue={setNewValue}
                        setCurrentEditRowData={setCurrentEditRowData}
                        setCurrentEditRow={setCurrentEditRow}
                        row={row}
                    >
                    </CellCheckboxWithUnableWithComment>,
                },
                {
                    id: 'note',
                    header: 'Ком.',
                    accessorKey: 'note',
                    size: 100,
                    maxSize: 100,
                    Cell: ({cell}) => {
                        {
                            return cell.getValue() === null ? "" : cell.getValue();
                        }
                    },
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    enableHiding: false,
                },
            ];

            if (isPlace) {
                baseColumns.splice(13, 0, {
                    id: 'place',
                    header: 'Место',
                    accessorKey: 'warehouse.name',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                });
            }
            return baseColumns;

        }, [data, setData]);

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRow(row);
                setMaterialPriceUnit(row.original.materialPriceUnit);
                setCreateOrUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleCloseCreateOrUpdateModalOpen = () => {
            setCreateOrUpdateModalOpen(false)
            setCurrentEditRowData(null);
            setMaterialPriceUnit(null);
            setCurrentEditRow(null);
            setUnableCheckbox(false);

        };

        const handleSubmit = async () => {

            const note = currentEditRowData.note;
            const id = currentEditRowData.id;
            const materialWarehouse = {
                id: id,
                materialPriceUnit: materialPriceUnit,
                note: note
            };

            if (unableCheckbox && !newValue) {
                if (note != null && note.length > 4) {
                    updateNote(materialWarehouse);
                    handleUnableCheckbox(id, newValue);
                } else {
                    setModalErrorOpen(true);
                    await DateTimeUtil.timeout(2000);
                    setModalErrorOpen(false);
                }
            } else {
                updateCutOff(materialWarehouse);
            }
        }

        const updateNote = (materialWarehouse) => {
            MaterialWarehouseService.updateNote(materialWarehouse.id, materialWarehouse.note
            ).then(function () {
                handleUpdateNote(materialWarehouse);
                handleCloseCreateOrUpdateModalOpen();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const updateCutOff = (materialWarehouse) => {
            MaterialWarehouseService.updateCutOff(materialWarehouse
            ).then(function (response) {
                handleUpdateCutOff(response.data);
                handleCloseCreateOrUpdateModalOpen();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const handleUpdateNote = (materialWarehouse) => {
            data[currentEditRow.index].note = materialWarehouse.note;
            setData([...data]);
        };

        const handleUpdateCutOff = (materialWarehouse) => {
            data[currentEditRow.index].note = materialWarehouse.note;
            data[currentEditRow.index].materialPriceUnit.length = materialWarehouse.materialPriceUnit.length;
            data[currentEditRow.index].materialPriceUnit.width = materialWarehouse.materialPriceUnit.width;
            setData([...data]);
        };

        const handleUnableCheckbox = (id, status) => {
            MaterialWarehouseService.confirmedOrNotConfirmedCutOff(id, status).then(function () {
                handleUnableCut(id, status)
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleUnableCut = (id, status) => {
            unableCut[id] = status
            setUnableCut(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    renderTopToolbar={() => null}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    enableEditing
                    /*onRowSelectionChange={setRowSelection}*/
                    /*  state={{rowSelection}}*/
                    /*  enableRowSelection={row => row.original.materialPriceUnit.cutOff}*/
                    /* getRowId={(originalRow) => originalRow.id}*/
                    /* selectionPadding={1}
                     selectionCss={{
                         backgroundColor: 'lightblue',
                         borderWidth: '2px',
                         borderStyle: 'solid',
                         borderColor: 'blue',
                         borderRadius: '4px',
                     }}*/
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                />
                <ModalUpdateCutOff
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    open={createOrUpdateModalOpen}
                    materialPriceUnit={materialPriceUnit}
                    setMaterialPriceUnit={setMaterialPriceUnit}
                    handleClose={() => handleCloseCreateOrUpdateModalOpen()}
                    handleSubmit={handleSubmit}
                    unableCheckbox={unableCheckbox}
                />
                <ErrorModal
                    setOpen={setModalErrorOpen}
                    open={modalErrorOpen}
                    message={'Комментарий не заполнен, либо количество символов менее 5'}
                />
            </div>
        );
    }
;

export default MaterialWarehouseCutOffsTable;