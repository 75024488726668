import React from 'react';
import MaterialPageItemModule from "../../../../../components/UI/modul/MaterialPageItemModule";
import {BreadcrumbsComponent} from "../../../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";

const Materials = () => {

    return <Box sx={{padding: "0 30px"}}>
        <BreadcrumbsComponent page={"Материалы"} path={[{name: "Главная", href: "/"}]}/>
        <MaterialPageItemModule
            refMaterial={true}
            isAddMaterial={true}
            pageSize={30}
            nameMethodGetMaterial={'with-group-material-unit'}
        />
    </Box>
};

export default Materials;