import TextFieldValidation from "../../../components/UI/table/customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import ButtonCreate from "../../../components/UI/button/ButtonCreate";
import DetailControllerService from "../../../API/details/DetailControllerService";
import {log} from "three/src/nodes/math/MathNode";

const getFileExtension = (response) => {
    const type = response.headers.get("content-type")
    const from = type.search("/") + 1
    const to = type.search(";charset")
    return type.substring(from, to)
}

const ExportDetail = ({orderId, detailId, position, handleError, setIsError}) => {
    const [formState, setFormState] = useState({machineManufacturer: "KDT"})

    const handlerExport = () => {
        DetailControllerService.exportFile(orderId, detailId, formState.machineManufacturer).then((response) => {
            const fileExtension = getFileExtension(response);
            const url = window.URL.createObjectURL(new Blob([response.data], {type: fileExtension}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${position}.${fileExtension}`);
            document.body.appendChild(link);
            link.click();
            setIsError(false);
        })
            .catch((error) => {
                handleError(error)
            })
    }
    return <Box sx={{width: "200px"}}>
        <TextFieldValidation
            select
            name="machineManufacturer"
            label="Тип постпроцессора"
            value={formState.machineManufacturer}
            setCurrValues={setFormState}
        >
            {["KDT", "Biesse", "Homag"].map((option) => (<MenuItem key={option} value={option}>
                {option}
            </MenuItem>))}
        </TextFieldValidation>
        <ButtonCreate icon={false} text={"Экспортировать деталь"} width={200} onClick={handlerExport}/>
    </Box>
}
export default ExportDetail;