import React from 'react';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

const MainMenu = ({name, labelName, handleMenu, anchorEl, handleClose, menuItems}) => {
    return (
        <div>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls={`${name}-menu-appbar`}
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    sx={{
                        "&:hover": {
                            backgroundColor: "transparent",
                            textDecoration: "underline",
                        }
                    }}
                >
                    <Typography component="div" sx={{flexGrow: 1}}>
                        {labelName}
                    </Typography>
                </IconButton>
                <Menu
                    id={`${name}-menu-appbar`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                    }}
                    keepMounted
                    MenuListProps={{
                        sx: {
                            minWidth: anchorEl && anchorEl.offsetWidth,
                        }
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {menuItems.map((menuItem) => (
                        <MenuItem key={menuItem.type} onClick={() => window.location.href = menuItem.hrefs}>
                            {menuItem.name}
                        </MenuItem>
                    ))}
                </Menu>

            </Box>
        </div>
    );
};

export default MainMenu;
