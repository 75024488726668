import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import WarehouseMaterialSettingsService
    from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import CurrencyService from "../../../../../API/orders/warehouse/currency/CurrencyService";
import SupplierService from "../../../../../API/orders/warehouse/supplier/SupplierService";
import WarehouseService from "../../../../../API/orders/warehouse/WarehouseService";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import {materialReserveState} from "../../../../../data/MaterialReserveState";

const IssuingEdgeMaterialMaterialReserveTable = ({
                                                     data,
                                                     setData,
                                                     isColumnOrder,
                                                     isColumnMaterialWarehouse,
                                                     isColumnOptimization,
                                                     enableGrouping,
                                                     expanded,
                                                     pageSize,
                                                     orderList,
                                                     change,
                                                     setChange,
                                                     columnGrouping,
                                                     titleModalUpdateOrAddMaterialArrival
                                                 }) => {

    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);


    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
    const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);


    const [messageError, setMessageError] = useState(null);
    const [messageInfo, setMessageInfo] = useState(null);
    const [rowSelection, setRowSelection] = useState({});

    const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
    const [date, setDate] = useState(null);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [materialDeliveryDate, setMaterialDeliveryDate] = useState(null);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");
    const [currencyList, setCurrencyList] = useState([]);
    const [currencyId, setCurrencyId] = useState(null);
    const [supplierList, setSupplierList] = useState([]);
    const [supplierId, setSupplierId] = useState(null);
    const [warehouseList, setWarehouseList] = useState([]);
    const [warehouseId, setWarehouseId] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {

        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
        CurrencyService.getAll().then(function (response) {
            let data = response.data;
            data.sort((a, b) =>
                a.defaultBase === b.defaultBase ? 0 : a.defaultBase ? -1 : 1
            );
            setCurrencyList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        SupplierService.getAllByOrganization().then(function (response) {
            let data = response.data;
            setSupplierList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        WarehouseService.getAll().then(function (response) {
            let data = response.data;
            setWarehouseList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'materialArticleNumber',
                header: 'Артикул',
                accessorKey: 'materialArticleNumber',
                minSize: 70,
            },
            {
                id: 'materialName',
                header: 'Найменование',
                accessorKey: 'materialName',
                minSize: 70,

            },
            {
                id: 'quantity',
                header: 'Необходимо.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'primary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
            },
            {
                id: 'availabilityQuantity',
                header: 'Наличие',
                accessorKey: 'availabilityQuantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'success.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
            },
            {
                id: 'materialReserveState',
                header: 'Статус',
                accessorKey: 'materialReserveState',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell}) => materialReserveState[cell.getValue()],
            },
            {
                id: 'orderNumber',
                header: 'Заказы',
                accessorKey: 'orders',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    return OrderUtil.convertNumberOrders(cell.getValue())
                },
                filterVariant: 'multi-select',
                filterSelectOptions: orderList,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkNumberOrder(row.getValue(id), filterValue)
                },
            },
            {
                id: 'plan.number',
                header: 'План',
                accessorKey: 'plan.number',
                minSize: 15,
                maxSize: 15,
            },

            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ];

        return baseColumns;

    }, [data, setData, isColumnOrder]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    expanded: expanded,
                    grouping: columnGrouping,
                    pagination: {pageIndex: 0, pageSize: pageSize},
                }}
                enableGrouping={enableGrouping}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default IssuingEdgeMaterialMaterialReserveTable;