import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {materialWarehouseStatuses} from "../../../../../data/MaterialWarehouseState";
import ModalConfirm from "../../../modal/ModalConfirm";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ModalAddMaterialRetailIssuingEdge from "./ModalAddMaterialRetailIssuingEdge";
import {Link} from "react-router-dom";
import IssuingEdgeService from "../../../../../API/orders/prodaction.issueedge/issueedge/IssuingEdgeService";

const IssuingEdgeWareHouseTable = ({
                                       data,
                                       setData,
                                       idIssuingEdge,
                                       change,
                                       setChange,
                                   }) => {

        const [confirmIssueModalOpen, setConfirmIssueModalOpen] = useState(false);
        const [modalAddMaterialRetailIssuingEdgeOpen, setModalAddMaterialRetailIssuingEdgeOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [selectedRows, setSelectedRows] = useState([]);
        const [selectedIds, setSelectedIds] = useState([]);
        const [rowSelection, setRowSelection] = useState({});

        const [availability, setAvailability] = useState({});
        const [issue, setIssue] = useState({});

        const handleSubmitConfirmIssue = () => {

            IssuingEdgeService.issuanceEdgeByIds(idIssuingEdge, selectedIds).then(function () {
                setChange(!change)
                setConfirmIssueModalOpen(false);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const getColor = (row) => {
            if (row.original.free < 0) {
                return 'red';
            } else {
                if (row.original.availabilityQuantity === 0) {
                    return 'red';
                } else {
                    if (row.original.currentIssueReserve === 0 && row.original.free === 0) {
                        return 'red';
                    } else {
                        if (row.original.currentIssueReserve >= 0 && row.original.free === 0 && row.original.otherIssueReserve > 0 && row.original.totalIssued > row.original.currentIssueReserve) {
                            return 'red';
                        } else {
                            if (row.original.currentIssueReserve >= 0 && row.original.free >= 0 && row.original.otherIssueReserve > 0) {
                                return 'orange';
                            } else {
                                if (row.original.quantity !== row.original.availabilityQuantity) {
                                    return 'orange';
                                } else {
                                    if (row.original.currentIssueReserve >= 0 && row.original.free >= 0 && row.original.otherIssueReserve === 0) {
                                        return 'green';
                                    } else {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const handleCloseConfirmIssue = () => {
            setConfirmIssueModalOpen(false)
        };

        const handleCloseModalAddMaterialRetailIssuingEdge = () => {
            setModalAddMaterialRetailIssuingEdgeOpen(false)
        };

        const openRetailIssue = useCallback(
            (row) => {
                const subtractSumIssueOtherReserve = row.original.otherIssueReserve - row.original.sumIssueOtherReserve
                const currentAvailability = row.original.availabilityQuantity - subtractSumIssueOtherReserve;
                const currentIssue = currentAvailability - row.original.free;
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setAvailability(currentAvailability)
                setIssue(currentIssue)
                setModalAddMaterialRetailIssuingEdgeOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleSubmitConfirmRetailIssue = () => {

            IssuingEdgeService.issuanceEdgeRetail(currentEditRowData.id, issue, idIssuingEdge).then(function () {
                setChange(!change)
                handleCloseModalAddMaterialRetailIssuingEdge();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }


        const columns = useMemo(() => {

            const baseColumns = [
                {
                    id: 'nameMaterial',
                    header: 'Найменование',
                    accessorKey: 'nameMaterial',
                    minSize: 10,
                    maxSize: 10,
                },
                {
                    id: 'quantity',
                    header: 'Кол.',
                    accessorKey: 'quantity',
                    minSize: 10,
                    maxSize: 10,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        const color = getColor(row);
                        return (
                            <span style={{color: color}}>
                {value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}
            </span>
                        );
                    },
                },
                {
                    id: 'availabilityQuantity',
                    header: 'Нал.',
                    accessorKey: 'availabilityQuantity',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                    },
                },
                {
                    id: 'currentIssueReserve',
                    accessorKey: 'currentIssueReserve',
                    size: 8,
                    maxSize: 8,
                    header: 'Тек. выд.',
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                    },
                },
                {
                    id: 'free',
                    header: 'Своб.',
                    accessorKey: 'free',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return <span style={{
                            color: value > 0 ? "green" : "black"
                        }}>{value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}</span>
                    }
                },
                {
                    id: 'otherIssueReserve',
                    header: 'Проч. выд.',
                    accessorKey: 'otherIssueReserve',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                    },
                },
                {
                    id: 'sumIssueCurrent',
                    header: 'Выдано в текущем',
                    accessorKey: 'sumIssueCurrent',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        return <li key={value}>
                            <Link
                                to={'/organization/' + idIssuingEdge + '/issuance-warehouse-material/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(2)}</Link>
                        </li>
                    },
                },
                {
                    id: 'totalIssued',
                    header: 'Выд. всего',
                    accessorKey: 'totalIssued',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell, row}) => {
                        const value = cell.getValue();
                        return <li key={value}>
                            <Link
                                to={'/organization/issuance-warehouse-material/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(2)}</Link>
                        </li>
                    },
                },
                {
                    id: 'overspending',
                    header: 'Перерасход',
                    accessorKey: 'overspending',
                    size: 8,
                    maxSize: 8,
                },
                {
                    id: 'materialWarehouseState',
                    header: 'Статус',
                    accessorKey: 'materialWarehouseState',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => materialWarehouseStatuses[cell.getValue()],
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    enableHiding: false,
                },
                {
                    id: 'warehouse',
                    header: 'Склад',
                    accessorKey: 'warehouse',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({cell, row}) => row.original.warehouse === null ? '' : cell.getValue(),
                },
                {
                    id: 'note',
                    header: 'Ком.',
                    accessorKey: 'note',
                    size: 8,
                    maxSize: 8,
                },
            ];

            return baseColumns;

        }, [data, setData,]);


        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                        sorting: [
                            {
                                id: 'nameMaterial',
                                desc: true,
                            },],
                        grouping: ['nameMaterial'],
                        expanded: true,
                    }}
                    enableEditing={true}
                    onRowSelectionChange={setRowSelection}
                    enableGrouping={true}
                    state={{rowSelection}}
                    /*enableRowSelection={row => (getColor(row) !== 'red' && getColor(row) !== 'orange' && row.original.availabilityQuantity > 0)}*/
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '25px'
                        }}>
                            {row.original.availabilityQuantity > 0 && !(row.original.otherIssueReserve > 0 && row.original.free === 0 && row.original.currentIssueReserve === 0)
                                ?
                                <Tooltip arrow placement="right" title="Выдать штучно">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="success"
                                        onClick={() => openRetailIssue(row)}>
                                        <PlaylistAddIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                    </Button>
                                </Tooltip> : <Button
                                    disabled={true}
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="success"
                                >
                                    <PlaylistAddIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>}
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const setIssueMaterial = () => {
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            setSelectedRows(selectedRows);
                            const selectedIds = selectedRows.map((row) => row.original.id);
                            setSelectedIds(selectedIds);
                            setConfirmIssueModalOpen(true)
                        };

                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    {/* {<Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => setIssueMaterial()}
                                        variant="contained"
                                    >
                                        Выдать партиями
                                    </Button>}*/}
                                </Box>
                            </div>
                        );
                    }}
                />
                <ModalConfirm
                    title={"Подтвердить выдачу?"}
                    modalOpen={confirmIssueModalOpen}
                    handleClose={handleCloseConfirmIssue}
                    handleSubmit={handleSubmitConfirmIssue}
                    action={"Подтвердить"}
                />
                <ModalAddMaterialRetailIssuingEdge
                    open={modalAddMaterialRetailIssuingEdgeOpen}
                    onClose={handleCloseModalAddMaterialRetailIssuingEdge}
                    titleModal={"Выдать материал в количестве"}
                    availability={availability}
                    issue={issue}
                    setIssue={setIssue}
                    submit={handleSubmitConfirmRetailIssue}
                />
            </div>
        );
    }
;

export default IssuingEdgeWareHouseTable;