import React, {useState} from "react";
import {Button} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";

const SwitchTab = ({tabComponents, margin}) => {
    const [tab, setTab] = useState(1);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div style={{margin: margin}}>
            <ButtonGroupTabBar activeTab={tab}>
                {tabComponents.map(({tabIndex, label}, index) => (
                    <Button key={tabIndex} tabIndex={tabIndex} onClick={(e) => handleChange(e, tabIndex)}>
                        <CheckIcon/>
                        {label}
                    </Button>
                ))}
            </ButtonGroupTabBar>
            {tabComponents.find(component => component.tabIndex === tab)?.component}
        </div>
    );
};

export default SwitchTab;