import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import AttributesService from "../../../../../../API/orders/orders/organization/AttributesService";
import CellCheckboxOATUnable from "./CellCheckboxOATUnable";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

const SetAttributeOrderTable = ({idOrder, number, idHeader, year}) => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        AttributesService.getAllCheckForOrder(idOrder).then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const redirectOrders = () => {
        navigate('/organization/orders');
    };

    const redirectOrder = () => {
        navigate('/organization/orders/' + idOrder + '/' + number + '/' + idHeader + '/' + year);
    };

    const redirectConstructor = () => {
        navigate('/organization/constructors/');
    };

    const columns = useMemo(
        () => [
            {
                header: 'Найменование',
                accessorKey: 'attributeName',
                minSize: 25,
                enableEditing: false,
            },
            {
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                Cell: ({cell, row}) => <CellCheckboxOATUnable cell={cell} id={idOrder}
                                                              attributeId={data[row.index].id}></CellCheckboxOATUnable>,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return <MaterialReactTable
        enableColumnActions={false}
        isFullScreen={isFullScreen}
        muiTableBodyProps={{
            sx: () => ({
                '& tr:nth-of-type(odd)': {
                    backgroundColor: 'lightgray',
                },
            }),
        }}
        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 10px',
                m: '0px',
            },
        }}
        muiTableBodyRowProps={{
            sx: {
                height: '10px',
            },
        }}
        columns={columns}
        data={data}
        initialState={{
            columnVisibility: {
                id: false,
            },
            isFullScreen: false,
            density: "compact",
            pagination: {pageIndex: 0, pageSize: 20},
            columnPinning: {left: ['number']}
        }}
        localization={MRT_Localization_RU}
        renderTopToolbarCustomActions={() => (<Box
            sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
        >
            <Button
                size={"small"}
                color="secondary"
                onClick={redirectOrder}
                variant="contained"
            >
                Вернуться к заказу
            </Button>
            <Button
                size={"small"}
                color="secondary"
                onClick={redirectOrders}
                variant="contained"
            >
                Вернуться в заказы
            </Button>
            <Button
                size={"small"}
                color="secondary"
                onClick={redirectConstructor}
                variant="contained"
            >
                Вернуться в проектирование
            </Button>
        </Box>)}
    />;
};

export default SetAttributeOrderTable;