import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const groupMaterialRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/user/group-materials'

export default class UserGroupMaterialMaterialCloudService {

    static async getAll(typeMaterial, subTypeMaterial) {
        const params = {typeMaterial: typeMaterial, subTypeMaterial: subTypeMaterial}

        const queryString = Object.keys(params)
            .filter(key => params[key] !== undefined && params[key] !== null)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');

        return axios.get(`${groupMaterialRestUrl}?${queryString}`);
    }
}