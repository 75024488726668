import React from 'react';
import {Tooltip} from "@mui/material";
import {Link} from "react-router-dom";

const CellMaterialReserveOptimizationQuantity = ({value, row}) => {

    const isSlab = row.original.materialOrganizationOption.typeMaterial === 'SLAB'
    const quantity = row.original.quantity;
    const workingCuttingMapId = row.original.workingCuttingMapId
    const workingCuttingMapNumber = row.original.workingCuttingMapNumber

    const name = row.original.materialOrganizationOption.name

    const requestsCutting = row.original.requestsCutting
    const isExpectation = isSlab ? (requestsCutting !== null ? requestsCutting.requestsCuttingState === 'EXPECTATION' : false) : false;

    const currentValue = isSlab ? (value ? value.toFixed(3) : 'Ожидание') : quantity.toFixed(3);

    const link = isSlab ? (workingCuttingMapId === null ? currentValue : <Link
        to={'/organization/working-cutting-map/' + workingCuttingMapId + '/' + encodeURIComponent(name)}>
        {currentValue}
    </Link>) : currentValue;

    const titleSlab = isSlab ? (isExpectation ? 'Ожидание' : "По карте раскроя " + workingCuttingMapNumber) : 'Количество в заявке';

    return (
        <div>
            <Tooltip arrow placement="right" title={titleSlab}>
                <span
                    size="small"
                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                    color="error">
                   {link}
                </span>
            </Tooltip>
        </div>
    );
};

export default CellMaterialReserveOptimizationQuantity;