import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import CellCheckbox from "../../../сheckbox/CellCheckbox";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import {Link} from "react-router-dom";
import ModalPerformRefund from "./ModalPerformRefund";
import CheckIcon from '@mui/icons-material/Check';
import ModalConfirm from "../../../modal/ModalConfirm";
import IssuingEdgeItemService
    from "../../../../../API/orders/prodaction.issueedge/issuingedgeitem/IssuingEdgeItemService";

const IssuingEdgesItemTable = ({id, change, setChange}) => {

    const [data, setData] = useState([]);


    useEffect(() => {

        IssuingEdgeItemService.getAllByIssuingEdgeId(id).then(function (response) {
            let data = response.data;
            setData(data)
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const [performRefundModalOpen, setPerformRefundModalOpen] = useState(false);
    const [refund, setRefund] = useState(null);

    const [closeModalOpen, setCloseModalOpen] = useState(false);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);


    const handleSubmitPerformRefund = () => {
        IssuingEdgeItemService.performRefund(currentEditRowId, id, refund).then(function (response) {
            handleUpdate(response.data);
            closePerformRefundModalOpen();
            setChange(!change)
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleSubmitCloseIssuingEdgeItem = () => {
        IssuingEdgeItemService.closeIssuingEdgeItem(currentEditRowId).then(function () {
            handleClose();
            closeCloseModalOpen();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleUpdate = (values) => {
        data[currentRowIndex] = values;
        setData([...data]);
    };

    const handleClose = () => {
        data[currentRowIndex].closed = true;
        setData([...data]);
    };

    useEffect(() => {

        IssuingEdgeItemService.getAllByIssuingEdgeId(id).then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'materialOrganizationOption.name',
                header: 'Найменование материала',
                accessorKey: 'materialOrganizationOption.name',
                maxSize: 250,
            },
            {
                id: 'totalNeed',
                header: 'Необходимо',
                accessorKey: 'totalNeed',
                maxSize: 25,
            },
            {
                id: 'totalCount',
                header: 'Выдано',
                accessorKey: 'totalCount',
                maxSize: 25,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return <li key={value}>
                        <Link
                            to={'/organization/issuance-issuing-edges-item/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(2)}</Link>
                    </li>
                },
            },
            {
                id: 'overspending',
                header: 'Перерасход',
                accessorKey: 'overspending',
                maxSize: 25,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    return <span style={{
                        color: value < 0 ? "green" : "red"
                    }}>{value === null || value === undefined ? 'Ожидание' : value.toFixed(2)}</span>
                }
            },
            {
                id: 'closed',
                header: 'Закрыто',
                accessorKey: 'closed',
                maxSize: 25,
                Cell: ({cell, row}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
                maxSize: 2,
            },
        ];

        return baseColumns;
    }, [data, setData]);


    const closePerformRefundModalOpen = () => {
        setCurrentRowIndex(null)
        setCurrentEditRowData(null);
        setCurrentEditRowId(null);
        setPerformRefundModalOpen(false)
    };

    const closeCloseModalOpen = () => {
        setCurrentRowIndex(null)
        setCurrentEditRowData(null);
        setCurrentEditRowId(null);
        setCloseModalOpen(false)
    };

    const handlePerformRefund = useCallback(
        (row) => {
            setCurrentRowIndex(row.index)
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.original.id);
            setRefund(row.original.totalCount);
            setPerformRefundModalOpen(true)
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const close = useCallback(
        (row) => {
            setCurrentRowIndex(row.index)
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.original.id);
            setCloseModalOpen(true)
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                localization={MRT_Localization_RU}
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.01rem',
                        justifyContent: 'flex-start',
                        maxWidth: '15px'
                    }}>
                        {!row.original.closed
                            ?
                            <Tooltip arrow placement="right" title="Выполнить возврат">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="pencil"
                                    onClick={() => handlePerformRefund(row)}>
                                    <AssignmentReturnIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="pencil"
                            >
                                <AssignmentReturnIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>}
                        {!row.original.closed
                            ?
                            <Tooltip arrow placement="right" title="Закрыть выдачу">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="success"
                                    onClick={() => close(row)}>
                                    <CheckIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="success"
                            >
                                <CheckIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>}
                    </Box>
                )}
                renderTopToolbarCustomActions={({table}) => {

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {/*  <Button
                                    size="small"
                                    color="success"
                                    onClick={openAddMaterialModal}
                                    variant="contained"
                                >
                                    Выдать кромку
                                </Button>*/}
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalPerformRefund
                open={performRefundModalOpen}
                onClose={closePerformRefundModalOpen}
                titleModal={"Вернуть материал в количестве"}
                issue={currentEditRowData ? currentEditRowData.totalCount : null}
                refund={currentEditRowData ? currentEditRowData.totalCount : null}
                setRefund={setRefund}
                submit={handleSubmitPerformRefund}
            />
            <ModalConfirm
                title={"Подтвердить закрыть выдачу?"}
                modalOpen={closeModalOpen}
                handleClose={closeCloseModalOpen}
                handleSubmit={handleSubmitCloseIssuingEdgeItem}
                action={"Подтвердить"}
            />
        </div>
    );
};

export default IssuingEdgesItemTable;