import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Tooltip} from "@mui/material";
import {Delete, Edit, PlaylistAdd} from "@mui/icons-material";
import Box from "@mui/material/Box";
import ModelService from "../../../../../API/model-estimate/model/ModelService";
import HeaderService from "../../../../../API/orders/orders/organization/HeaderService";
import ModelsUtil from "../../../../../utils/orders/ModelUtils";
import {Link, useNavigate} from "react-router-dom";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import ButtonSettingsOrders from "../../../button/ButtonSettingsOrders";
import CellCheckboxWithUnable from "../../../сheckbox/CellCheckboxWithUnable";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import UploadBasicModelFileForm from "./UploadBasicModelFileForm";
import UploadModelEstimateFilesForm from "../estimate/UploadModelEstimateFilesForm";
import ModelFileService from "../../../../../API/model-estimate/file/ModelFileService";

const BasicModelsTable = ({
                              isButtonDelete,
                              isButtonUpdate,
                              isButtonModels,
                              isButtonEstimate,
                              isButtonCreateModel,
                              isButtonSettings,
                              isColumnUnable,
                              isColumnPackaging,
                              enableRowSelection,
                              setBasicModelId,
                              setModalModelsOpen,
                              orderIdHeader,
                              getAll,
                              upLoad
                          }) => {

        const [data, setData] = useState([]);
        const [isFullScreen, setIsFullScreen] = useState(false);

        const [createModalOpen, setCreateModalOpen] = useState(false);
        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [modelHeaderList, setModelHeaderList] = useState([]);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [unable, setUnable] = useState({});
        const [change, setChange] = useState(null);

        const [rowSelection, setRowSelection] = useState({});

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const handleUpdate = (row, values) => {
            data[row[currentEditRowId]] = values;
            setData([...data]);
        };

        const openModels = useCallback(
            (row) => {
                setBasicModelId(row.original.id)
                setModalModelsOpen(true);

            },
            [data],
        );


        useEffect(() => {

                getAll(orderIdHeader).then(function (response) {
                    let data = response.data;
                    setData(data);
                    /*setModelHeaderList(DataUtil.getListChildField(response.data, 'header', 'name'));*/
                    data.forEach((row) => {
                        unable[row.id] = row.enabled;
                    });
                })
                    .catch(function (error) {
                        console.log(error);
                    });

            }, [change]
        )
        ;

        const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
            ModelService.update(values)
                .then(function () {
                    data[row.index] = values;
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
            exitEditingMode();
        };

        const handleDeleteRow = useCallback(
            (row) => {
                ModelService.delete(row.original.id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);

            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleUnableCheckbox = (id, status) => {
            ModelService.unable(id, status)
                .then(function () {
                    handleUnable(id, status)
                })
                .catch(function (error) {
                    console.log(error);
                })
        };

        const handleUnable = (id, status) => {
            unable[id] = status
            setUnable(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'name',
                    header: 'Модель',
                    accessorKey: 'name',
                    minSize: 200,
                },
                {
                    id: 'headerName',
                    header: 'Наименование',
                    accessorKey: 'headerName',
                    filterVariant: 'multi-select',
                    filterSelectOptions: modelHeaderList,
                    minSize: 30,
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (isColumnUnable) {
                baseColumns.splice(7, 0, {
                    header: 'Статус',
                    accessorKey: 'enabled',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unable}
                        setUnable={handleUnableCheckbox}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            if (isColumnPackaging) {
                baseColumns.splice(8, 0, {
                    id: 'packagingSet.articleNumber',
                    header: 'Артикул упаковки',
                    accessorKey: 'packagingSet.articleNumber',
                    minSize: 15,
                    maxSize: 15,
                    enableEditing: false,
                    Cell: ({cell, row}) => {
                        if (data[row.index].packagingSet !== null) {
                            return (
                                <li key={cell.getValue()}>
                                    <Link
                                        to={'/models/set-packaging-set/' + data[row.index].packagingSet.id + '/' + data[row.index].packagingSet.articleNumber
                                        }>{cell.getValue() === undefined ? 'Не определено' : cell.getValue()}</Link>
                                </li>
                            );
                        } else {
                            return (
                                <li key={cell.getValue()}>
                                    {cell.getValue() === undefined ? 'Не определено' : cell.getValue()}
                                </li>
                            );
                        }
                    }
                });
            }

            return baseColumns;

        }, [data, setData,]);

        const navigate = useNavigate();

        const redirectByArrivalMaterialId = (row) => {
            navigate('/organization/basic-models/' + row.original.id + '/estimates');
        };

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        sorting: [{id: 'name', desc: false}],
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 20},
                        columnPinning: {left: ['number']}
                    }}
                    enableEditing
                    editingMode="modal"
                    onEditingRowSave={handleSaveRowEdits}
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection={enableRowSelection}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            {isButtonUpdate && <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                            {isButtonDelete && <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                            {isButtonEstimate && <Tooltip arrow placement="right" title="Сметы">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                        onClick={() => redirectByArrivalMaterialId(row)}>
                                    <PlaylistAdd sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                            {isButtonModels && <Tooltip arrow placement="right" title="Показать модели">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                        onClick={() => openModels(row)}>
                                    <ChecklistRtlIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const setCurrency = (typeCurrentCurrency) => {
                        };

                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        alignItems: 'start'
                                    }}
                                >
                                    {isButtonCreateModel && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => setCreateModalOpen(true)}
                                        variant="contained"
                                    >
                                        Создать модель
                                    </Button>}
                                    {isButtonSettings && <ButtonSettingsOrders/>}
                                    {upLoad && <UploadBasicModelFileForm
                                        label={'Загрузить базовые модели'}
                                        change={change}
                                        setChange={setChange}
                                    />}
                                    {upLoad && <UploadModelEstimateFilesForm
                                        labelForm={'Загрузить файлы смет'}
                                        upload={ModelFileService.uploadModelEstimatesFiles}
                                    />}
                                    {upLoad && <UploadModelEstimateFilesForm
                                        labelForm={'Загрузить файлы раскроя'}
                                        upload={ModelFileService.uploadModelRequestsCuttingFiles}
                                    />}
                                </Box>
                            </div>
                        );
                    }}
                />
                <CreateNewModelModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                />
                <UpdateModelModal
                    columns={columns}
                    open={updateModalOpen}
                    onClose={() => setUpdateModalOpen(false)}
                    onSubmit={handleUpdate}
                    values={currentEditRowData}
                    currentEditRowId={currentEditRowId}
                    change={change}
                    setChange={setChange}
                />
            </div>
        );
    }
;

export const CreateNewModelModal = ({open, columns, onClose, onSubmit}) => {

    const [modelHeaderList, setModelHeaderList] = useState([]);
    const [modelHeaderId, setModelHeaderId] = useState(null);

    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );


    useEffect(() => {

        HeaderService.getAllUnableForModels().then(function (response) {
            setModelHeaderList(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleSubmit = () => {
        values.enabled = true;
        ModelService.save(values, modelHeaderId === null ? modelHeaderList[0].id : modelHeaderId).then(function (response) {
            onSubmit(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Создать модель</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        <TextField
                            size={"small"}
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '0.01rem',
                            }}
                            margin="normal"
                            id="modelHeader"
                            select
                            label="Найменование"
                            name="modelHeaderId"
                            onChange={(e) =>
                                setModelHeaderId(e.target.value)
                            }
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {modelHeaderList.map((modelHeader) => (
                                <option
                                    key={modelHeader.id}
                                    value={modelHeader.id}>
                                    {modelHeader.name}
                                </option>
                            ))}
                        </TextField>

                        {columns.map((column) => (
                            ModelsUtil.checkCreateField(column) &&
                            <TextField
                                size={"small"}
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '0.01rem',
                                }}
                                margin="normal"
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({...values, [e.target.name]: e.target.value})
                                }
                            />
                        ))}

                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выход</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const UpdateModelModal = ({open, columns, onClose, onSubmit, values, change, setChange}) => {

    const [modelHeaderList, setModelHeaderList] = useState([]);
    const [modelHeaderId, setModelHeaderId] = useState(null);


    useEffect(() => {

        HeaderService.getAllUnableForModels().then(function (response) {
            setModelHeaderList(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleSubmit = () => {
        ModelService.update(values, modelHeaderId === null ? values.header.id : modelHeaderId).then(function (response) {
            onSubmit(response.data);
            setModelHeaderId(null)
            if (modelHeaderId !== null) {
                setChange(!change)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Редактировать модель</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextField
                            margin="normal"
                            id="customer"
                            select
                            label="Найменование"
                            defaultValue={values === null ? "" : values.header.id}
                            name="customerId"
                            onChange={(e) =>
                                setModelHeaderId(e.target.value)
                            }
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {modelHeaderList.map((modelHeader) => (
                                <option
                                    key={modelHeader.id}
                                    value={modelHeader.id}>
                                    {modelHeader.name}
                                </option>
                            ))}
                        </TextField>
                        {columns.map((column) => (
                            ModelsUtil.checkCreateField(column) &&
                            <TextField
                                margin="normal"
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                defaultValue={values === null ? "" : values[column.accessorKey]}
                                onChange={(e) =>
                                    values[column.accessorKey] = e.target.value
                                }
                            />
                        ))}

                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BasicModelsTable;