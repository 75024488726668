import React from 'react';
import {Dialog} from "@mui/material";
import EdgeModal from "./ModalsContent/EdgeModal";
import HoleModal from "./ModalsContent/HoleModal";
import PointModal from "./ModalsContent/PointModal";
import CutModal from "./ModalsContent/CutModal";
import NotchModal from "./ModalsContent/NotchModal";
import NotchRoundModal from "./ModalsContent/NotchRoundModal";
import GrooveModal from "./ModalsContent/GrooveModal";
import QuarterModal from "./ModalsContent/QuarterModal";
import NotchContour from "./ModalsContent/NotchContour";
import ArcModal from "./ModalsContent/ArcModal";

const ModalProcessing = ({
                             type,
                             onClose,
                             onSubmit,
                             edgesData,
                             detailData,
                             editableElementId,
                             activePointId,
                             setActivePointId,
                             openConfirmModal,
                             cutData,
                             setCutData,
                             calcPrevInnerContour,
                             getPrevContour,
                             refCanvas,
                             variables
                         }) => {
    const getContent = () => {
        switch (type) {
            case 'addEdge':
            case 'editEdge':
                return <EdgeModal type={type} edgesData={edgesData} detailData={detailData} onClose={onClose}
                                  onSubmit={onSubmit}
                                  editableElementId={editableElementId}
                                  openConfirmModal={openConfirmModal}/>
            case 'addHole':
            case "editHole":
                return <HoleModal editableElementId={editableElementId}
                                  onClose={onClose} onSubmit={onSubmit} detailData={detailData}
                                  openConfirmModal={openConfirmModal}
                                  variables={variables}
                                  calcPrevInnerContour={calcPrevInnerContour}/>
            case "addPoint":
            case "editPoint":
                return <PointModal type={type} activePointId={activePointId}
                                   onClose={onClose} onSubmit={onSubmit}
                                   detailData={detailData}
                                   setActivePointId={setActivePointId}
                                   variables={variables}
                />
            case "arc":
            case "round":
                return <ArcModal type={type} onClose={onClose} onSubmit={onSubmit}
                                        detailData={detailData}
                                        activePointId={activePointId}
                                        editableElementId={editableElementId}
                                        setActivePointId={setActivePointId}
                                        cutData={cutData}
                                        getPrevContour={getPrevContour}
                                        variables={variables}/>
            case "cut":
                return <CutModal onClose={onClose} onSubmit={onSubmit}
                                 detailData={detailData}
                                 activePointId={activePointId}
                                 editableElementId={editableElementId}
                                 setActivePointId={setActivePointId}
                                 cutData={cutData}
                                 setCutData={setCutData}/>
            case "notch":
                return <NotchModal onClose={onClose} onSubmit={onSubmit}
                                   setActivePointId={setActivePointId}
                                   calcPrevInnerContour={calcPrevInnerContour}
                                   detailData={detailData}
                                   variables={variables}
                />
            case "notchRound":
            case "editNotchRound":
                return <NotchRoundModal type={type} detailData={detailData}
                                        onClose={onClose} onSubmit={onSubmit}
                                        editableElementId={editableElementId}
                                        openConfirmModal={openConfirmModal}
                                        setActivePointId={setActivePointId}
                                        calcPrevInnerContour={calcPrevInnerContour}
                                        variables={variables}
                />
            case "groove":
            case "editGroove":
                return <GrooveModal type={type} detailData={detailData}
                                    onClose={onClose} onSubmit={onSubmit}
                                    editableElementId={editableElementId}
                                    openConfirmModal={openConfirmModal}
                                    setActivePointId={setActivePointId}
                                    calcPrevInnerContour={calcPrevInnerContour}
                                    variables={variables}/>
            case "quarter":
            case "editQuarter":
                return <QuarterModal type={type} onClose={onClose}
                                     onSubmit={onSubmit}
                                     detailData={detailData}
                                     editableElementId={editableElementId}
                                     openConfirmModal={openConfirmModal}
                                     setActivePointId={setActivePointId}
                                     points={cutData.possible}
                                     activePointId={activePointId}
                                     calcPrevInnerContour={calcPrevInnerContour}
                                     variables={variables}
                />
            case "editNotch":
                return <NotchContour onClose={onClose}
                                     onSubmit={onSubmit}
                                     openConfirmModal={openConfirmModal}
                                     detailData={detailData}
                                     activePointId={activePointId}/>
        }
    }

    return <Dialog
        open={!!type}
        PaperProps={{
            style: {
                minWidth: (type === "addEdge" || type === "editEdge") && "870px",
                position: 'absolute',
                left: refCanvas?.current?.clientWidth - refCanvas?.current?.offsetLeft,
            }
        }}
        BackdropProps={{
            style: {
                opacity: 0.1,
            },
        }}>
        {getContent()}
    </Dialog>
}
export default ModalProcessing;
