import React, {useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import {SIDE} from "../../../../../../Constants";
import {calcPrevQuarterXY} from "../../Canvas/CanvasCalcPositions/calcPrevQuarterXY";
import {noExponents} from "../../../../../../utils/processing/noExponents";
import {calcValueOfStringWithVar} from "../../Canvas/CanvasCalcPositions/calcValueOfStringWithVar";
import ButtonExit from "../../../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../../../components/UI/button/ButtonAdd";

const QuarterModal = ({
                                 type,
                                 onClose,
                                 onSubmit,
                                 detailData,
                                 editableElementId,
                                 openConfirmModal,
                                 setActivePointId,
                                 activePointId,
                                 points,
                                 calcPrevInnerContour,
                                 variables,
                             }) => {
    const pointsArr = points.filter(el => {
        return ((el.curr.x === el.next.x || el.curr.y === el.next.y) && el.mainContour)
    })

    const initData = detailData.quarters.find(el => el.id === editableElementId)

    const initFormState = type === "editQuarter" ? {
        "pointId": initData.sideStartPointId,
        "position": initData.contour.position,
        "distToStartPoint": initData.variables.find(el => el.name === "DIST_TO_START_POINT")?.pattern || initData.distToStartPoint,
        "length": initData.variables.find(el => el.name === "LENGTH")?.pattern || initData.length,
        "width": initData.variables.find(el => el.name === "WIDTH")?.pattern || initData.width,
        "depth": initData.variables.find(el => el.name === "DEPTH")?.pattern || initData.depth,
    } : {
        "pointId": activePointId,
        "position": SIDE[0].value,
        "distToStartPoint": "",
        "length": "",
        "width": "",
        "depth": ""
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "position": "", "distToStartPoint": "", "pointId": "", "length": "", "width": "", "depth": ""
    })


    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                pointId: formState.pointId,
                params: {
                    "positionTop": formState.position === "TOP", "distToStartPoint": formState.distToStartPoint,
                    "length": formState.length, "width": formState.width, "depth": formState.depth
                }
            })
        }
    }

    const inputHandler = (getData) => {
        const data = getData()

        const currState = {...formState, ...data}
        setFormState(currState)
        data.pointId && setActivePointId(data.pointId)

        const paramsToPrevContour = [currState.distToStartPoint, currState.width, currState.length]

        if (paramsToPrevContour.every(str => str.toString().trim() !== '')) {
            const currPoint = pointsArr.find(el => el.curr.id === currState.pointId)
            try {
                const calcValuesToPrevContour = paramsToPrevContour.map(str => {
                    return calcValueOfStringWithVar(variables, str.toString())
                })

                const {
                    x,
                    y,
                    horizontal
                } = calcPrevQuarterXY(currPoint, +calcValuesToPrevContour[0], +calcValuesToPrevContour[1], +calcValuesToPrevContour[2], detailData)

                calcPrevInnerContour({
                    width: horizontal ? currState.length : currState.width,
                    length: horizontal ? currState.width : currState.length,
                    x: x,
                    y: y,
                    distToStartPoint: currState.distToStartPoint,
                }, currState.position, false)
            } catch (error) {
                calcPrevInnerContour({
                    width: currState.width,
                    length: currState.length,
                    distToStartPoint: currState.distToStartPoint,
                }, currState.position, false, currPoint)
            }
        }
    }

    return (<>
        <DialogTitle textAlign="center">{type === "quarter" ? "Добавить четверть" : "Изминить четверть"}</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                {!editableElementId && <TextFieldValidation
                    select
                    name="pointId"
                    label="Узел"
                    value={formState.pointId}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {pointsArr.map((option) => (
                        <MenuItem key={option.curr.id} value={option.curr.id}>
                            x= {+option.curr.x.toFixed(2)}, y= {+option.curr.y.toFixed(2)}
                        </MenuItem>))}
                </TextFieldValidation>}
                <TextFieldValidation
                    select
                    name="position"
                    label="Позиция"
                    value={formState.position}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {[SIDE[0], SIDE[1]].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextFieldValidation>
                <TextFieldValidation
                    name={"distToStartPoint"}
                    value={formState?.distToStartPoint}
                    label="Расстояние от узла"
                    setCurrValues={inputHandler}
                    validationError={validationState?.distToStartPoint}
                    setValidationState={setValidationState}
                    adornment={type === "editQuarter" && +parseFloat(noExponents(initData?.distToStartPoint)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"length"}
                    value={formState?.length}
                    label="Длинна"
                    setCurrValues={inputHandler}
                    validationError={validationState?.length}
                    setValidationState={setValidationState}
                    adornment={type === "editQuarter" && +parseFloat(noExponents(initData?.length)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"width"}
                    value={formState?.width}
                    label="Ширина"
                    setCurrValues={inputHandler}
                    validationError={validationState?.width}
                    setValidationState={setValidationState}
                    adornment={type === "editQuarter" && +parseFloat(noExponents(initData?.width)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"depth"}
                    value={formState?.depth}
                    label="Глубина"
                    setCurrValues={inputHandler}
                    validationError={validationState?.depth}
                    setValidationState={setValidationState}
                    adornment={type === "editQuarter" && +parseFloat(noExponents(initData?.depth)).toFixed(2)}
                />
                {type === "editQuarter" && <Button
                    size="small"
                    color="secondary"
                    onClick={() => openConfirmModal("deleteQuarter", editableElementId)}
                    variant="outlined"
                >
                    Удалить четверть
                </Button>}

            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Отменить"} onClick={onClose}/>
            <ButtonAdd text={"Выбрать"}  onClick={handleValidationData}/>
        </DialogActions>
    </>)
}
export default QuarterModal
