import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Stack, Tooltip} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import WarehouseMaterialSettingsService from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import {materialReserveState} from "../../../../../data/MaterialReserveState";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import CellMaterialEstimateUnitTypePrice from "../../orders/organization/estimate/CellMaterialReserveTypePrice";
import MaterialReserveUtil from "../../../../../utils/warehouse/MaterialReserveUtil";
import CellCheckbox from "../../../сheckbox/CellCheckbox";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ModalConfirm from "../../../modal/ModalConfirm";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";
import UnitService from "../../../../../API/orders/warehouse/UnitService";
import Common from "../../../../../utils/common/Common";
import {Delete} from "@mui/icons-material";
import {CreateSlabMaterialModal} from "./CreateSlabMaterialModal";
import CellMaterialEstimateBasePrice from "./CellMaterialEstimateBasePrice";

const ReservationMaterialEstimateUnitTable = ({
                                                  data,
                                                  setData,
                                                  isColumnMaterialWarehouse,
                                                  isSlab,
                                                  orderId,
                                                  orderHeaderId,
                                                  nameOrder,
                                                  isButtonPurchase,
                                                  estimateId,
                                                  year,
                                                  isIssue,
                                                  unablePackaging,
                                                  setUnablePackaging,
                                                  isLinear,
                                                  enableEditing,
                                                  packaging,
                                                  showSetOnIssue,
                                                  materialEstimateUnitId
                                              }) => {

    const allCalculation = MaterialReserveUtil.checkOrAllCalculation(data);

    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");

    const [openModal, setOpenModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [currentEditRow, setCurrentEditRow] = useState(null);
    const currentIsSlab = isSlab === 'true'
    const currentPackaging = !isSlab && !isLinear && packaging
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mR) => acc + mR.amountAlternativePrice, 0),
        [data]
    );

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mR) => acc + mR.amountBasePrice, 0),
        [data]
    );

    const sumAmountQuantity = useMemo(
        () => data.reduce((acc, mR) => acc + mR.quantity, 0),
        [data]
    );

    const sumIssued = useMemo(
        () => data.reduce((acc, mR) => acc + mR.issued, 0),
        [data]
    );

    useEffect(() => {
        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const navigate = useNavigate();

    const redirectEstimate = () => {
        navigate('/organization/orders/' + orderId + '/' + nameOrder + '/' + orderHeaderId + '/' + year);
    };

    const handleCloseSetPurchase = () => {
        setCurrentId(null);
        setCurrentEditRow(null);
        setOpenModal(false);
    };

    const handleSubmitSetPurchase = async () => {
        MaterialReserveService.issue(currentId,)
            .then(function (response) {
                let dataR = response.data;
                handleCloseSetPurchase()
                Common.handleUpdate(currentEditRow, dataR, data, setData)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        UnitService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };


    const setOnIssue = useCallback(
        (row) => {
            const id = row.original.id;
            setCurrentId(id);
            setOpenModal(true);
            setCurrentEditRow(row);
        },
        [data],
    );

    const handleDeleteRow = useCallback(
        (row) => {
            MaterialReserveService.delete(row.original.id, true, true, true, true)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'quantity',
                header: 'Необходимо.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountQuantity?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'materialReserveState',
                header: 'Статус',
                accessorKey: 'materialReserveState',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell}) => materialReserveState[cell.getValue()],
            },
            {
                id: 'supplier',
                header: 'Поставщик',
                accessorKey: 'materialWarehouse.materialPriceUnit.supplierMaterial.supplier.name',
                minSize: 15,
                maxSize: 15,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },];

        if (isColumnMaterialWarehouse) {
            baseColumns.splice(2, 0, {
                id: 'size', header: 'Размеры', accessorKey: 'size', minSize: 15, maxSize: 15,
            });
        }

        if (isIssue) {
            baseColumns.splice(4, 0, {
                id: 'issued',
                header: 'Выдано',
                accessorKey: 'issued',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return <li key={value}>
                        <Link
                            to={'/organization/issuance-material-reserve/' + row.original.id}>{value === null || value === undefined ? 0 : value.toFixed(3)}</Link>
                    </li>
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumIssued?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </Stack>
                ),
            });
        }

        //TODO
        if (currentPackaging) {
            baseColumns.splice(4, 0, {
                id: 'packaging',
                header: 'Собрано',
                accessorKey: 'packaging',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                /*    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unablePackaging}
                        setUnable={setUnablePackaging}
                    >
                    </CellCheckboxWithUnable>,*/
            });
        }

        if (isIssue) {
            baseColumns.splice(3, 0, {
                id: 'additional',
                header: 'Добавочный',
                accessorKey: 'additional',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell, row}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            });
        }

        if (!isIssue) {
            baseColumns.splice(5, 0, {
                id: 'basePrice',
                header: `Цена ${baseCurrency}`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) =>
                    <CellMaterialEstimateBasePrice
                        value={cell.getValue()}
                        price={row.original.basePrice}
                        typePrice={row.original.materialReserveTypePrice}
                    />,
            });
        }

        if (!isIssue) {
            baseColumns.splice(6, 0, {
                id: 'alternativePrice',
                header: `Цена ${alternativeCurrency}`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) =>
                    <CellMaterialEstimateBasePrice
                        value={cell.getValue()}
                        price={row.original.alternativePrice}
                        typePrice={row.original.materialReserveTypePrice}
                    />,
            });
        }

        if (true) {
            baseColumns.splice(7, 0, {
                id: 'amountBasePrice',
                header: `Сумма ${baseCurrency}`,
                accessorKey: 'amountBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (true) {
            baseColumns.splice(8, 0, {
                id: 'amountAlternativePrice',
                header: `Сумма ${alternativeCurrency}`,
                accessorKey: 'amountAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isIssue) {
            baseColumns.splice(9, 0, {
                id: 'materialReserveTypePrice',
                header: 'Тип цены',
                accessorKey: 'materialReserveTypePrice',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell, row}) => <CellMaterialEstimateUnitTypePrice
                    value={cell.getValue()}
                    row={row}
                />,
            });
        }

        if (!isIssue) {
            baseColumns.splice(10, 0, {
                accessorFn: (row) => {
                    if (row.alternativePrice === null) {
                    } else {
                        return new Date(row.alternativePrice.dateTime)
                    }
                },
                id: 'dateBasePrice',
                header: 'Дата цены',
                sortingFn: 'date',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            });
        }

        if (currentIsSlab) {
            baseColumns.splice(0, 0, {
                id: 'length',
                header: 'Ширина',
                accessorKey: 'materialWarehouse.materialPriceUnit.length',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (currentIsSlab) {
            baseColumns.splice(1, 0, {
                id: 'width',
                header: 'Высота',
                accessorKey: 'materialWarehouse.materialPriceUnit.width',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (!allCalculation) {
            baseColumns.splice(3, 0, {
                id: 'availabilityQuantity',
                header: 'Наличие',
                accessorKey: 'availabilityQuantity',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (!allCalculation) {
            baseColumns.splice(4, 0, {
                id: 'orderedQuantity',
                header: 'Заказано',
                accessorKey: 'orderedQuantity',
                minSize: 15,
                maxSize: 15,
            });
        }

        if (!allCalculation) {
            baseColumns.splice(1, 0, {
                id: 'needToOrderQuantity',
                header: 'Необходимо заказать',
                accessorKey: 'needToOrderQuantity',
                minSize: 15,
                maxSize: 15,
            });
        }

        return baseColumns;

    }, [data, setData, isSlab]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px', m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        }, size: 15, maxSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableEditing={enableEditing}
                getRowId={(originalRow) => originalRow.id}
                initialState={{
                    columnVisibility: {
                        id: false,
                    }, isFullScreen: false, density: "compact", pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '15px'
                    }}>
                        {row.original.quantity !== row.original.issued && row.original.materialReserveState === 'IN_STOCK'
                            ?
                            showSetOnIssue && <Tooltip arrow placement="right" title="Выдать штучно">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="success"
                                    onClick={() => setOnIssue(row)}>
                                    <PlaylistAddIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : showSetOnIssue && <Button
                            disabled={true}
                            size="small"
                            sx={{minWidth: 'auto', marginLeft: 'auto'}}
                            color="success"
                        >
                            <PlaylistAddIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>}
                        {true ? <Tooltip arrow placement="right" title="Удалить">
                            <Button
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip> : <Button
                            size="small"
                            sx={{minWidth: 'auto', marginLeft: 'auto'}}
                            color="error"
                            disabled={true}
                        >
                            <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>}
                    </Box>
                )}
                renderTopToolbarCustomActions={({table}) => {

                    return (<div>
                        <Box
                            sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                        >
                            <Button
                                color="secondary"
                                onClick={() => redirectEstimate()}
                                variant="contained"
                                size="small"
                            >
                                Вернуться в смету
                            </Button>
                            <Button
                                color="success"
                                onClick={() => setCreateModalOpen(true)}
                                variant="contained"
                                size="small"
                            >
                                Добавить резерв
                            </Button>
                        </Box>
                    </div>);
                }}

            />
            <ModalConfirm
                title={"Подтвердить выдачу?"}
                modalOpen={openModal}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
            <CreateSlabMaterialModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                handleCreateNewRow={handleCreateNewRow}
                id={materialEstimateUnitId}
                isSlab={isSlab}
            />
        </div>);
};

export default ReservationMaterialEstimateUnitTable;
