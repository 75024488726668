import {ButtonGroup} from "@mui/material";

export const ButtonGroupTabBar = ({children, activeTab}) => {
    return <ButtonGroup
        variant="outlined"
        aria-label="button group"
        sx={{
            height: "48px",
            "& button": {
                fontWeight: 500,
                border: (theme) => `${theme.palette.primary.main} solid 1px`,
                color: (theme) => theme.palette.text.main,
                textTransform: "none",
                padding: "12px",
                gap: "8px",
                "& .MuiSvgIcon-root": {display: "none"},
                [`&[tabindex='${activeTab}']`]: {
                    backgroundColor: (theme) => theme.palette.primary.light,
                    "& .MuiSvgIcon-root": {
                        display: "inline-block",
                        width: "17px"
                    }
                },
                '&:first-of-type': {
                    borderTopLeftRadius: "100px",
                    borderBottomLeftRadius: "100px"
                },
                '&:last-of-type': {
                    borderTopRightRadius: "100px",
                    borderBottomRightRadius: "100px"
                }
            }
        }}>
        {children}
    </ButtonGroup>
}