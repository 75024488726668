import {COLORS} from "../../../../../../Constants";
import Layer from "./Layer";
import React, {useState} from "react";

const Point = ({point, editableElement, scale, position, openPopoverActives, isRound}) => {
    const [hover, setHover] = useState(null)

    const typesToSelect = ["notch", "addEdge", "addHole", "editHole"]
    const active = editableElement.pointId === point.id && !(typesToSelect.includes(editableElement.type))

    const onclick = (event) => {
        event.stopPropagation()
        !isRound && openPopoverActives({pointId: point.id, x: event.x, y: event.y, arc: point.arcParams});
    }

    const onPointerOver = (event) => {
        event.stopPropagation()
        !isRound && setHover(true)
    }
    const onPointerOut = (event) => {
        event.stopPropagation()
        !isRound && setHover(false)
    }

    return <Layer layer={2}>
        <mesh
            position={[point.x * scale + position[0], point.y * scale + position[1], position[2] + scale]}
            onClick={onclick}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
        >
            {(hover || active) && <circleGeometry args={[0.17]}/>}
            <meshBasicMaterial color={COLORS[7][500]} opacity={0.2} transparent toneMapped={false}/>
            <Layer layer={3}>
                <mesh>
                    <boxGeometry args={[0.15, 0.15, scale]}/>
                    <meshBasicMaterial color={active ? "black" : COLORS[7][800]}/>
                    <mesh>
                        <boxGeometry args={[0.12, 0.12, scale]}/>
                        <meshBasicMaterial color={COLORS[7][800]} toneMapped={false}/>
                    </mesh>
                </mesh>
            </Layer>
        </mesh>
    </Layer>
}
export default Point
