import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import ModelMaterialEstimateUnitService
    from "../../../../../API/model-estimate/estimate/ModelMaterialEstimateUnitService";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {typeMaterial} from "../../../../../data/TypeMaterial";
import ModalUpdateOrAddModalEstimateUnit from "./ModalUpdateOrAddModalEstimateUnit";
import Common from "../../../../../utils/common/Common";
import UnitService from "../../../../../API/orders/warehouse/UnitService";

const BasicModelEstimateTableItem = ({
                                         estimateId
                                     }) => {


        const [data, setData] = useState([]);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRow, setCurrentEditRow] = useState(null);
        const [rowSelection, setRowSelection] = useState({});
        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [listUnits, setListUnits] = useState([]);
        const [unitMap, setUnitMap] = useState(new Map());
        const [unitId, setUnitId] = useState(null);

        useEffect(() => {

            ModelMaterialEstimateUnitService.getAllByEstimateId(estimateId).then(function (response) {
                const data = response.data;
                setData(data);
            })
                .catch(function (error) {
                    console.log(error);
                });

            UnitService.getAll().then(function (response) {
                let data = response.data;
                setListUnits(data)
                data.forEach(unit => {
                    unitMap.set(unit.id.toString(), unit.name);
                });
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.original.id;
                ModelMaterialEstimateUnitService.delete(id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRow(row);
                setCreateOrUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const isNew = currentEditRowData === null ? true : currentEditRowData.id === null

        const handleCloseCreateOrUpdateModalOpen = () => {
            setCreateOrUpdateModalOpen(false)
            setCurrentEditRowData(null);
            setCurrentEditRow(null);
        };

        const handleSubmit = () => {

            setUnit();
            isNew ? ModelMaterialEstimateUnitService.create(currentEditRowData, estimateId,
                ).then(function (response) {
                    let dataR = response.data;
                    Common.handleCreateNewRow(dataR, data, setData)
                    handleCloseCreateOrUpdateModalOpen();
                })
                    .catch(function (error) {
                        console.log(error);
                    }) :

                ModelMaterialEstimateUnitService.update(currentEditRowData, estimateId,
                ).then(function (response) {
                    let dataR = response.data;
                    Common.handleUpdate(currentEditRow, dataR, data, setData)
                    handleCloseCreateOrUpdateModalOpen();
                })
                    .catch(function (error) {
                        console.log(error);
                    });
        }

        const setUnit = () => {
            currentEditRowData.unitId = unitId ? unitId : currentEditRowData.unitId ? currentEditRowData.unitId.toString() : "1";
            currentEditRowData.unitName = unitMap.get(currentEditRowData.unitId);
        }

        const handleCreateRow = () => {
            const modelMaterialEstimateUnit = {
                typeMaterial: 'SLAB',
                quantity: 0,
                tempName: null,
                note: null,
                id: null,
                unitId: 1,
            };
            setCurrentEditRowData(modelMaterialEstimateUnit);
            setCreateOrUpdateModalOpen(true);
        };

        const columns = useMemo(
            () => [
                {
                    id: 'tempName',
                    header: 'Найменование',
                    accessorKey: 'tempName',
                    minSize: 50,
                    maxSize: 50,
                    enableEditing: false,
                },
                {
                    id: 'quantity',
                    header: 'Кол.',
                    accessorKey: 'quantity',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    id: 'unitName',
                    header: 'Ед. изм.',
                    accessorKey: 'unitName',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    id: 'typeMaterial',
                    header: 'Тип материала',
                    accessorKey: 'typeMaterial',
                    minSize: 5,
                    maxSize: 5,
                    Cell: ({cell}) => typeMaterial[cell.getValue()],
                },
                {
                    id: 'note',
                    header: 'Примечание',
                    accessorKey: 'note',
                    minSize: 5,
                    maxSize: 5,
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                },
            ],
            [data, setData],
        );

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        sorting: [{id: 'unitName', desc: false}, {id: 'tempName', desc: false}],
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    enableEditing
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection={false}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const setCurrency = (typeCurrentCurrency) => {
                        };

                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                </Box>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        color="success"
                                        onClick={() => handleCreateRow()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Создать базовый материал
                                    </Button>
                                </Box>
                            </div>
                        );
                    }}
                />
                <ModalUpdateOrAddModalEstimateUnit
                    open={createOrUpdateModalOpen}
                    handleClose={() => handleCloseCreateOrUpdateModalOpen()}
                    title={isNew ? "Создать материал" : "Обновить материал"}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    handleSubmit={handleSubmit}
                    isNew={isNew}
                    listUnits={listUnits}
                    setUnitId={setUnitId}
                />
            </div>
        );
    }
;

export default BasicModelEstimateTableItem;

