import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import React, {useEffect, useState} from "react";

import Box from "@mui/material/Box";
import UserSupplierMaterialService from "../../../../../../API/orders/warehouse/material/UserSupplierMaterialService";
import SupplierTable from "../../supplier/SupplierTable";
import ArrivalMaterialTextField from "../arrival/ArrivalMaterialTextField";
import TextFieldObjectWithSupplierMaterialList from "./TextFieldObjectWithSupplierMaterialList";
import MaterialPriceUnitUtil from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import {setMaterialPriceUnit} from "../arrival/UpdateOrAddMaterialPriceUnitModule";

const steps = [
    'Постащик',
    'Тип поставки',
    'Размеры',
];

function setMaterialPriceUnitByFound(foundMaterial, dataFormat, id, setIsFixedStripe, setIsFixedWhole,
                                     baseCurrency, alternativeCurrency, setPrice) {
    if (foundMaterial) {
        dataFormat.supplierMaterial = foundMaterial;
        dataFormat.supplierMaterialId = id;
        setIsFixedStripe(foundMaterial.typeSupplierMaterial === "BY_FIXED_STRIPE");
        setIsFixedWhole(foundMaterial.typeSupplierMaterial === "WHOLE")
        const basePrice = foundMaterial.basePrice
        const currentCurrency = basePrice.currency.curAbbreviation;
        setMaterialPriceUnit(basePrice, setPrice,
            currentCurrency, baseCurrency,
            alternativeCurrency,
            foundMaterial,
            null,
            true,
            dataFormat.materialPriceUnit,
            false,
            true)

    } else {
        console.warn(`Material with id ${id} not found`);
    }
}

function getAndSetSupplierMaterials(data, supplier, setSupplierMaterials, dataFormat, setIsFixedStripe, setIsFixedWhole,
                   baseCurrency, alternativeCurrency, setPrice) {
    const supplierMaterials = data.get(supplier.id);
    setSupplierMaterials(supplierMaterials);
    setMaterialPriceUnitByFound(supplierMaterials[0], dataFormat,
        supplierMaterials[0].id, setIsFixedStripe, setIsFixedWhole,
        baseCurrency, alternativeCurrency, setPrice);
}

const ModalAddWarehouseMaterial = ({
                                       open, onClose, title, materialId,
                                       supplierMaterials, setSupplierMaterials,
                                       dataSuppliers, setDataSuppliers,
                                       baseCurrency, alternativeCurrency,
                                       handleSubmitCreate
                                   }) => {


    const [activeStep, setActiveStep] = useState(0)
    const [data, setData] = useState([])
    const [isFixedStripe, setIsFixedStripe] = useState(false)
    const [isFixedWhole, setIsFixedWhole] = useState(false)
    const [price, setPrice] = useState([])

    const [dataFormat, setDataFormat] = useState({
        supplier: null,
        supplierMaterialId: null,
        supplierMaterial: null,
        materialPriceUnit: MaterialPriceUnitUtil.materialPriceUnit
    })

    useEffect(() => {
        UserSupplierMaterialService.getAllByMaterialOrganizationOption(materialId)
            .then(response => {
                const rawData = response.data;
                const supplierMap = new Map();
                rawData.forEach(item => {
                    const supplierKey = item.supplier.id;
                    if (!supplierMap.has(supplierKey)) {
                        supplierMap.set(supplierKey, []);
                    }
                    supplierMap.get(supplierKey).push(item);
                });
                setData(supplierMap);
                const suppliersArray = Array.from(supplierMap.values()).map(items => items[0].supplier);
                setDataSuppliers(suppliersArray);
                dataFormat.supplier = suppliersArray[0];
                getAndSetSupplierMaterials(supplierMap, suppliersArray[0], setSupplierMaterials, dataFormat,
                    setIsFixedStripe, setIsFixedWhole, baseCurrency, alternativeCurrency, setPrice);
            })
            .catch(error => {
                console.log(error);
            });
    }, [materialId, baseCurrency, alternativeCurrency]);

    const submit = () => {
        if (activeStep < 2) {
            setActiveStep(activeStep + 1);
        } else {
            handleSubmitCreate(dataFormat);
            setActiveStep(0);
        }
    }

    const reset = () => {
        setActiveStep(0)
        onClose()
    }

    const back = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        } else {
            onClose();
        }
    }

    const selectSupplier = (supplier) => {
        dataFormat.supplier = supplier;
        getAndSetSupplierMaterials(data, supplier, setSupplierMaterials, dataFormat,
            setIsFixedStripe, setIsFixedWhole, baseCurrency, alternativeCurrency, setPrice);
    }


    const setField = (value) => {
        dataFormat.materialPriceUnit = value
    }

    const setSupplierMaterial = (id) => {
        const foundMaterial = supplierMaterials.find(material => material.id.toString() === id);
        setMaterialPriceUnitByFound(foundMaterial, dataFormat,
            id, setIsFixedStripe, setIsFixedWhole, baseCurrency, alternativeCurrency, setPrice);
    }

    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: `${activeStep === 0 ? "40vw" : "700px"}`
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box style={{
                    height: 24,
                    marginTop: 30,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    border: dataFormat.supplier ? "blue 1px solid" : "transparent 1px solid"
                }}>
                    <Box
                        style={{marginRight: 40}}>{dataFormat.supplier !== null && dataFormat.supplier !== undefined ? dataFormat.supplier.name : ""}</Box>
                </Box>
                {activeStep === 0 ?
                    <Box style={{height: 700}}>
                        <SupplierTable
                            data={dataSuppliers}
                            selectSupplier={selectSupplier}
                        />
                    </Box>
                    : activeStep === 1 ?
                        <Box style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <Box style={{width: 300, paddingTop: 20}}>
                                <TextFieldObjectWithSupplierMaterialList
                                    label="Тип поставки"
                                    values={dataFormat}
                                    field={'supplierMaterialId'}
                                    setId={setSupplierMaterial}
                                    entityList={supplierMaterials}
                                    name={'typeSupplierMaterial'}
                                />
                            </Box>
                        </Box> :
                        <Box style={{height: 700}}>
                            <Stack
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '0.01rem',
                                }}
                            >
                                <ArrivalMaterialTextField
                                    label="Количество"
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'quantity'}
                                    disabled={isFixedStripe}
                                />
                                <ArrivalMaterialTextField
                                    label="Ширина"
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'length'}
                                    disabled={isFixedWhole || isFixedStripe}
                                />
                                <ArrivalMaterialTextField
                                    label="Высота"
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'width'}
                                    disabled={isFixedWhole || isFixedStripe}
                                />
                                <ArrivalMaterialTextField
                                    label={`Сумма в ${price}`}
                                    values={dataFormat.materialPriceUnit}
                                    setValues={setField}
                                    field={'amountInCurrencyArrival'}
                                    disabled={true}
                                />
                            </Stack>
                        </Box>
                }
            </DialogContent>

            <DialogActions sx={{p: '1.25rem'}}>
                {activeStep !== 0 && <Button onClick={back}> Назад </Button>}
                <Button onClick={reset}> Выйти </Button>
                <Button color="secondary"
                        onClick={submit}
                        variant="contained"
                        disabled={activeStep === 2 && dataFormat.materialPriceUnit.supplierMaterial == null}>
                    Выбрать
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default ModalAddWarehouseMaterial