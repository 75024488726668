import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";
import axios from "axios";

const fileRestOrdersUrl = gatewayUrl + nameOrders + apiVersionOrders + '/customers/files';

export default class FileService {
    static getFormData(selectedFile) {
        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        return formData;
    }

    static getFormDataArray(files) {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });
        return formData;
    }

    static async uploadRequestsCuttingFiles(files, calculationRequestsCutting, orderId) {
        return await axios.post(fileRestOrdersUrl + '/upload/order-requests-cutting-files/for-basis' + "?orderId="
            + orderId,
            FileService.getFormDataArray(files), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async uploadRequestsCuttingFilesWithSample(files, calculationRequestsCutting, orderId) {
        return await axios.post(fileRestOrdersUrl + '/upload/order-requests-cutting-files/for-basis-with-sample' + "?orderId="
            + orderId,
            FileService.getFormDataArray(files), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

    }

    static async uploadOrderSample(files, calculationRequestsCutting, requestCuttingId) {
        return await axios.post(fileRestOrdersUrl + '/upload/order-sample-file' + "?requestCuttingId="
            + requestCuttingId,
            FileService.getFormDataArray(files), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

}