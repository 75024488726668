import React, {useMemo} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import {modelParameterUnit} from "../../../../../../data/ModelParameterUnit";

const ProductionCosts = ({data, setData, baseCurrency, alternativeCurrency}) => {

    const sumAmountBasePrice = useMemo(() => data.reduce((acc, mEU) => acc + mEU.costInOrderBasePrice, 0), [data]);

    const sumAmountAlternativePrice = useMemo(() => data.reduce((acc, mEU) => acc + mEU.costInOrderAlternativePrice, 0), [data]);

    const columns = useMemo(() => [{
        id: "materialName",
        header: 'Материал',
        accessorKey: 'materialName',
        enableEditing: false,
        enableGrouping: true,
        minSize: 35,
    }, {
        id: "signJobName",
        header: 'Найменование',
        accessorKey: 'signJobName',
        enableEditing: false,
        enableGrouping: true,
        minSize: 35,
    }, {
        id: "unitName",
        header: 'Ед.изм.',
        accessorKey: 'unitName',
        enableColumnActions: false,
        enableEditing: false,
        size: 8,
    }, {
        header: 'Объем в заказе',
        accessorKey: 'orderQuantity',
        enableColumnActions: false,
        enableEditing: false,
        maxSize: 15,
    }, {
        header: 'Трудоемкость в заказе',
        accessorKey: 'laborIntensityInOrder',
        enableColumnActions: false,
        enableEditing: false,
        minSize: 10,
        AggregatedCell: ({cell, table}) => (<>
            <Box sx={{
                color: 'secondary.main', fontWeight: 'bold'
            }}>
                {cell.getValue()?.toLocaleString?.('en-US', {
                    minimumFractionDigits: 0, maximumFractionDigits: 3,
                })}
            </Box>
        </>),
    }, {
        header: 'Стоимость в заказе BYN',
        accessorKey: 'costInOrderBasePrice',
        enableColumnActions: false,
        enableEditing: false,
        size: 8,
        Cell: ({cell, row}) => {
            const value = cell.getValue();
            return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
        },
        AggregatedCell: ({cell, table}) => (<>
            <Box sx={{
                color: 'secondary.main', fontWeight: 'bold'
            }}>
                {cell.getValue()?.toLocaleString?.('en-US', {
                    minimumFractionDigits: 0, maximumFractionDigits: 3,
                })}
            </Box>
        </>),
        Footer: () => (<Stack>
            <Box color="warning.main">
                {sumAmountBasePrice?.toLocaleString?.('en-US', {
                    minimumFractionDigits: 0, maximumFractionDigits: 2,
                })} {alternativeCurrency}
            </Box>
        </Stack>),
    }, {
        header: 'Стоимость в заказе USD',
        accessorKey: 'costInOrderAlternativePrice',
        enableColumnActions: false,
        enableEditing: false,
        size: 8,
        Cell: ({cell, row}) => {
            const value = cell.getValue();
            return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
        },
        AggregatedCell: ({cell, table}) => (<>
            <Box sx={{
                color: 'secondary.main', fontWeight: 'bold'
            }}>
                {cell.getValue()?.toLocaleString?.('en-US', {
                    minimumFractionDigits: 0, maximumFractionDigits: 3,
                })}
            </Box>
        </>),
        Footer: () => (<Stack>
            <Box color="warning.main">
                {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                    minimumFractionDigits: 0, maximumFractionDigits: 2,
                })} {alternativeCurrency}
            </Box>
        </Stack>),
    },
        {
            id: "metricName",
            header: 'Работа',
            accessorKey: 'metricName',
            enableEditing: false,
            enableGrouping: true,
            minSize: 35,
        },
        {
            id: "modelParameterUnit",
            header: 'Параметр с модели',
            accessorKey: 'modelParameterUnit',
            enableEditing: false,
            enableGrouping: true,
            minSize: 35,
            Cell: ({cell}) => modelParameterUnit[cell.getValue()],
        },

    ], [data, setData],);

    const handleEdit = (row) => {
        /*setCurrentEditRowData({
            material: {
                articleNumber: row.materialArticleNumber,
                id: row.materialId,
                name: row.materialName
            },
            edgeType: row.edgeType
        })
        setAddEdgeModalOpen(true)
        setCurrentEditRowId(row.id);
        setIsNew(false);*/
    };

    const handleDelete = (row) => {
        /*  OrderEdgeController.delete(row.id)
              .then(function () {
                  setData(prev => prev.filter(el => el.id !== row.id))
              })
              .catch(function (error) {
                  handleError(error)
              })*/
    };

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}
    ]

    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
        >
            <ButtonCreate
                text={"Добавить затраты"}
                width={190}
                /*   onClick={handleAddDetail}*/
                /* disabled={isError}*/
            />
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    /*   "&:first-of-type": {
                           padding: 0,
                           width: "100%",
                           height: "36px",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           color: (theme) => theme.palette.text.light,
                           "& .MuiButtonBase-root": {
                               borderRadius: "4px",
                               width: "24px",
                               height: "24px",
                               opacity: 1,
                               margin: 0,
                               padding: 0,
                               "&:hover": {
                                   backgroundColor: (theme) => theme.palette.primary.stroke,
                               }
                           }
                       },*/
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",

                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.primary.light,
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light
                            },
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 10,
                    grow: false,
                },
                'mrt-row-select': {
                    size: 10,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={data}
            rowHeight={13}
            columnSpacing={1}
            enableExpanding={true}
            enableGrouping={true}
            initialState={{
                columnVisibility: {
                    id: false,
                },
                isFullScreen: false,
                density: "compact",
                pagination: {pageIndex: 0, pageSize: 50},
                grouping: ['materialName', 'signJobName'], //
                sorting: [{id: 'materialName', desc: false}, {id: 'signJob.name', desc: false}],
            }}

            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            enableRowActions={true}
            enableToolbarInternalActions={false}
            getRowId={(originalRow) => originalRow.id}
            localization={MRT_Localization_RU}
        />
    </div>);
};

export default ProductionCosts;