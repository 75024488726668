import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Stack, Tooltip} from "@mui/material";
import MaterialEstimateUnitService
    from "../../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import ModalConfirm from "../../../../modal/ModalConfirm";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import {Link} from "react-router-dom";
import CellMaterialReserveOptimizationQuantity from "./CellMaterialReserveOptimizationQuantity";
import ReplacementSimilarMaterials
    from "../../../../../../pages/organization/orders/estimate/ReplacementSimilarMaterials";
import MenuItem from "@mui/material/MenuItem";

const EstimateTableItem = ({
                               estimateId,
                               orderId,
                               orderNumber,
                               orderHeaderId,
                               year,
                               isButtonPurchase,
                               isActual,
                               enableEditing,
                               inWork,
                               orderCloud,
                               isFact,
                               data,
                               setData,
                               isBlockedReplacement,
                               baseCurrency,
                               alternativeCurrency
                           }) => {

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentMaterialData, setCurrentMaterialData] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    /*    const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());*/
    const [rowSelection, setRowSelection] = useState({});

    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
    const [change, setChange] = useState(false);
    const [currentInWork, setCurrentInWork] = useState(inWork);

    const [searchSimilarMaterials, setSearchSimilarMaterials] = useState(false);

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountBasePrice, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountAlternativePrice, 0),
        [data]
    );

    const sumCalculationAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.calculationAmountBasePrice, 0),
        [data]
    );

    const sumCalculationAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.calculationAmountAlternativePrice, 0),
        [data]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            const materialId = row.original.id
            MaterialEstimateUnitService.delete(id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                    /*  materialPriceUnits.delete(materialId);*/
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleSubmitSetPurchase = async () => {
        EstimateService.setPurchase(estimateId)
            .then(function () {
                handleCloseSetPurchase()
                setCurrentInWork(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleCloseSetPurchase = () => {
        setSetPurchaseModalOpen(false);
    };

    const openSearchSimilarMaterials = useCallback(
        (row) => {
            setCurrentMaterialData(row.original.materialOrganizationOption);
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.index)
            setSearchSimilarMaterials(true)
        },
        [],
    );

    const replacement = async () => {
        const id = currentEditRowData.id
        const materialOrganizationOptionId = currentMaterialData.id
        MaterialEstimateUnitService.replacementByMaterialOrganizationOptionId(id, materialOrganizationOptionId)
            .then(function (response) {
                handleUpdate(response.data)
                setSearchSimilarMaterials(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.index);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleUpdate = (values) => {
        data[currentEditRowId] = values;
        setData([...data]);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.articleNumber : '';
                }
            },
            {
                id: 'requestsCuttingNumber',
                header: 'Заявка',
                accessorKey: 'requestsCutting.number',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row, rowIndex}) => {
                    const {materialOrganizationOption, requestsCutting} = row.original;

                    if (materialOrganizationOption === null) {
                        return '';
                    }

                    if (materialOrganizationOption.typeMaterial === 'SLAB') {
                        const nominalQuantity = requestsCutting ? requestsCutting.nominalQuantity.toFixed(3) : '';

                        return (
                            <Tooltip title={"В заявке на раскрой " + nominalQuantity + " кв. м"} arrow>
                                {requestsCutting === null ? (
                                    'Ожидание'
                                ) : (
                                    <Link
                                        to={'/organization/requests-cutting/detail/' + requestsCutting.id + '/' + requestsCutting.number}>
                                        {cell.getValue()}
                                    </Link>
                                )}
                            </Tooltip>
                        );
                    } else if (materialOrganizationOption.subTypeMaterial === "EDGE") {
                        return 'Кромочный';
                    } else {
                        return 'Фурнитура';
                    }
                }
            },
            {
                id: 'unit',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.unit.name : row.original.tempUnit.name;
                }
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => (row.original.materialOrganizationOption === null) ? '' :
                    <CellMaterialReserveOptimizationQuantity
                        value={cell.getValue()}
                        row={row}
                    />
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ];

        if (!isFact) {
            baseColumns.splice(1, 0, {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row, rowIndex}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    const isSlab = temp ? null : row.original.materialOrganizationOption.typeMaterial === 'SLAB';
                    const link = temp ? null : <Link
                        to={'/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId
                            + '/' + year + '/estimates/' + estimateId + '/' + isButtonPurchase + '/reservation/'
                            + row.original.id + '/' + isSlab + '/' + encodeURIComponent(row.original.materialOrganizationOption.name)
                            + '/' + encodeURIComponent(row.original.materialOrganizationOption.articleNumber) + '/' + 'calculation/false'}>
                        {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                    </Link>
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {link}
                            </span>
                    );
                },
            });
        }

        if (isFact) {
            baseColumns.splice(1, 0, {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row, rowIndex}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    const isSlab = temp ? null : row.original.materialOrganizationOption.typeMaterial === 'SLAB';
                    const link = temp ? null : <Link
                        to={'/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId
                            + '/' + year + '/estimates/' + estimateId + '/' + isButtonPurchase + '/reservation/'
                            + row.original.id + '/' + isSlab + '/' + encodeURIComponent(row.original.materialOrganizationOption.name)
                            + '/' + encodeURIComponent(row.original.materialOrganizationOption.articleNumber) + '/' + 'plan/true'}>
                        {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                    </Link>
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {link}
                            </span>
                    );
                },
            });
        }

        baseColumns.splice(10, 0, {
            id: 'costBasePrice',
            header: `Стоимость в ${baseCurrency}`,
            accessorKey: 'costBasePrice.price',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {sumAmountBasePrice?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })} {baseCurrency}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(10, 0, {
            id: 'costAlternativePrice',
            header: `Стоимость в ${alternativeCurrency}`,
            accessorKey: 'costAlternativePrice.price',
            minSize: 15,
            maxSize: 15,
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })} {alternativeCurrency}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(10, 0, {
            id: 'reservePercentage',
            header: '% резерва',
            accessorKey: 'reservePercentage',
            minSize: 15,
            maxSize: 15,
        });

        return baseColumns;
    }, [data, setData]);

    const handleEdit = (row) => {
        /*setCurrentEditRowData({
            material: {
                articleNumber: row.materialArticleNumber,
                id: row.materialId,
                name: row.materialName
            },
            edgeType: row.edgeType
        })
        setAddEdgeModalOpen(true)
        setCurrentEditRowId(row.id);
        setIsNew(false);*/
    };

    const handleDelete = (row) => {
        /*  OrderEdgeController.delete(row.id)
              .then(function () {
                  setData(prev => prev.filter(el => el.id !== row.id))
              })
              .catch(function (error) {
                  handleError(error)
              })*/
    };

    const menuItem = [
        {type: "edit", text: "Заменить", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}
    ]

    return (
        <div>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        /*   "&:first-of-type": {
                               padding: 0,
                               width: "100%",
                               height: "36px",
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                               color: (theme) => theme.palette.text.light,
                               "& .MuiButtonBase-root": {
                                   borderRadius: "4px",
                                   width: "24px",
                                   height: "24px",
                                   opacity: 1,
                                   margin: 0,
                                   padding: 0,
                                   "&:hover": {
                                       backgroundColor: (theme) => theme.palette.primary.stroke,
                                   }
                               }
                           },*/
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light,
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light
                                },
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    sorting: [{id: 'unit', desc: false}],
                }}
                enableEditing={enableEditing}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                /* selectionCss={{
                     backgroundColor: 'lightblue',
                     borderWidth: '2px',
                     borderStyle: 'solid',
                     borderColor: 'blue',
                     borderRadius: '4px',
                 }}*/
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })
                }
                enableRowActions={true}
                enableToolbarInternalActions={false}

                /*renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!row.original.isBlocked ?
                            <Tooltip arrow placement="left" title="Заменить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="secondary"
                                        onClick={() => openSearchSimilarMaterials(row)}>
                                    <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> :
                            <Tooltip arrow placement="left" title="Материал заблокирован">
                                <Button size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="secondary"
                                        disabled={true}
                                >
                                    <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        }
                        {!row.original.isBlocked ? <Tooltip arrow placement="right" title="Удалить">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="error"
                                    onClick={() => handleDeleteRow(row)}
                                >
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> :
                            <Tooltip arrow placement="left" title="Материал заблокирован">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="error"
                                    onClick={() => handleDeleteRow(row)}
                                    disabled={true}
                                >
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                    </Box>
                )}*/
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const setCurrency = (typeCurrentCurrency) => {
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                            </Box>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                {isButtonPurchase === 'true' && <Button
                                    size="small"
                                    color="success"
                                    onClick={() => setSetPurchaseModalOpen(true)}
                                    variant="contained"
                                    disabled={!currentInWork}
                                >
                                    Подать заявку на закупку
                                </Button>}
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalConfirm
                title={"Подтвердить создание заявки на закупку?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
            {currentMaterialData ? <ReplacementSimilarMaterials
                open={searchSimilarMaterials}
                setOpen={setSearchSimilarMaterials}
                replacement={replacement}
                currentEditRowData={currentMaterialData}
                setCurrentEditRowData={setCurrentMaterialData}
                id={currentEditRowData.id}
            /> : ""}
        </div>
    );
};

export default EstimateTableItem;

