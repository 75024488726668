import {Button, Typography} from "@mui/material";

const ButtonCancel = ({onClick, text, width = 176, height = 56, disabled = false}) => {
    return <Button
        onClick={onClick}
        variant="outlined"
        disabled={disabled}
        sx={{
            width: width,
            height: height,
            borderRadius: "100px",
            color: (theme) => theme.palette.text.main,
            border: (theme) => `${theme.palette.secondary.add} 1px solid`,
            padding: "0 24px",
            textTransform: 'none',
            "&:hover": {
                border: (theme) => `${theme.palette.secondary.add} 1px solid`,
                backgroundColor: "inherit",
            }
        }}
    >
        <Typography>{text}</Typography>
    </Button>
}
export default ButtonCancel