import React from 'react';
import MainOptionsMaterialModuleEntityTextField from "./MainOptionsMaterialModuleEntityTextField";
import {Button, DialogContent, Stack} from "@mui/material";
import TextFieldWithSetValueMainOptions from "./TextFieldWithSetValueMainOptions";
import Box from "@mui/material/Box";
import MaterialTextFieldObjectWithEntityList from "./MaterialTextFieldObjectWithEntityList";
import {materialCutDefault} from "../../../../data/MaterialCutDefault";
import {statusesYesOrNo} from "../../../../data/StatusesYesOrNo";
import {typeMaterial} from "../../../../data/TypeMaterial";
import TextFieldArrivalMaterialModuleItem from "../arrivalmaterialmoduleitem/TextFieldArrivalMaterialModuleItem";

const CurrentOptionsMaterialModule = ({
                                          data,
                                          setData,
                                          supplierList,
                                          groupMaterialList,
                                          manufacturerList,
                                          handleSubmit,
                                          addMaterial,
                                          setSupplierId,
                                          setManufacturerId,
                                          setGroupMaterialId,
                                          baseUnit,
                                          isDataMOO,
                                          isMaterialOrganizationOption
                                      }) => {

    const isSlab = isMaterialOrganizationOption ? data && data.typeMaterial === 'SLAB' : data && data.typeMaterial === 'SLAB';

    return (
        <div>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        "& > *:not(:last-child)": {marginRight: '20px'}
                    }}>
                        {isDataMOO && <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                maxWidth: '500px',
                            }}
                        >
                            <Box>
                                <TextFieldWithSetValueMainOptions
                                    label="Текущее найменование"
                                    values={data}
                                    setValues={setData}
                                    field={'articleNumber'}
                                />
                                <TextFieldWithSetValueMainOptions
                                    label="Текущее найменование"
                                    values={data}
                                    setValues={setData}
                                    field={'name'}
                                />
                                {supplierList && <MainOptionsMaterialModuleEntityTextField
                                    label={'Поставщик по умолчанию'}
                                    values={data}
                                    field={'defaultSupplier'}
                                    childFirstField={'id'}
                                    setId={setSupplierId}
                                    entityList={supplierList}
                                />}
                                <TextFieldArrivalMaterialModuleItem
                                    label="Ед. измерения"
                                    value={baseUnit}
                                    field={baseUnit}
                                    maxWidth={'600px'}
                                    disabled={true}
                                />
                                <MaterialTextFieldObjectWithEntityList
                                    label="Тип материала"
                                    values={data}
                                    setValues={setData}
                                    field={'typeMaterial'}
                                    entityList={typeMaterial}
                                    disabled={true}
                                />
                                <MaterialTextFieldObjectWithEntityList
                                    label="Архив"
                                    values={data}
                                    setValues={setData}
                                    field={'archive'}
                                    entityList={statusesYesOrNo}
                                />
                                {!isSlab && <MaterialTextFieldObjectWithEntityList
                                    label="Выдача не по смете"
                                    values={data}
                                    setValues={setData}
                                    field={'deliveryNotAccordingToEstimate'}
                                    entityList={statusesYesOrNo}
                                />}
                            </Box>
                        </Stack>}
                        {isDataMOO && <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                maxWidth: '200px',
                            }}
                        >
                            {isSlab && <MaterialTextFieldObjectWithEntityList
                                label="Кроить по умолчанию"
                                values={data}
                                setValues={setData}
                                field={'cutDefault'}
                                entityList={materialCutDefault}
                            />}

                            {isSlab && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп слева"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingLeft'}
                                    marginRight={'10px'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп справа"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingRight'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                            </Box>}
                            {isSlab && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                maxWidth: '100%',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп снизу"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingBottom'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    marginRight={'10px'}
                                />
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп сверху"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingTop'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                            </Box>}
                            {isSlab && <TextFieldWithSetValueMainOptions
                                label="Минимальный остаток до целого листа"
                                values={data}
                                setValues={setData}
                                field={'minBalanceUpToWholeSheet'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />}
                            {<TextFieldWithSetValueMainOptions
                                label="Плотность, Вес"
                                values={data}
                                setValues={setData}
                                field={'density'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                            />}
                            {isSlab && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Мин. ширина обрезка"
                                    values={data}
                                    setValues={setData}
                                    field={'minWeightCutOff'}
                                    marginRight={'10px'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                                <TextFieldWithSetValueMainOptions
                                    label="Мин. высота обрезка"
                                    values={data}
                                    setValues={setData}
                                    field={'minHeightCutOff'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                            </Box>}
                            {isSlab && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп обрезка слева"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingLeftCutOff'}
                                    marginRight={'10px'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп обрезка справа"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingRightCutOff'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                            </Box>}
                            {isSlab && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                maxWidth: '100%',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп обрезка снизу"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingBottomCutOff'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    marginRight={'10px'}
                                />
                                <TextFieldWithSetValueMainOptions
                                    label="Отсуп обрезк сверху"
                                    values={data}
                                    setValues={setData}
                                    field={'trimmingTopCutOff'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                            </Box>}
                            {isSlab && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                maxWidth: '100%',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Ширина реза"
                                    values={data}
                                    setValues={setData}
                                    field={'cutWeight'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                                <Box sx={{marginLeft: '10px'}}/>
                                <TextFieldWithSetValueMainOptions
                                    label="Кол. поворотов"
                                    values={data}
                                    setValues={setData}
                                    field={'limitNumberTurns'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                />
                            </Box>}
                        </Stack>}
                    </Box>
                    {isDataMOO && <Button
                        color="secondary"
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        Применить
                    </Button>}
                    {!isDataMOO && <Button
                        color="secondary"
                        onClick={addMaterial}
                        variant="contained"
                    >
                        Добавить
                    </Button>}
                </form>
            </DialogContent>
        </div>
    );
};

export default CurrentOptionsMaterialModule;