import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const packingMaterialRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/user/job-metric-packing-material'

export default class UserJobMetricPackingMaterial {

    static async getById(materialId) {

        return axios.get(`${packingMaterialRestUrl}/by-material-id?materialId=${materialId}`);
    }
}