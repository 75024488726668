import {Breadcrumbs, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import React from "react";

export const BreadcrumbsComponent = ({path, page}) => {
    return <Breadcrumbs sx={{padding: "30px 0"}}>
        {path.map(el => {
            return <Link
                key={`${el.name}${el.href}`}
                sx={{color: (theme) => theme.palette.text.light,}}
                underline="hover" href={el.href}>
                {el.name}
            </Link>
        })}
        <Typography sx={{
            color: (theme) => theme.palette.text.main,
            textDecoration: "none",
        }}>{page}</Typography>
    </Breadcrumbs>
}