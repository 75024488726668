import {Button} from "@mui/material";

const ButtonAdd = ({
                       onClick,
                       text,
                       width = 117,
                       minWidth = width,
                       height = 56,
                       minHeight = height,
                       disabled = false
                   }) => {
    return <Button
        onClick={onClick}
        variant="contained"
        disabled={disabled}
        sx={{
            width: width,
            height: height,
            backgroundColor: (theme) => theme.palette.secondary.add,
            borderRadius: "100px",
            boxShadow: "none",
            textTransform: 'none',
            '@media (max-width: 1450px)': {
                width: minWidth,
                height: minHeight,
            },
            "&:hover": {
                backgroundColor: (theme) => theme.palette.secondary.add,
                boxShadow: "none",
            }
        }}>
        {text}
    </Button>
}
export default ButtonAdd