import React, {useEffect, useState} from 'react';
import {Button, Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {createPointArray} from "../../../../../../utils/processing/createPointArray";
import {noExponents} from "../../../../../../utils/processing/noExponents";
import {calcValueOfStringWithVar} from "../../Canvas/CanvasCalcPositions/calcValueOfStringWithVar";
import {hasOnlyDigitsAndSymbols} from "../../../../../../utils/processing/hasOnlyDigits";
import ButtonExit from "../../../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../../../components/UI/button/ButtonAdd";

const PointModal = ({
                               type,
                               onClose,
                               onSubmit,
                               detailData,
                               activePointId,
                               setActivePointId,
                               variables,
                           }) => {

    const pointArray = createPointArray(detailData)

    const initData = pointArray.find(el => el.id === activePointId)

    const initFormState = type === "editPoint" ? {
        "x": initData.variables.find(el => el.name === "x")?.pattern || +parseFloat(noExponents(initData.x)).toFixed(2),
        "y": initData.variables.find(el => el.name === "y")?.pattern || +parseFloat(noExponents(initData.y)).toFixed(2),
        "afterPointId": activePointId
    } : {
        "x": "", "y": "", "afterPointId": pointArray[0].id
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "x": "", "y": "", "afterPointId": "",
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}

        const calcValues = Object.fromEntries(Object.entries(formState).map(([name, value]) => {
            return name === "afterPointId" || !hasOnlyDigitsAndSymbols(value) ? [name, value] : [name, calcValueOfStringWithVar(variables, value.toString())]
        }))

        Object.entries(calcValues).forEach(([name, value]) => {
            if (name === "x" && (value < 0 || value > detailData.length)) {
                newErrors.x = `от 0 до ${detailData.length}`
            } else if (name === "y" && (value < 0 || value > detailData.width)) {
                newErrors.y = `от 0 до ${detailData.width}`
            }
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                afterPointId: formState.afterPointId,
                point: {
                    "x": formState.x,
                    "y": formState.y,
                }
            })
        }
    }
    const inputHandler = (getData) => {
        const data = getData()
        const currState = {...formState, ...data}
        setFormState(currState)

        data.pointId && setActivePointId(data.pointId)
    }


    return (<>
        <DialogTitle textAlign="center">{type === "editPoint" ? "Изменить узел" : "Добавить узел"}</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                {type === "addPoint" && <TextFieldValidation
                    select
                    name="afterPointId"
                    label="Предыдущий узел"
                    value={formState.afterPointId}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {pointArray.map((option, i, arr) => (
                        <MenuItem key={option.id} value={option.id}>
                            x= {+option.x.toFixed(2)}, y= {+option.y.toFixed(2)}
                        </MenuItem>))}
                </TextFieldValidation>}
                <TextFieldValidation
                    name={"x"}
                    value={formState?.x}
                    label="x"
                    setCurrValues={inputHandler}
                    validationError={validationState?.x}
                    setValidationState={setValidationState}
                    adornment={type === "editPoint" && +parseFloat(noExponents(initData.x)).toFixed(2)}/>
                <TextFieldValidation
                    name={"y"}
                    value={formState?.y}
                    label="y"
                    setCurrValues={inputHandler}
                    validationError={validationState?.y}
                    setValidationState={setValidationState}
                    adornment={type === "editPoint" && +parseFloat(noExponents(initData.y)).toFixed(2)}
                />
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Отменить"} onClick={onClose}/>
            <ButtonAdd text={"Выбрать"}  onClick={handleValidationData}/>
        </DialogActions>
    </>)
}
export default PointModal
