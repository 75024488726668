import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextFieldValidation from "../TextFieldValidation";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";


const ModalAddVariable = ({initValue, open, onClose, title, handleSubmit, labelButtonSubmit}) => {
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState(initValue)

    useEffect(() => {
        setFormState(initValue)
        setValidationState(Object.keys(initValue).reduce((acc, it) => ({...acc, [it]: ""}), {}))
    }, [initValue])

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {

            if (name !== "comment" && value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{minWidth: 300}}>
                <TextFieldValidation
                    name={"name"}
                    label={"Наименование"}
                    value={formState?.name}
                    setCurrValues={setFormState}
                    validationError={validationState?.name}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"pattern"}
                    label={"Значение"}
                    value={formState?.pattern}
                    setCurrValues={setFormState}
                    validationError={validationState?.pattern}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"comment"}
                    label={"Комментарий"}
                    value={formState?.comment}
                    setCurrValues={setFormState}
                    validationError={validationState?.comment}
                    setValidationState={setValidationState}
                    required={false}
                />
            </DialogContent>

            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Выйти"} onClick={onClose}/>
                <ButtonAdd text={labelButtonSubmit} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default ModalAddVariable
