import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {styled} from "@mui/system";
import {InputBase, Typography} from "@mui/material";


export const SearchComponent = ({placeholder, buttonText}) => {
    const StyledInputBase = styled(InputBase)(({theme}) => ({
        color: (theme) => theme.palette.text.light,
        width: buttonText ? "228px" : "296px",
        height: "100%",
        backgroundColor: "white",
        borderRadius: "6px",
        border: `${theme.palette.primary.main} 2px solid`,
        // '@media (max-width: 1380px)': {
        //     width: 163,
        // },
        '& .MuiInputBase-input': {
            padding: "0 0 0 15px",
            fontSize: "16px"
        },
    }));
    const Search = styled('div')(({theme}) => ({
        position: 'relative',
        borderRadius: "6px",

        backgroundColor: theme.palette.primary.main,
        width: 352,
        height: 40,
        // '@media (max-width: 1380px)': {
        //     width: 219,
        // },
    }));

    const SearchIconWrapper = styled('div')(({theme}) => ({
        height: '100%',
        position: 'absolute',
        right: 0,
        width: buttonText ? "124px" : "56px",
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& .MuiSvgIcon-root": {
            width: 25
        }
    }));
    return <Search>
        <SearchIconWrapper>
            {buttonText ? <Typography component="p" sx={{fontSize: "14px", color: "white", fontWeight: 700}}>
                {buttonText}
            </Typography> : <SearchIcon/>}
        </SearchIconWrapper>
        <StyledInputBase
            placeholder={placeholder}
            inputProps={{'aria-label': 'search'}}
        />
    </Search>
}