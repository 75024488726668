import React, {useState} from 'react';
import {Box, Button, Tabs, Tooltip} from "@mui/material";
import RequestsCuttingTable from "../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import UploadFilesRequestsForm from "../../organization/orders/estimate/UploadFilesRequestsForm";
import CheckIcon from "@mui/icons-material/Check";
import {ButtonGroupTabBar} from "../../../components/UI/button/ButtonGroupTabBar";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FileService from "../../../API/orders/orders/customer/FileService";

const CustomerRequestsCuttingEstimateItem = ({
                                                 getAll,
                                                 isOptimization,
                                                 isRedirectRequestsCutting,
                                                 orderId,
                                                 nameOrder,
                                                 idHeader,
                                                 year,
                                                 isOrder,
                                                 isButtonAddRequestsCutting,
                                                 isEstimate,
                                                 isCreateOptimizationCalculation,
                                                 isSetForOptimization,
                                                 customerOrderNumber
                                             }) => {

    const [tab, setTab] = useState(1);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const [change, setChange] = useState(false);

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
            <ButtonGroupTabBar activeTab={tab}>
                <Button tabIndex={1} onClick={(e) => handleChange(e, 1)}>
                    <CheckIcon/>
                    Заявки на раскрой
                </Button>
                <Button tabIndex={2} onClick={(e) => handleChange(e, 2)}>
                    <CheckIcon/>
                    Импорт
                </Button>
            </ButtonGroupTabBar>
            {tab === 1 ? <RequestsCuttingTable
                getAll={getAll}
                isOptimization={isOptimization}
                isRedirectRequestsCutting={isRedirectRequestsCutting}
                orderId={orderId}
                nameOrder={nameOrder}
                idHeader={idHeader}
                year={year}
                isOrder={isOrder}
                isButtonAddRequestsCutting={isButtonAddRequestsCutting}
                isEstimate={isEstimate}
                isCreateOptimizationCalculation={isCreateOptimizationCalculation}
                isSetForOptimization={isSetForOptimization}
                hideOrder={true}
                hideWorkingCuttingMap={true}
                customer={true}
                customerOrderNumber={customerOrderNumber}
            /> : <Box> <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                '& > *': {
                    marginRight: '10px',
                }
            }}>
                <UploadFilesRequestsForm
                    orderId={orderId}
                    change={change}
                    setChange={setChange}
                    calculationRequestsCutting={false}
                    upload={FileService.uploadRequestsCuttingFiles}
                />
                <Tooltip sx={{height: "36px"}} arrow placement="top"
                         title="Импорт заявок на раскрой из файлов раскроя базис-мебельщика, с добовлением материала в смету">
                    <HelpOutlineIcon/>
                </Tooltip>
            </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    '& > *': {
                        marginRight: '10px',
                    }
                }}>
                    <UploadFilesRequestsForm
                        orderId={orderId}
                        change={change}
                        setChange={setChange}
                        calculationRequestsCutting={false}
                        upload={FileService.uploadRequestsCuttingFilesWithSample}
                    />
                    <Tooltip sx={{height: "36px"}} arrow placement="top"
                             title="Импорт заявок на раскрой из файлов раскроя базис-мебельщика, с добовлением материала в смету,
                        с использованием шаблонов *.xls">
                        <HelpOutlineIcon/>
                    </Tooltip>
                </Box></Box>}

        </Box>
    );
};

export default CustomerRequestsCuttingEstimateItem;