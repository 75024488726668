import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider, Tabs} from "@mui/material";
import FilesTable from "../../../../components/UI/table/common/files/FilesTable";
import FileService from "../../../../API/orders/orders/organization/FileService";
import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import RequestsCuttingEstimateItem from "../estimate/RequestsCuttingEstimateItem";
import TechnicalMapTable from "../../../../components/UI/table/orders/organization/technicalmaptable/TechnicalMapTable";
import JobService from "../../../../API/orders/job/JobService";
import ModalConfirm from "../../../../components/UI/modal/ModalConfirm";
import EstimateService from "../../../../API/orders/estimate/organization/EstimateService";
import MaterialEstimateUnitService from "../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import WarehouseMaterialSettingsService from "../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import EstimateTable from "../../../../components/UI/table/orders/organization/estimate/EstimateTable";
import {estimateStatuses} from "../../../../data/EstimateState";
import UploadOrderEstimateFileForm from "../../../../components/form/UploadOrderEstimateFileForm";
import {
    UpdateOrAddBasicModelsModule
} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddBasicModelsModule";
import {UpdateOrAddModelsModule} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddModelsModule";

const OrderItem = () => {

        const params = useParams();
        const navigate = useNavigate();

        const [value, setValue] = useState('4');
        const year = params.year;
        const nameOrder = params.number;
        const orderId = params.id;
        const orderIdHeader = params.idHeader;

        const [path, setPath] = useState(null);
        const [change, setChange] = useState(null);

        const [technicalMapData, setTechnicalMapData] = useState([]);
        const [inWorkModalOpen, setInWorkModalOpen] = useState(false);
        const [modalBasicModelsOpen, setBasicModalModelsOpen] = useState(false);
        const [modalModelsOpen, setModalModelsOpen] = useState(false);
        const [basicModelId, setBasicModelId] = useState(null);

        useEffect(() => {
            JobService.getJobsByOrderId(orderId).then(function (response) {
                setTechnicalMapData(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const redirectOrders = () => {
            navigate('/organization/orders/');
        };

        const redirectSetAttribute = () => {
            navigate('/organization/orders/set-attribute/' + orderId + '/' + nameOrder + '/' + orderIdHeader + '/' + year);
        };

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const handleSubmitSetInWork = async () => {
            EstimateService.setInWork(orderId)
                .then(function () {
                    handleCloseSetPurchase()
                    /*         setCurrentInWork(false);*/
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

        const handleCloseSetPurchase = () => {
            setInWorkModalOpen(false);
        };

        const [materialEstimateUnits, setMaterialEstimateUnits] = useState([]);
        useEffect(() => {
            MaterialEstimateUnitService.getAllByOrderId(orderId).then(function (response) {
                const data = response.data;
                setMaterialEstimateUnits(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        useEffect(() => {

            WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
                let data = response.data;
                setBaseCurrency(data.baseCurrency.curAbbreviation);
                setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);


        const [baseCurrency, setBaseCurrency] = useState("");
        const [alternativeCurrency, setAlternativeCurrency] = useState("");

        const [data, setData] = useState([]);
        useEffect(() => {
            EstimateService.getAllByOrderId(orderId).then(function (response) {
                let data = response.data;
                setData([data])
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const openBasicModels = () => {
            setBasicModalModelsOpen(true);
        };

        const closeModels = () => {
            setModalModelsOpen(false);
            setBasicModelId(null);
        };

        const closeBasicModels = () => {
            setBasicModalModelsOpen(false);
        };

        return (
            <div>

                <Typography variant="h6" component="h6">
                    Заказ: {estimateStatuses[nameOrder]}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        paddingLeft: '0.5rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.2rem',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        size="small"
                        color="secondary"
                        onClick={redirectOrders}
                        variant="contained"
                    >
                        Вернуться в заказы
                    </Button>
                    <Button
                        size="small"
                        color="success"
                        onClick={redirectSetAttribute}
                        variant="contained"
                    >
                        Добавить атрибут
                    </Button>
                    {<Button
                        size="small"
                        color="success"
                        onClick={() => setInWorkModalOpen(true)}
                        variant="contained"
                        /*disabled={!currentInWork}*/
                    >
                        Отправить заказ в работу
                    </Button>}
                </Box>
                <TabContext value={value}>
                    <Box sx={{
                        borderBottom: 1, borderColor: 'divider'
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Файлы" value='1'/>
                            <Tab label="Заявки на раскрой" value='3'/>
                            <Tab label="Модули" value='2'/>
                            <Tab label="Смета" value='4'/>
                            <Tab label="Тех карта" value='5'/>
                        </Tabs>
                    </Box>
                    <TabPanel value='1'>
                        <Box
                            sx={{display: ' block', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                            <Divider/>
                            <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'проекта'}
                                label={'Загрузить файлы проекта'}
                                getAllFile={FileService.getAllProjectFileNamesByOrderId}
                                fileNameType={'PROJECT'}
                            />
                            <FilesTable
                                nameOrder={nameOrder}
                                year={year}
                                orderId={orderId}
                                change={change}
                                setChange={setChange}
                                title={'замера'}
                                label={'Загрузить файлы замера'}
                                getAllFile={FileService.getAllMeasuringFileNamesByOrderId}
                                fileNameType={'MEASURING'}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='3'>
                        <RequestsCuttingEstimateItem
                            getAll={RequestsCuttingService.getAllByOrderIdWithMaterial}
                            isOptimization={true}
                            isRedirectRequestsCutting={true}
                            orderId={orderId}
                            nameOrder={nameOrder}
                            idHeader={orderIdHeader}
                            year={year}
                            isOrder={true}
                            isButtonAddRequestsCutting={true}
                            isEstimate={true}
                            isCreateOptimizationCalculation={true}
                            isSetForOptimization={true}
                            isRedirectOptimizations={true}
                            isRedirectCalculationOptimizations={true}
                            isRedirectCalculationCalculationWorkingCuttingMaps={true}
                            isRedirectCalculationRequestsCuttings={true}
                            customer={false}
                        />

                    </TabPanel>
                    <TabPanel value='4'>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            '& > *': {
                                marginRight: '10px',
                            }
                        }}>
                            <Typography variant="h6" component="h6">
                                Импорт сметы из файла сметы базис-мебельщика *.xls
                            </Typography>
                            <UploadOrderEstimateFileForm
                                year={year}
                                orderId={orderId}
                                label={'Загрузить смету проекта'}
                                change={change}
                                setChange={setChange}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            '& > *': {
                                marginRight: '10px',
                            }
                        }}>
                            <Typography variant="h6" component="h6">
                                Создание сметы на основе моделей (с заявками на раскрой, необходимо наличие модуля
                                облако-моделей)
                            </Typography>
                            <Button
                                size="small"
                                color="success"
                                onClick={openBasicModels}
                                variant="contained"
                            >
                                Добавить модель
                            </Button>
                        </Box>
                        <EstimateTable
                            data={data}
                            setData={setData}
                            orderId={orderId}
                            orderNumber={nameOrder}
                            orderHeaderId={orderIdHeader}
                            year={year}
                            enableEditing={true}
                        />
                    </TabPanel>
                    <TabPanel value='6'>


                    </TabPanel>
                    <TabPanel value='5'>
                        <h3>Техническая карта заказа: {params.order}</h3>
                        <TechnicalMapTable
                            data={technicalMapData}
                            setData={setTechnicalMapData}
                            estimateIsCurrent={true}
                        />
                    </TabPanel>
                    <TabPanel value='6'>
                        <h3>Техническая карта заказа: {params.order}</h3>
                        {/*     <ModelsTable
                            data={dataModel}
                            setData={setDataModel}
                            isButtonDelete={true}
                            isButtonBasicModels={true}
                            isButtonCreateModel={false}
                            isButtonSettings={false}
                            isButtonUpdate={true}
                            isColumnUnable={false}
                            isRedirecrt={true}
                            isBasicModel={false}
                            enableRowSelection={false}
                            disableEditing={true}
                        />*/}
                    </TabPanel>
                </TabContext>
                <ModalConfirm
                    title={"Подтвердить заказ в работу?"}
                    modalOpen={inWorkModalOpen}
                    handleClose={handleCloseSetPurchase}
                    handleSubmit={handleSubmitSetInWork}
                    action={"Подтвердить"}
                />
                <UpdateOrAddBasicModelsModule
                    open={modalBasicModelsOpen}
                    setBasicModelId={setBasicModelId}
                    setModalModelsOpen={setModalModelsOpen}
                    onClose={closeBasicModels}
                    orderIdHeader={orderIdHeader}
                />
                <UpdateOrAddModelsModule
                    open={modalModelsOpen}
                    setModelsOpen={setModalModelsOpen}
                    setBasicModalModelsOpen={setBasicModalModelsOpen}
                    onClose={closeModels}
                    basicModelId={basicModelId}
                    orderId={orderId}
                    change={change}
                    setChange={setChange}
                />
            </div>
        );

    }
;

export default OrderItem;