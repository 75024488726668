import React, {useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {noExponents} from "../../../../../../utils/processing/noExponents";
import {SIDE} from "../../../../../../Constants";
import MenuItem from "@mui/material/MenuItem";
import ButtonExit from "../../../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../../../components/UI/button/ButtonAdd";

const NotchContour = ({
                          onClose,
                          onSubmit,
                          detailData,
                          activePointId,
                          openConfirmModal
                      }) => {
    const initData = detailData.notches.find(contour => contour.id === activePointId)

    const [formState, setFormState] = useState(
        {
            "position": initData.contour.position,
            "depth": initData.variables.find(el => el.name === "DEPTH")?.pattern || +parseFloat(noExponents(initData.depth)).toFixed(2)
        })
    const [validationState, setValidationState] = useState({"depth": ""})

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({"position": formState.position, "depth": formState.depth}
            )
        }
    }

    return (<>
        <DialogTitle textAlign="center">Изменить контур</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                <TextFieldValidation
                    select
                    name="position"
                    label="Позиция"
                    value={formState.position}
                    setCurrValues={setFormState}
                    setValidationState={setValidationState}
                >
                    {[SIDE[0], SIDE[1]].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextFieldValidation>
                <TextFieldValidation
                    name={"depth"}
                    value={formState?.depth}
                    label="Глубина"
                    setCurrValues={setFormState}
                    validationError={validationState?.depth}
                    setValidationState={setValidationState}
                    adornment={+parseFloat(noExponents(initData.depth)).toFixed(2)}
                />
                <Button
                    size="small"
                    color="secondary"
                    onClick={() => openConfirmModal("deleteNotch", initData.id)}
                    variant="outlined"
                >
                    Удалить контур
                </Button>
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Отменить"} onClick={onClose}/>
            <ButtonAdd text={"Выбрать"}  onClick={handleValidationData}/>
        </DialogActions>
    </>)
}
export default NotchContour