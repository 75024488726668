import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";

import {Button, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {Delete, Edit} from "@mui/icons-material";
import MaterialPriceUnitService from "../../../../../API/orders/warehouse/MaterialPriceUnitService";
import ModalConfirm from "../../../modal/ModalConfirm";
import WarehouseMaterialSettingsService from "../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import {UploadSignJobFileForm} from "../productioncosts/UploadSignJobFileForm";

const JobMetricOrganizationTable = ({
                                        data,
                                        setData,
                                        change,
                                        setChange,
                                        enableEditing,
                                        uploadSignJob,
                                        isRedirectSettings,
                                        isChooseSignJob,
                                        handleSubmitAddSign,
                                        rowSelection,
                                        setRowSelection,
                                        rowIndexSelection,
                                        setRowIndexSelection,
                                        selectedIds,
                                        setSelectedIds,
                                        isPrice,
                                        isUnit
                                    }) => {

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [showConfirmAddSignJob, setShowConfirmAddSignJob] = useState(false);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");

    useEffect(() => {
        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const navigate = useNavigate();

    const redirectSettings = () => {
        navigate('/settings-orders');
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.index);
            /* setUpdateModalOpen(true);*/
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            const materialId = row.original.id
            MaterialPriceUnitService.delete(id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                    /* materialPriceUnits.delete(materialId);*/
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleCloseShowConfirmAddSignJob = () => {
        setShowConfirmAddSignJob(false)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: "metricName",
                header: 'Найменование операции',
                accessorKey: 'metricName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,

            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        if (isUnit) {
            baseColumns.splice(2, 0, {
                header: 'Ед.изм.',
                accessorKey: 'unit.name',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            });
        }

        if (isPrice) {
            baseColumns.splice(4, 0, {
                header: `Стоимость ${baseCurrency}`,
                accessorKey: 'basePrice.price',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
            });
        }

        if (isPrice) {
            baseColumns.splice(4, 0, {
                header: `Стоимость ${alternativeCurrency}`,
                accessorKey: 'alternativePrice.price',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </>
                ),
            });
        }

        return baseColumns;

    }, [data, setData, isPrice, isUnit]);


    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    expanded: false,
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                    sorting: [{id: 'signJob.name', desc: false}],
                }}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection
                getRowId={(originalRow) => originalRow.id}
                localization={MRT_Localization_RU}
                enableEditing={enableEditing}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.01rem',
                        justifyContent: 'flex-start',
                        maxWidth: '25px'
                    }}>
                        <Tooltip arrow placement="left" title="Редактировать">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => handleUpdateModel(row)}>
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Удалить">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={({table}) => {
                    const chooseSignJob = () => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        const selectedIndexRows = selectedRows.map((row) => row.index);
                        setSelectedIds(selectedIds);
                        setRowIndexSelection(selectedIndexRows);
                        setShowConfirmAddSignJob(true)
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {isRedirectSettings && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectSettings}
                                    variant="contained"
                                >
                                    Вернуться в настройки
                                </Button>}
                                {isChooseSignJob && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={chooseSignJob}
                                    variant="contained"
                                    disabled={table.getSelectedRowModel().rows.length === 0}
                                >
                                    Установить
                                </Button>}
                                {uploadSignJob && <UploadSignJobFileForm
                                    label={'Загрузить операции'}
                                    change={change}
                                    setChange={setChange}
                                />}
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalConfirm
                title={"Вы действительно хотите добавить работы?"}
                modalOpen={showConfirmAddSignJob}
                handleClose={handleCloseShowConfirmAddSignJob}
                handleSubmit={handleSubmitAddSign}
                action={'Установить'}
            />
        </div>
    );
};

export default JobMetricOrganizationTable;