import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const requestsCuttingServiceRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/customers/requests-cutting'

export default class CustomerRequestsCuttingService {

    static async getAllByCustomerOrderId(orderId) {
        return axios.get(requestsCuttingServiceRestUrl + '/by-order-id?orderId=' + orderId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async add(data) {
        return axios.post(requestsCuttingServiceRestUrl, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async edit(id, data) {
        return axios.put(requestsCuttingServiceRestUrl + "/" + id, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        return axios.delete(requestsCuttingServiceRestUrl + "/" + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

}