export const modelParameterUnit = {
    "CUTTING_LENGTH": "Длина резов",
    "AREA_DETAILS": "Габаритная площадь готовой детали",
    "OPTIMIZATION_AREA_DETAILS": "Габаритная общая площадь готовых деталей",
    "NUMBER_TURNS_PANEL": "Количество поворотов панелей",
    "NUMBER_SIZE_SETTINGS": "Количество установок размеров",
    "LENGTH_OF_STRAIGHT_CLADDING": "Длина прямолинейной облицовки",
    "LENGTH_OF_CURVED_CLADDING": "Длина криволинейной облицовки",
    "LENGTH_OF_CURVED_HAND_CLADDING": "Длина криволинейной ручной облицовки",
    "TOTAL_LENGTH_OF_CLADDING": "Общая длина облицовки",
    "LENGTH_OF_PANEL_MILLED_CUTS": "Длина фрезерованных резов панели",
    "STRAIGHT_GROOVE_LENGTH": "Длина прямолинейного паза",
    "NUMBER_OF_HOLES": "Количество отверстий",
    "NUMBER_OF_END_HOLES": "Количество торцевых отверстий",
    "OVERALL_AREA_OF_THE_FINISHED_PART": "Габаритная площадь готовой детали",
    "OVERALL_PANEL_AREA_WITHOUT_CLADDING": "Габаритная площадь панели без облицовки",
    "WORKPIECE_LENGTH": "Периметр заготовки",
    "NUMBER_OF_RECESSES": "Количество выемок",
    "NUMBER_OF_PARTS_WITHOUT_HOLES_FOR_NON_RECTANGULAR_WITH_INTERNAL_CONTOUR": "Количество ДЕТАЛЕЙ без отверстий для непрямоугольных С внутренним контуром",
    "NUMBER_OF_PARTS_WITH_HOLES_FOR_RECTANGULAR_ONES_WITHOUT_INTERNAL_MILLING": "Количество ДЕТАЛЕЙ с отверстиями для прямоугольных БЕЗ внутренней фрезеровки",
    "NUMBER_OF_PARTS_WITH_HOLES_FOR_RECTANGULAR_WITH_INTERNAL_MILLING_AND_NON_RECTANGULAR": "Количество ДЕТАЛЕЙ с отверстиями для прямоугольных С внутренней фрезеровкой и непрямоугольных",
    "NUMBER_OF_HOLES_FOR_RECTANGULAR_WITH_INTERNAL_MILLING_AND_NON_RECTANGULAR": "Количество ОТВЕРСТИЙ  для прямоугольных С внутренней фрезеровкой и непрямоугольных",
    "NUMBER_OF_HOLES_FOR_RECTANGULAR_WITHOUT_INTERNAL_MILLING": "Количество ОТВЕРСТИЙ для прямоугольных БЕЗ внутренней фрезеровки",
    "NUMBER_OF_MATERIAL_PANELS": "Количество панелей материала",
    "FINISHED_PART_PERIMETER": "Периметр готовой детали",
    "FINISHED_PART_CONTOUR_PERIMETER": "Периметр контура готовой детали",
    "ANY": "Не определено"
};