import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const workerRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/workers'

export default class WorkerService {

    static async getAll() {
        return axios.get(workerRestUrl);
    }

    static async getAllEnabledAndConstructor() {
        return axios.get(workerRestUrl + "/enabled-and-constructor");
    }

    static async getAllByJobId(id) {
        return axios.get(workerRestUrl + '/jobs/' + +id);
    }

    static async update(data) {
        await axios.post(workerRestUrl + '/' + +data.id, JSON.stringify(data));
    }

    static async delete(id) {
        await axios.delete(workerRestUrl + '/' + id);
    }

    static async unable(id, status) {
        return axios.patch(workerRestUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async unableProduction(id, status) {
        return axios.patch(workerRestUrl + '/' + id + '/unable-production?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async unableConstructor(id, status) {
        return axios.patch(workerRestUrl + '/' + id + '/unable-constructor?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(workerRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}