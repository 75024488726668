import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Stack, Tooltip} from "@mui/material";
import {Cached, Delete} from "@mui/icons-material";
import MaterialEstimateUnitService
    from "../../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";

const MaterialCostTable = ({
                               materialEstimateUnits,
                               setMaterialEstimateUnits,
                               baseCurrency,
                               alternativeCurrency,
                               orderId, orderNumber, orderHeaderId, year, isButtonPurchase,
                               isActual, enableEditing, inWork
                           }) => {

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
    const [rowSelection, setRowSelection] = useState({});

    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
    const [change, setChange] = useState(false);
    const [currentInWork, setCurrentInWork] = useState(inWork);

    const [searchSimilarMaterials, setSearchSimilarMaterials] = useState(false);

    const sumAmountBasePrice = useMemo(
        () => materialEstimateUnits.reduce((acc, mEU) => {
            if (mEU.basePrice !== null) {
                return acc + mEU.basePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [materialEstimateUnits]
    );

    const sumAmountAlternativePrice = useMemo(
        () => materialEstimateUnits.reduce((acc, mEU) => {
            if (mEU.alternativePrice !== null) {
                return acc + mEU.alternativePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [materialEstimateUnits]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            const materialId = row.original.id
            MaterialEstimateUnitService.delete(id)
                .then(function () {
                    materialEstimateUnits.splice(row.index, 1);
                    setMaterialEstimateUnits([...materialEstimateUnits]);
                    materialPriceUnits.delete(materialId);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [materialEstimateUnits],
    );

    /*    const handleSubmitSetPurchase = async () => {
            EstimateService.setPurchase(estimateId)
                .then(function () {
                    handleCloseSetPurchase()
                    setCurrentInWork(false);
                })
                .catch(function (error) {
                    console.log(error);
                })
        }*/

    const handleCloseSetPurchase = () => {
        setSetPurchaseModalOpen(false);
    };

    const openSearchSimilarMaterials = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setSearchSimilarMaterials(true)
        },
        [],
    );

    const materialReplacement = () => {
        setSetPurchaseModalOpen(false);
    };

    const selectInMaterialOOId = async (row) => {
        const id = currentEditRowData.id
        MaterialEstimateUnitService.replacementByMaterialOrganizationOptionId(id, row.original.id)
            .then(function () {
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const selectInMaterialId = async (row) => {
        const id = currentEditRowData.id
        MaterialEstimateUnitService.replacementByMaterialId(id, row.original.id)
            .then(function () {
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.articleNumber : '';
                }
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                            </span>
                    );
                }
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.unit.name : row.original.tempUnit.name;
                }
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return (row.original.materialOrganizationOption === null) ?
                        '' : row.original.materialOrganizationOption.typeMaterial === 'SLAB' ?
                            (row.original.optimizationQuantity === null || row.original.optimizationQuantity === undefined
                                ? 'Ожидание' : row.original.optimizationQuantity.toFixed(3)) : value.toFixed(3);
                },
            },
            {
                id: 'basePrice',
                header: `Стоимость ${baseCurrency}`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativePrice',
                header: `Стоимость ${alternativeCurrency}`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [materialEstimateUnits, setMaterialEstimateUnits],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        /*   "&:first-of-type": {
                               padding: 0,
                               width: "100%",
                               height: "36px",
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                               color: (theme) => theme.palette.text.light,
                               "& .MuiButtonBase-root": {
                                   borderRadius: "4px",
                                   width: "24px",
                                   height: "24px",
                                   opacity: 1,
                                   margin: 0,
                                   padding: 0,
                                   "&:hover": {
                                       backgroundColor: (theme) => theme.palette.primary.stroke,
                                   }
                               }
                           },*/
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light,
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light
                                },
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={materialEstimateUnits}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                        calculationAmountAlternativePrice: false

                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                enableEditing={false}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!row.original.isBlocked ?
                            <Tooltip arrow placement="left" title="Заменить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => openSearchSimilarMaterials(row)}>
                                    <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                                 onClick={() => openSearchSimilarMaterials(row)}>
                                <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        }
                        {!row.original.isBlocked ? <Tooltip arrow placement="right" title="Удалить">
                            <Button
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip> : <Button
                            size="small"
                            sx={{minWidth: 'auto', marginLeft: 'auto'}}
                            color="error"
                            onClick={() => handleDeleteRow(row)}
                            disabled={true}
                        >
                            <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>}
                    </Box>
                )}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const setCurrency = (typeCurrentCurrency) => {
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                            </Box>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                {/*{isButtonPurchase === 'true' && <Button
                                    size="small"
                                    color="success"
                                    onClick={() => setSetPurchaseModalOpen(true)}
                                    variant="contained"
                                    disabled={!currentInWork}
                                >
                                    Подать заявку на закупку
                                </Button>}*/}
                            </Box>
                        </div>
                    );
                }}
            />
            {/*    <ModalConfirm
                title={"Подтвердить создание заявки на закупку?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />*/}
        </div>
    );
};

export default MaterialCostTable;

