import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AddCircle} from "@mui/icons-material";
import Box from "@mui/material/Box";
import UserService from "../../../../../API/common/UserService";

const AllRolesTable = ({id, change, setChange}) => {

        const [data, setData] = useState([]);
        const [isFullScreen, setIsFullScreen] = useState(false);
        useEffect(() => {

            UserService.getAllRoles().then(function (response) {
                setData(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const addRole = useCallback(
            (row) => {
                UserService.addRole(id, row.original)
                    .then(function () {
                        setChange(!change);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const columns = useMemo(
            () => [
                {
                    id: 'name',
                    header: 'Название роли',
                    accessorKey: 'name',
                    minSize: 25,
                },

                {
                    id: 'description',
                    header: 'Описание роли',
                    accessorKey: 'description',
                    minSize: 300,
                },
                {
                    id: 'id',
                    header: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ],
            [data, setData],
        );

        return (
            <>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 0px',
                            m: '0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={data}
                    editingMode="modal"
                    enableEditing
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip arrow placement="right" title="Добавить роль пользователю">
                                <IconButton color="success" onClick={() => addRole(row)}>
                                    <AddCircle/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 20},
                        columnPinning: {left: ['number']}
                    }}
                    localization={MRT_Localization_RU}
                />
            </>
        );
    }
;

export default AllRolesTable;