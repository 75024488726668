import React from "react";
import Layer from "./Layer";
import * as THREE from "three";
import LineElement from "./LineElement";

const Hole = ({data, hoveredHole, hoveredSideHole, setSelectedHole, setHoveredHole, processingHandle, scale}) => {
    const isHovered = hoveredHole?.id === data.id || hoveredSideHole?.id === data.id
    const onclickHoleHandle = () => {
        processingHandle("editHole", data.id)
        setSelectedHole(data.id)
    }
    return (
        <Layer layer={2}>

            <mesh
                onClick={(event) => (event.stopPropagation(), onclickHoleHandle())}
                onPointerOver={(event) => (event.stopPropagation(), setHoveredHole(`${data.id}${data.name}`))}
                onPointerOut={(event) => (event.stopPropagation(), setHoveredHole(null))}
                position={[data.x, data.y, data.z]}>

                {isHovered && <circleGeometry args={[hoveredHole ? data.radius + 10 : data.radius + 0.1]}/>}
                <meshBasicMaterial
                    toneMapped={false}
                    color={"#D7E8FA"}/>
                <mesh>
                    <circleGeometry args={[data.radius]}/>
                    <meshBasicMaterial color={data.color} toneMapped={false}/>
                </mesh>
            </mesh>
            {data.border !== "none" &&
                <mesh position={[data.x * scale * scale, data.y * scale * scale, data.z]}><LineElement
                    width={4}
                    points={new THREE.EllipseCurve(data.x, data.y, data.radius, data.radius, 2 * Math.PI, false, 0).getPoints(1000)}
                    color={"black"}
                    dashSize={data.border === "dash" ? 0.05 / scale : 0}
                    gapSize={data.border === "dash" ? 0.05 / scale : 0}
                /></mesh>}

        </Layer>
    )
}
export default Hole
