import React, {useCallback, useEffect, useMemo, useState} from 'react';
import FileService from "../../../../../API/orders/orders/organization/FileService";
import {Divider, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {Delete, Edit, FileUploadSharp} from "@mui/icons-material";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import UploadOrderFilesForm from "../../../../form/UploadOrderFilesForm";
import {MaterialReactTable} from "material-react-table";

const FilesTable = ({getAllFile, label, nameOrder, year, orderId, change, setChange, title, fileNameType}) => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        getAllFile(orderId).then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const downloadFile = useCallback(
        (row) => {
            FileService.downloadFile(row.original.id)
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', row.original.nameFile);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [],
    );

    const handleDeleteRow = useCallback(
        (row) => {
            FileService.deleteFileById(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        FileService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };

    const columns = useMemo(
        () => [
            {
                id: 'nameFile',
                header: 'найменование файла',
                accessorKey: 'nameFile',
                minSize: 150,
            },
            {
                id: 'description',
                header: 'Описание',
                accessorKey: 'description',
                minSize: 25,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <Divider/>
            <Typography variant="h6" component="h6">
                Файлы {title}
            </Typography>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="left" title="Загрузить файл">
                            <IconButton color="success" onClick={() => downloadFile(row)}>
                                <FileUploadSharp/>
                            </IconButton>
                        </Tooltip>
                        {/*  <Tooltip arrow placement="left" title="Редактировать">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>*/}
                        <Tooltip arrow placement="right" title="Удалить  файл">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    sorting: [{id: 'nameFile', desc: false}],
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <UploadOrderFilesForm
                            label={label}
                            year={year}
                            nameOrder={nameOrder}
                            orderId={orderId}
                            change={change}
                            setChange={setChange}
                            fileNameType={fileNameType}
                        />
                    </Box>
                )}
            />
        </>
    );
};
export default FilesTable;