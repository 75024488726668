import React, {useEffect, useState} from 'react';
import {Box, Button, Tooltip} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import DetailControllerService from "../../../API/details/DetailControllerService";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../Constants";
import {useParams} from "react-router-dom";
import CustomerDetailsTable from "../../../components/UI/table/customer-detales/CustomerDetailsTable";
import {ButtonGroupTabBar} from "../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Typography from "@mui/material/Typography";
import UploadFilesRequestsForm from "../../organization/orders/estimate/UploadFilesRequestsForm";
import FileService from "../../../API/orders/orders/customer/FileService";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const CustomerRequestsCutting = () => {

    const params = useParams();
    const {orderId, customerOrderNumber, requestsCuttingId, requestsCuttingNumber} = params

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'

    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({unableTurn: {}, unablePackage: {}});
    const [tab, setTab] = useState(1);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const [change, setChange] = useState(false);


    const handleError = (error) => {
        setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response.status] || error.message
        })
    }
    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }

    useEffect(() => {
        setIsLoading(true)
        DetailControllerService.getAll(orderId, requestsCuttingId)
            .then(function (response) {
                const data = response?.data
                setData(data);
                setUnable(({unableTurn, unablePackage}) => {
                    data.forEach(row => {
                        unableTurn[row.id] = row.turn;
                        unablePackage[row.id] = row.package;
                    })
                    return {unableTurn, unablePackage}
                })
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
            .finally(() => setIsLoading(false))
    }, [change]);

    const imports = [
        {id: 1, helpText: "Импорт деталей из файла(базис раскроя)"},
        {id: 2, helpText: "Импорт деталей из файла (базис раскроя) с использованием шаблонов"},
        // {id: 3, helpText: "Импорт деталей из файла (Pro100 раскроя)"},
        // {id: 4, helpText: "Импорт деталей из файла (Pro100 раскроя) с использованием шаблонов"}
    ]

    return (
        isLoading ?
            <Box sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> :
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={requestsCuttingNumber}
                    path={[
                        {name: "Главная", href: "/"},
                        {name: "Заказы", href: "/customer/orders"},
                        {name: `${customerOrderNumber}`, href: `/customer/orders/${orderId}/${customerOrderNumber}`}
                    ]}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                        <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                            Детали
                        </Typography>
                    </Box>
                    <ModalAlert data={alertData} onClose={handleCloseAlert}/>
                    <ButtonGroupTabBar activeTab={tab}>
                        <Button tabIndex={1} onClick={(e) => handleChange(e, 1)}>
                            <CheckIcon/>
                            Детали
                        </Button>
                        <Button tabIndex={2} onClick={(e) => handleChange(e, 2)}>
                            <CheckIcon/>
                            Импорт
                        </Button>
                        <Button tabIndex={3} onClick={(e) => handleChange(e, 3)}>
                            <CheckIcon/>
                            Экспорт
                        </Button>
                    </ButtonGroupTabBar>
                    {tab === 1 ? <CustomerDetailsTable
                        data={data}
                        setChange={setChange}
                        unableTurn={unable.unableTurn}
                        unablePackage={unable.unablePackage}
                        pageSize={100}
                        isError={isError}
                        setData={setData}
                        enableEditing={true}
                        orderId={orderId}
                        requestsCuttingId={requestsCuttingId}
                        customerOrderNumber={customerOrderNumber}
                        requestsCuttingNumber={requestsCuttingNumber}
                    /> : tab === 2 ? <Box>{imports.map(el => {
                        return <UploadFilesRequestsForm
                            key={el.id}
                            uploadData={el}
                            detail={true}
                            orderId={orderId}
                            change={change}
                            setChange={setChange}
                            calculationRequestsCutting={false}
                            upload={FileService.uploadOrderSample}
                        />
                    })}
                    </Box> : <Box/>}
                </Box>
            </Box>
    )
}
export default CustomerRequestsCutting
