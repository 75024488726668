import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {authorization, logout} from "../../../Constants";
import OrganizationCustomerService from "../../../API/orders/orders/organization/OrganizationCustomerService";
import {determineAccessibility} from "./determineAccessibility";
import {ToolbarFirst} from "./ToolbarFirst";
import {ToolbarSecond} from "./ToolbarSecond";

const MenuAppBar = () => {
        const profileStorage = JSON.parse(localStorage.getItem("profile"))
        const auth = authorization + '/oauth2/authorization/up-me-client-authorization-code';
        const [profile, setProfile] = useState(profileStorage)
        const [accessibility, setAccessibility] = useState({})

        useEffect(() => {
            OrganizationCustomerService.profile().then(function (response) {
                handleAuth(response)
            }).catch(handleError);
        }, []);

        const handleAuth = (response) => {
            localStorage.setItem("profile", JSON.stringify(response.data))
            setProfile(response.data)
            setAccessibility(() => determineAccessibility(response.data.userRoles))
        }

        const handleError = () => {
            const currentUrl = window.location.href
            if (currentUrl !== authorization + "/") {
                window.location.href = authorization + "/"
            }
            localStorage.setItem("profile", JSON.stringify(null))
            setProfile(null)
            setAccessibility([])
        }

        const handleChange = () => {
            if (profile) {
                OrganizationCustomerService.profileHead().then(function () {
                    window.location.href = logout;
                    localStorage.setItem("profile", JSON.stringify(null))
                }).catch(function () {
                    window.location.href = auth
                });
            } else {
                window.location.href = auth
            }
        }

        return (
            <Box sx={{width: "100%", flexGrow: 1}}>
                <ToolbarFirst profile={profile} handleChange={handleChange}/>
                {profile && <ToolbarSecond accessibility={accessibility}/>}
            </Box>
        );
    }
;

export default MenuAppBar;
