import React from 'react';
import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material";
import ButtonExit from "../button/ButtonExit";
import ButtonAdd from "../button/ButtonAdd";

const ModalConfirm = ({title, modalOpen, handleClose, handleSubmit, action}) => {
    return (
        <div>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <ButtonExit text={"Отменить"} onClick={handleClose}/>
                        <ButtonAdd text={action} onClick={handleSubmit}/>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalConfirm;

