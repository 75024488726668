import {Button} from "@mui/material";

const ButtonBlue = ({onClick, text, disabled = false}) => {
    return <Button
        onClick={onClick}
        disabled={disabled}
        sx={{
            width: 172,
            height: 44,
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "white",
            borderRadius: "100px",
            boxShadow: "none",
            textTransform: 'none',
            '@media (max-width: 360px)': {
                width: 148,
                height: 36,
            },
            "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
                boxShadow: "none",
            }
        }}>
        {text}
    </Button>
}
export default ButtonBlue