import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import {Delete, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Unarchive} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import WorkingCuttingMapService from "../../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";
import {structure} from "../../../../../data/Structure";
import {workingCuttingMapStatuses} from "../../../../../data/WorkingCuttingMapStatuses";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import ModalConfirm from "../../../modal/ModalConfirm";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ModalFinishCutCuttingMap from "./ModalFinishCutCuttingMap";
import MaterialWarehouseService from "../../../../../API/orders/warehouse/material/MaterialWarehouseService";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

const WorkingCuttingMapTable = ({
                                    data,
                                    setData,
                                    isCalculation,
                                    disabledEdit,
                                    queue,
                                    minNumberCutting,
                                    maxNumberCutting,
                                    unableCut,
                                    setUnableCut
                                }) => {

    const handleDeleteRow = useCallback(
        (row) => {
            WorkingCuttingMapService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const raiseCuttingPriority = useCallback(
        (row, data, setData) => {
            WorkingCuttingMapService.raiseCuttingPriority(row.original.id)
                .then(function () {
                    const index = row.index;
                    if (row.index - 1 > -1) {
                        const newData = [...data];
                        newData[index].numberCutting -= 1;
                        newData[index - 1].numberCutting += 1;
                        const movedItem = newData.splice(index, 1)[0];
                        newData.splice(index - 1, 0, movedItem);
                        setData(newData);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [setData],
    );

    const [modalOpenReturnForRevision, setModalOpenReturnForRevision] = useState(false);

    const returnForRevision = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRowIndex(row.index)
            setModalOpenReturnForRevision(true)
        },
        [data],
    );

    const handleCloseReturnForRevision = () => {
        setCurrentIdRow(null);
        setCurrentRowIndex(null)
        setModalOpenReturnForRevision(false);
    };

    const handleSubmitReturnForRevision = async () => {
        WorkingCuttingMapService.returnForRevision(currentIdRow)
            .then(function () {
                data.splice(currentRowIndex, 1);
                setData([...data]);
                setModalOpenReturnForRevision(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const lowerCuttingPriority = useCallback(
        (row, data, setData) => {
            WorkingCuttingMapService.lowerCuttingPriority(row.original.id)
                .then(function () {
                    const index = row.index;
                    if (row.index - 1 > -1) {
                        const newData = [...data];
                        newData[index + 1].numberCutting -= 1;
                        newData[index].numberCutting += 1;
                        const movedItem = newData.splice(index, 1)[0];
                        newData.splice(index - 1, 0, movedItem);
                        setData(newData);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [setData],
    );

    const navigate = useNavigate();

    const redirectOrders = () => {
        navigate('/organization/orders');
    };

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const redirectRequestsCutting = () => {
        navigate('/organization/requests-cutting');
    };

    const redirectOptimizations = () => {
        navigate('/organization/optimizations');
    };

    const redirectCalculationRequestsCutting = () => {
        navigate('/organization/requests-cutting/Calculation');
    };

    const redirectCalculationOptimizations = () => {
        navigate('/organization/optimizations-calculation');
    };

    const redirectArchive = () => {
        navigate('/organization/working-cutting-maps-archive');
    };

    const redirectCalculationArchive = () => {
        navigate('/organization/working-cutting-maps-calculation-archive');
    };

    const redirectCutFiles = (row) => {
        navigate('/organization/working-cutting-map/' + data[row.index].id + '/' + data[row.index].materialName + '/cut-files');
    };

    const checkDelete = (row) => {
        const workingCuttingMapState = row.original.workingCuttingMapState
        return workingCuttingMapState === 'EXPECTATION' || workingCuttingMapState === 'MAPS_CANCEL' || workingCuttingMapState === 'RESERVES_CANCEL'
            || workingCuttingMapState === 'CALCULATION' || workingCuttingMapState === 'CALCULATION_RESERVES_CANCEL';
    };

    const [currentIdRow, setCurrentIdRow] = useState(null);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [modalOpenSetForCutting, setModalOpenSetForCutting] = useState(false);
    const [modalOpenSetStartCutting, setModalOpenSetStartCutting] = useState(false);
    const [modalOpenSendToArchive, setModalOpenSendToArchive] = useState(false);

    const [dataCutOff, setDataCutOff] = useState([]);

    useEffect(() => {
        if (currentIdRow !== null && currentIdRow !== undefined) {
            MaterialWarehouseService.getAllCutOffsByWorkingCuttingMapId(currentIdRow).then(function (response) {
                let data = response.data;
                data.forEach((row) => {
                    unableCut[row.id] = row.confirmedAfterCutting;
                });
                setDataCutOff(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [currentIdRow]);


    const setForCutting = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRowIndex(row.index)
            setModalOpenSetForCutting(true)
        },
        [data],
    );

    const setStartCutting = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRowIndex(row.index)
            setModalOpenSetStartCutting(true)
        },
        [data],
    );

    const handleSubmitSetForCutting = async () => {
        WorkingCuttingMapService.setForCutting(currentIdRow)
            .then(function () {
                data.splice(currentRowIndex, 1);
                setData([...data]);
                setModalOpenSetForCutting(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseSetForCutting = () => {
        setCurrentIdRow(null);
        setCurrentRowIndex(null)
        setModalOpenSetForCutting(false);
    };

    const handleSubmitSetStartCutting = async () => {
        WorkingCuttingMapService.setStartCutting(currentIdRow)
            .then(function () {
                data[currentRowIndex].workingCuttingMapState = 'CUTTING_STARTED';
                setData([...data]);
                handleCloseSetStartCutting();
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseSetStartCutting = () => {
        setCurrentIdRow(null);
        setCurrentRowIndex(null)
        setModalOpenSetStartCutting(false);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: 'Номер',
                accessorKey: 'number',
                minSize: 15,
                maxSize: 15,
                enableSorting: !queue,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/working-cutting-map/' + data[row.index].id + '/' + data[row.index].materialName}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'materialName',
                header: 'Материал',
                accessorKey: 'materialName',
                minSize: 70,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                enableSorting: !queue,
            },
            {
                id: 'materialStructure',
                header: 'Структура',
                accessorKey: 'materialStructure',
                minSize: 15,
                maxSize: 15,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                Cell: ({cell}) => structure[cell.getValue()],
                enableSorting: !queue,
            },
            {
                id: 'workingCuttingMapState',
                header: 'Статус',
                accessorKey: 'workingCuttingMapState',
                minSize: 15,
                maxSize: 15,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                Cell: ({cell}) => workingCuttingMapStatuses[cell.getValue()],
                enableSorting: !queue,
            },
            {
                id: 'optimization',
                header: 'Оптимизация',
                accessorKey: 'optimization.number',
                minSize: 15,
                maxSize: 15,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/details/optimization/' + data[row.index].optimization.id + '/' + data[row.index].optimization.number}>{cell.getValue()}</Link>
                    </li>
                ),
                enableSorting: !queue,
            },
            {
                id: 'optimizationQuantity',
                header: 'Опт. кв.м',
                accessorKey: 'optimizationQuantity',
                minSize: 15,
                maxSize: 15,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                Cell: ({cell}) => cell.getValue() === null ? null : parseFloat(cell.getValue()).toFixed(3),
                enableSorting: !queue,
            },
            {
                id: 'orders',
                header: 'Заказы',
                accessorKey: 'orderNumbers',
                minSize: 550,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                enableSorting: !queue,
            },
            {
                id: 'numberCutting',
                header: 'приоритет',
                accessorKey: 'numberCutting',
                minSize: 15,
                enableHiding: false,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableSorting: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ];

        if (!queue) {
            baseColumns.splice(7, 0, {
                id: 'nominalQuantity',
                header: 'Номинал кв.м',
                accessorKey: 'nominalQuantity',
                minSize: 15,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                Cell: ({cell}) => cell.getValue() === null ? null : cell.getValue().toFixed(3),
            });
        }

        if (queue) {
            baseColumns.splice(6, 0, {
                id: 'file',
                header: 'Файлы',
                accessorKey: 'nominalQuantity',
                minSize: 15,
                maxSize: 15,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
                Cell: ({row}) => (
                    <Button
                        size="small"
                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                        color="pencil"
                        onClick={() => redirectCutFiles(row)}
                    >
                        <DriveFileMoveIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </Button>
                ),
            });
        }

        return baseColumns;

    }, [data, setData, queue]);

    const [modalOpenFinishCutCuttingMap, setModalOpenFinishCutCuttingMap] = useState(false);

    const setFinishCutCuttingMap = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRowIndex(row.index);
            setModalOpenFinishCutCuttingMap(true);
        },
        [data],
    );

    const handleSubmitSetFinishCut = async () => {
        WorkingCuttingMapService.setFinishCut(currentIdRow)
            .then(function () {
                data[currentRowIndex].workingCuttingMapState = 'CUT_OUT';
                setData([...data]);
                handleCloseSetFinishCut();
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseSetFinishCut = () => {
        setCurrentIdRow(null);
        setCurrentRowIndex(null)
        setModalOpenFinishCutCuttingMap(false);
    };

    const openModalToArchive = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRowIndex(row.index);
            setModalOpenSendToArchive(true);
        },
        [data],
    );

    const handleCloseModalToArchive = () => {
        setCurrentIdRow(null);
        setCurrentRowIndex(null)
        setModalOpenSendToArchive(false);
    };

    const handleSubmitSendToArchive = async () => {
        WorkingCuttingMapService.sendToArchive(currentIdRow)
            .then(function () {
                data.splice(currentRowIndex, 1);
                setData([...data]);
                handleCloseModalToArchive();
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                            sx: {
                                p: '0px 0px 0px 15px',
                                m: '0px',
                                justifyContent: 'flex-start',
                            },
                        },
                        size: 5,
                        maxWidth: '5px',
                        sx: {
                            p: '0px 0px 0px 15px',
                            m: '0px',
                            justifyContent: 'flex-start',
                        },
                    },
                }}
                columns={columns}
                data={data}
                enableEditing={!disabledEdit}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.01rem',
                        justifyContent: 'flex-start',
                        maxWidth: '180px'
                    }}>
                        {!queue && (checkDelete(row) ? (
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="error"
                                    onClick={() => handleDeleteRow(row)}
                                >
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        ))}
                        {!queue && (row.original.workingCuttingMapState === 'MAPS_APPROVED' ? (
                            <Tooltip arrow placement="right" title="Отправить карту на раскрой">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="primary"
                                    onClick={() => setForCutting(row)}>
                                    <KeyboardDoubleArrowRight
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="primary"
                            >
                                <KeyboardDoubleArrowRight sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        ))}
                        {queue ? (row.original.workingCuttingMapState === 'FOR_CUTTING' ?
                                <Tooltip arrow placement="right" title="Вернуть карту на доработку">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="primary"
                                        onClick={() => returnForRevision(row, data, setData)}>
                                        <KeyboardDoubleArrowLeft
                                            sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}
                                        />
                                    </Button>
                                </Tooltip> : <Button
                                    disabled={true}
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="primary">
                                    <KeyboardDoubleArrowLeft
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}
                                    />
                                </Button>
                        ) : null}
                        {queue ? (row.original.numberCutting !== minNumberCutting ?
                                <Tooltip arrow placement="right" title="Поднять карту выше">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="primary"
                                        onClick={() => raiseCuttingPriority(row, data, setData)}>
                                        <KeyboardDoubleArrowUpIcon
                                            sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}
                                        />
                                    </Button>
                                </Tooltip> : <Button
                                    disabled={true}
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="primary">
                                    <KeyboardDoubleArrowUpIcon
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}
                                    />
                                </Button>
                        ) : null}
                        {queue ? (row.original.numberCutting !== maxNumberCutting ?
                                <Tooltip arrow placement="right" title="Опустить карту ниже">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="primary"
                                        onClick={() => lowerCuttingPriority(row, data, setData)}>
                                        <KeyboardDoubleArrowDownIcon
                                            sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}
                                        />
                                    </Button>
                                </Tooltip> :
                                <Button disabled={true}
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="primary">
                                    <KeyboardDoubleArrowDownIcon
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                        ) : null}
                        {queue ? (row.original.workingCuttingMapState === 'FOR_CUTTING' ?
                                <Tooltip arrow placement="right" title="Начать раскрой карты">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="pencil"
                                        onClick={() => setStartCutting(row)}>
                                        <PlayCircleOutlineIcon
                                            sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 15}}
                                        />
                                    </Button>
                                </Tooltip> : <Button
                                    disabled={true}
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="pencil">
                                    <PlayCircleOutlineIcon
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 15}}
                                    />
                                </Button>
                        ) : null}
                        {queue ? (row.original.workingCuttingMapState === 'CUTTING_STARTED' ?
                                <Tooltip arrow placement="right" title="Подтвердить раскрой карты">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                        color="success"
                                        onClick={() => setFinishCutCuttingMap(row)}>
                                        <ContentCutIcon
                                            sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 15}}
                                        />
                                    </Button>
                                </Tooltip> : <Button
                                    disabled={true}
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="success">
                                    <ContentCutIcon
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 15}}
                                    />
                                </Button>
                        ) : null}
                        {queue ? (row.original.workingCuttingMapState === 'CUT_OUT' ?
                                <Tooltip arrow placement="right" title="Поместить в архив">
                                    <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                            onClick={() => openModalToArchive(row)}>
                                        <Unarchive sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                    </Button>
                                </Tooltip> :
                                <Button
                                    disabled={true}
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="error"
                                    onClick={() => openModalToArchive(row)}>
                                    <Unarchive sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                        ) : null}
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                        numberCutting: false
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    sorting: [
                        {
                            id: 'numberCutting',
                            desc: false,
                        },]
                }}
                localization={
                    MRT_Localization_RU
                }
                renderTopToolbarCustomActions={
                    ({table}) => {
                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectOrders}
                                        variant="contained"
                                    >
                                        Заказы
                                    </Button>
                                    {!isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectRequestsCutting}
                                        variant="contained"
                                    >
                                        Заявки на раскрой
                                    </Button>}
                                    {isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectCalculationRequestsCutting}
                                        variant="contained"
                                    >
                                        Рас. заявки на раскрой
                                    </Button>}
                                    {!isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectPlans}
                                        variant="contained"
                                    >
                                        Планы
                                    </Button>}
                                    {!isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectOptimizations}
                                        variant="contained"
                                    >
                                        Оптимизации
                                    </Button>}
                                    {isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectCalculationOptimizations}
                                        variant="contained"
                                    >
                                        Рас.оптимизации
                                    </Button>}
                                    {!isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectArchive}
                                        variant="contained"
                                    >
                                        Архив
                                    </Button>}
                                    {isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectCalculationArchive}
                                        variant="contained"
                                    >
                                        Архив
                                    </Button>}
                                </Box>
                            </div>
                        );
                    }
                }
            />
            <ModalConfirm
                title={"Подтвердить отправить карту на раскрой?"}
                modalOpen={modalOpenSetForCutting}
                handleClose={handleCloseSetForCutting}
                handleSubmit={handleSubmitSetForCutting}
                action={"Подтвердить"}
            />
            <ModalConfirm
                title={"Подтвердить вернуть карту раскроя на доработку?"}
                modalOpen={modalOpenReturnForRevision}
                handleClose={handleCloseReturnForRevision}
                handleSubmit={handleSubmitReturnForRevision}
                action={"Подтвердить"}
            />
            <ModalConfirm
                title={"Подтвердить начало раскроя?"}
                modalOpen={modalOpenSetStartCutting}
                handleClose={handleCloseSetStartCutting}
                handleSubmit={handleSubmitSetStartCutting}
                action={"Подтвердить"}
            />
            <ModalConfirm
                title={"Подтвердить отправить карту в архив?"}
                modalOpen={modalOpenSendToArchive}
                handleClose={handleCloseModalToArchive}
                handleSubmit={handleSubmitSendToArchive}
                action={"Подтвердить"}
            />
            <ModalFinishCutCuttingMap
                open={modalOpenFinishCutCuttingMap}
                handleClose={handleCloseSetFinishCut}
                handleSubmit={handleSubmitSetFinishCut}
                action={"Подтвердить"}
                data={dataCutOff}
                setData={setDataCutOff}
                unableCut={unableCut}
                setUnableCut={setUnableCut}
            />
        </>
    );
};

export default WorkingCuttingMapTable;