import React, {useMemo} from 'react';
import {Path} from "three";
import {groupBy} from "../../../../../utils/common/groupBy";
import EndFace from "./CanvasComponents/EndFace";
import LineElement from "./CanvasComponents/LineElement";
import {calcEndHoleOutline} from "./CanvasCalcPositions/calcEndHoleOutline";
import {Border} from "./CanvasComponents/Border";
import Detail from "./CanvasComponents/Detail";
import Notch from "./CanvasComponents/Notch";
import {calcSidePos} from "./CanvasCalcPositions/calcSidePos";
import {calcSideHolePos} from "./CanvasCalcPositions/calcSideHolePos";
import {Text, useTexture} from "@react-three/drei";
import Point from "./CanvasComponents/Point";
import Layer from "./CanvasComponents/Layer";
import {createShape} from "./CanvasCalcPositions/createShape";
import * as THREE from "three";
import image from "../../../../../components/Icons/hatch.png";
import {getHoveredId} from "../../../../../utils/processing/getHoveredId";

const PLACEMENT = {
    top: "TOP", bottom: "BOTTOM", front: "FRONT", back: "BACK", left: "LEFT", right: "RIGHT",
};

const groupByHoles = (data) => groupBy((it) => [PLACEMENT.top, PLACEMENT.bottom].includes(it.contour.position) ? "holes" : "sideHoles", data, {
    holes: [],
    sideHoles: []
},);

const groupByGrooves = (data) => groupBy((it) => it.side ? "sideGrooves" : "grooves", data, {
    grooves: [],
    sideGrooves: []
},);

const getGroovesShape = (grooves, detailData, scale) => {
    const res = []
    grooves.map((el) => {
        if (el.sideContours.length > 0) {
            if (el.type !== "SIDE") {
                res.push({shape: createShape(el.contour.points), data: el, side: false})
            }
            el.sideContours.map(contour => {
                const sidePos = calcSidePos(contour.position, detailData, scale)
                const side = {x: sidePos.x / scale, y: sidePos.y / scale}

                res.push({
                    shape: createShape(contour.points, contour.position, detailData.height, side),
                    data: {...el, sideContours: contour},
                    side: true
                })
            })
        } else {
            res.push({shape: createShape(el.contour.points), data: el, side: false})
        }
    })
    return res
}


const HoveredSideHole = React.memo(({hole, scale, detailData, position, isGroove}) => {
    // const {scale, detailData} = useProcessingContext();
    // const position = useCoreProcessingDetailPosition();

    const basePoint = isGroove ? hole.data?.basePoint : hole.center
    const placementPosition = isGroove ? hole.data?.contour.position : hole.contour.position

    const {xPos, yPos, x, y} = calcSidePos(placementPosition, detailData, scale)
    const {
        scaleX,
        scaleY,
        nameX,
        nameY,
    } = calcSideHolePos(basePoint, placementPosition, detailData, scale, xPos, yPos, x, y, hole?.radius)

    const xWidthDirection = placementPosition === PLACEMENT.right ? x : -x
    const yWidthDirection = placementPosition === PLACEMENT.back ? y : -y
    const textOffsetY = placementPosition === PLACEMENT.back ? 0.2 : -0.2
    const textOffsetX = placementPosition === PLACEMENT.right ? 0.2 : -0.2

    return (
        <Layer layer={3}>
            <LineElement
                scale={scale}
                position={[position[0] + xPos, position[1] + yPos, position[2] + scale]}
                points={[[scaleX / scale, scaleY / scale], [scaleX / scale, yWidthDirection / scale / 2]]}
                color={"black"}
                dashSize={0.05}
                gapSize={0.05}
            />
            <Text
                color={"black"}
                fontSize={.2}
                position={[position[0] + xPos + scaleX, position[1] + yPos + yWidthDirection / 2 + textOffsetY, position[2] + scale]}
                maxWidth={6}
            >
                {nameY}
            </Text>

            <LineElement
                scale={scale}
                position={[position[0] + xPos, position[1] + yPos, position[2] + scale]}
                points={[[scaleX / scale, scaleY / scale], [xWidthDirection / scale / 2, scaleY / scale]]}
                color={"black"}
                dashSize={0.05}
                gapSize={0.05}
            />
            <Text
                color={"black"}
                fontSize={.2}
                position={[position[0] + xPos + xWidthDirection / 2 + textOffsetX, position[1] + yPos + scaleY, position[2] + scale]}
                maxWidth={6}
            >
                {nameX}
            </Text>
        </Layer>
    )
})


const HoveredHole = React.memo(({hole, scale, position, isGroove}) => {
    // const scale = useProcessingScale();
    // const position = useCoreProcessingDetailPosition();

    const basePoint = isGroove ? hole.data?.basePoint || hole.data?.contour.points[0] : hole.center
    const isQuarter = hole.data?.name.includes("quarter")

    const x = basePoint.x.toFixed()
    const y = basePoint.y.toFixed()

    const showX = !isQuarter || (isQuarter && x > 0)
    const showY = !isQuarter || (isQuarter && y > 0)

    return (
        <Layer layer={2}>
            {showY && <><LineElement
                scale={scale}
                position={[position[0], position[1], position[2] - scale * 2]}
                points={[[x, y], [x, 0]]}
                color={"black"}
                dashSize={0.05}
                gapSize={0.05}
            />
                <Text
                    color={"black"}
                    fontSize={.2}
                    position={[position[0] + x * scale, position[1] - 0.4, position[2] - scale * 2]}
                    maxWidth={6}
                >
                    {y}
                </Text></>}
            {showX && <><LineElement
                scale={scale}
                position={[position[0], position[1], position[2] - scale * 2]}
                points={[[x, y], [0, y]]} color={"black"}
                dashSize={0.05}
                gapSize={0.05}
            />
                <Text
                    rotation={[0, 0, 20.4]}
                    color={"black"}
                    fontSize={.2}
                    position={[position[0] - 0.4, position[1] + y * scale, position[2] - scale * 2]}
                    maxWidth={6}
                >
                    {x}
                </Text></>}
        </Layer>
    )
})

const DetailCanvas = ({
                          editableElement,
                          processingHandle,
                          selectedHole,
                          setSelectedHole,
                          hoveredHoleId,
                          setHoveredHoleId,
                          hoveredGrooveId,
                          setHoveredGrooveId,
                          cutData,
                          prevInnerContour,
                          prevArc,
                          openPopoverActives,
                          position,
                          detailData,
                          scale,
                      }) => {
    // const {detailData, scale} = useProcessingContext();
    // const corePosition = useCoreProcessingDetailPosition();

    const mainContour = createShape(detailData.detailContour.points)
    const notches = detailData.notches.map((el) => ({shape: createShape(el.contour.points), data: el}))
    const roundNotches = detailData.roundNotches.map((el) => ({shape: createShape(el.contour.points), data: el}))
    const groovesShape = getGroovesShape(detailData?.grooves, detailData, scale)
    const quarters = getGroovesShape(detailData?.quarters, detailData, scale)

    const {holes, sideHoles} = groupByHoles([...detailData.holes, ...detailData.roundNotches]);
    const {grooves, sideGrooves} = groupByGrooves([...groovesShape, ...quarters])
    const allInsideContours = [...grooves, ...notches, ...roundNotches]

    holes?.forEach((el, i) => {
        mainContour.holes.push(new Path().absellipse(holes[i].center.x, holes[i].center.y, holes[i].radius, holes[i].radius, 0, Math.PI * 2))
    })

    const initialDetailShape = [
        {
            position: PLACEMENT.left,
            side: [[0, 0], [0, detailData.width]],
        },
        {
            position: PLACEMENT.back,
            side: [[0, detailData.width], [detailData.length, detailData.width]],
        },
        {
            position: PLACEMENT.right,
            side: [[detailData.length, detailData.width], [detailData.length, 0]],
        },
        {
            position: PLACEMENT.front,
            side: [[detailData.length, 0], [0, 0]],
        },
    ]

    const textureFromNotch = useTexture(image)
    textureFromNotch.wrapS = THREE.RepeatWrapping;
    textureFromNotch.wrapT = THREE.RepeatWrapping;
    textureFromNotch.repeat.set(0.2 * scale, 0.2 * scale);

    const hoveredSideHole = sideHoles?.find(hole => getHoveredId(hole) === hoveredHoleId);
    const hoveredHole = holes?.find(hole => getHoveredId(hole) === hoveredHoleId);
    const hoveredGroove = grooves?.find(groove => getHoveredId(groove.data) === hoveredGrooveId);
    const hoveredSideGroove = sideGrooves?.find(groove => getHoveredId(groove.data) === hoveredGrooveId && groove.data.type === "SIDE");

    return (
        <>
            <Detail
                scale={scale}
                position={position}
                detailData={detailData}
                shape={mainContour}
                holes={holes}
                sideHoles={sideHoles}
                hoveredHole={hoveredHole}
                selectedHole={selectedHole}
                setSelectedHole={setSelectedHole}
                setHoveredHole={setHoveredHoleId}
                processingHandle={processingHandle}
                prevInnerContour={prevInnerContour}
            />

            {
                allInsideContours.map((el, i) => {
                    const isGroove = el.data.name.includes("groove")
                    const isQuarter = el.data.name.includes("quarter")
                    const isRound = el.data.name.includes("round")
                    const notThrough = el.data.depth < detailData.height

                    return (
                        <React.Fragment key={`${el.data.id}${el.data.name}`}>
                            <Notch
                                scale={scale}
                                position={position}
                                notch={el}
                                texture={textureFromNotch}
                                isGroove={isGroove}
                                isQuarter={isQuarter}
                                isRound={isRound}
                                setHoveredGrooveId={setHoveredGrooveId}
                                processingHandle={processingHandle}
                                notThrough={notThrough}
                                setSelectedHole={setSelectedHole}
                                setHoveredHoleId={setHoveredHoleId}
                                startPoint={cutData.possible[0]}
                            />
                            {
                                el.data.contour.points.map((point, i, arr) => {
                                        const edge = detailData.edges.find(el => el.startPoint.id === point.id)

                                        return i < arr.length - 1 && <React.Fragment key={point.id}>
                                            <Border
                                                start={point}
                                                next={arr[i + 1]}
                                                prev={i === 0 ? arr[arr.length - 2] : arr[i - 1]}
                                                editableElement={editableElement}
                                                processingHandle={processingHandle}
                                                cutData={cutData}
                                                prevArc={prevArc}
                                                isGroove={isGroove || isQuarter}
                                                position={position}
                                                scale={scale}
                                                edge={edge}
                                            />
                                            {
                                                !isGroove && !isQuarter && (
                                                    <Point
                                                        point={point}
                                                        editableElement={editableElement}
                                                        scale={scale}
                                                        position={position}
                                                        openPopoverActives={openPopoverActives}
                                                        isRound={isRound}/>
                                                )
                                            }
                                        </React.Fragment>
                                    }
                                )
                            }
                        </React.Fragment>
                    )
                })
            }

            {
                initialDetailShape.map((el, i) => {
                    const holes = sideHoles?.filter(hole => el.position === hole.contour.position)
                    const groovesOnSide = sideGrooves?.filter(groove => groove.data.sideContours.position === el.position)
                    const prevContour = prevInnerContour.contour?.position === el.position ? prevInnerContour : {}

                    return (
                        <React.Fragment key={i}>
                            <EndFace
                                detailData={detailData}
                                scale={scale}
                                position={position}
                                side={el.position}
                                groovesOnSide={groovesOnSide}
                                holes={holes}
                                selectedHole={selectedHole}
                                setSelectedHole={setSelectedHole}
                                setHoveredHoleId={setHoveredHoleId}
                                processingHandle={processingHandle}
                                texture={textureFromNotch}
                                setHoveredGrooveId={setHoveredGrooveId}
                                editableElement={editableElement}
                                prevInnerContour={prevContour}
                                hoveredSideHole={hoveredSideHole}
                            />
                            <Layer layer={1}>
                                <LineElement
                                    key={i}
                                    position={position}
                                    scale={scale}
                                    points={[el.side[0], el.side[1]]}
                                    color={"#2C3038"}
                                    dashSize={0.07}
                                    gapSize={0.07}
                                />
                            </Layer>

                        </React.Fragment>
                    )
                })
            }

            {hoveredSideHole &&
                <HoveredSideHole hole={hoveredSideHole} scale={scale} position={position} detailData={detailData}/>}
            {hoveredSideGroove &&
                <HoveredSideHole hole={hoveredSideGroove} scale={scale} position={position}
                                 detailData={detailData} isGroove={true}/>
            }

            {
                sideHoles?.map(hole => {
                    const {xPos, yPos, x, y} = calcSidePos(hole.contour.position, detailData, scale)
                    const {
                        scaleRadius,
                    } = calcSideHolePos(hole.center, hole.contour.position, detailData, scale, xPos, yPos, x, y, hole.radius)

                    return (
                        <React.Fragment key={hole.id}>
                            {
                                calcEndHoleOutline(hole, scaleRadius / scale).map((el, i) => (
                                    <Layer layer={0} key={"outline-" + i}>
                                        <LineElement
                                            scale={scale}
                                            position={position}
                                            points={[el[0], el[1]]}
                                            color={"#2C3038"}
                                            dashSize={0.05}
                                            gapSize={0.05}
                                        />
                                    </Layer>
                                ))
                            }
                        </React.Fragment>
                    )
                })
            }

            {hoveredHole && <HoveredHole hole={hoveredHole} scale={scale} position={position}/>}

            {hoveredGroove && <HoveredHole hole={hoveredGroove} scale={scale} position={position} isGroove={true}/>}

            {
                detailData.detailContour.points.map((point, i, arr) => {
                    const edge = detailData.edges.find(el => el.startPoint.id === point.id)

                    return i < arr.length - 1 && <React.Fragment key={point.id}>
                        <Border
                            start={point}
                            next={arr[i + 1]}
                            prev={i === 0 ? arr[arr.length - 2] : arr[i - 1]}
                            editableElement={editableElement}
                            processingHandle={processingHandle}
                            cutData={cutData}
                            prevArc={prevArc}
                            position={position}
                            scale={scale}
                            edge={edge}
                        />
                        <Point
                            point={point}
                            editableElement={editableElement}
                            scale={scale}
                            position={position}
                            openPopoverActives={openPopoverActives}/>
                    </React.Fragment>
                })
            }
        </>)
}

export default DetailCanvas
