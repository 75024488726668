import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import CloudOptionsMaterialModule from "../../../components/UI/modul/mainoptions/CloudOptionsMaterialModule";
import SupplierService from "../../../API/orders/warehouse/supplier/SupplierService";
import GroupMaterialService from "../../../API/orders/orders/organization/GroupMaterialService";
import ManufacturerService from "../../../API/orders/warehouse/manufacturer/ManufacturerService";
import MaterialOrganizationOptionService
    from "../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import CurrentOptionsMaterialModule from "../../../components/UI/modul/mainoptions/CurrentOptionsMaterialModule";
import UserSupplierMaterialCloudService
    from "../../../API/material-cloud/user/UserSupplierMaterialCloudService";
import UserGroupMaterialMaterialCloudService
    from "../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import UserManufacturerMaterialCloudService
    from "../../../API/material-cloud/user/UserManufacturerMaterialCloudService";
import UserMaterialMaterialCloudService
    from "../../../API/material-cloud/user/UserMaterialMaterialCloudService";
import AdminMaterialMaterialCloudService
    from "../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";
import {authorizedAdmin, authorizedOrganization} from "../../../Constants";

const AllMaterialItem = () => {
        const profile = JSON.parse(localStorage.getItem("profile"))
        const isOrganization = profile?.userRoles.some(role => authorizedOrganization.includes(role.name))
        const isAdmin = profile?.userRoles.some(role => authorizedAdmin.includes(role.name))
        const params = useParams();

        const [data, setData] = useState(null);
        const [dataMOO, setDataMOO] = useState(null);
        const [change, setChange] = useState(null);
        const [group, setGroup] = useState(null);

        const [supplierList, setSupplierList] = useState(null);
        const [groupMaterialList, setGroupMaterialList] = useState(null);
        const [manufacturerList, setManufacturerList] = useState(null);

        const [supplierMaterialCloudList, setSupplierMaterialCloudList] = useState(null);
        const [groupMaterialMaterialCloudList, setGroupMaterialMaterialCloudList] = useState(null);
        const [manufacturerMaterialCloudList, setManufacturerMaterialCloudList] = useState(null);

        const [supplierId, setSupplierId] = useState(null);
        const [manufacturerId, setManufacturerId] = useState(null);
        const [groupMaterialId, setGroupMaterialId] = useState(null);
        const [isDataMOO, setIsDataMOO] = useState(false);

        const id = params.id;
        const baseUnit = params.baseUnit;
        const typeMaterial = params.typeMaterial;

        useEffect(() => {
            UserSupplierMaterialCloudService.getAll().then(function (response) {
                setSupplierMaterialCloudList(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            UserGroupMaterialMaterialCloudService.getAll().then(function (response) {
                setGroupMaterialMaterialCloudList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });
            UserManufacturerMaterialCloudService.getAll().then(function (response) {
                setManufacturerMaterialCloudList(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });

            loadLists();
            UserMaterialMaterialCloudService.getWithAllPrices(id).then(function (response) {
                let data = response.data;
                setData(data)
                setGroup(data.groupMaterial)
            })
                .catch(function (error) {
                    console.log(error);
                });

            MaterialOrganizationOptionService.getForCloudCatalog(id).then(function (response) {
                let data = response.data;
                setIsDataMOO(data !== null && data !== undefined && data !== '');
                setDataMOO(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const [dataMaterialWholeData, setDataMaterialWholeData] = useState([]);
        const [dataMaterialMinStripeData, setDataMaterialMinStripeData] = useState([]);
        const [dataMaterialFixedStripeData, setDataMaterialFixedStripeData] = useState([]);

        useEffect(() => {
            UserSupplierMaterialCloudService.getAllCurrentWholeByMaterialId(id)
                .then(function (response) {
                    setDataMaterialWholeData(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
            UserSupplierMaterialCloudService.getAllCurrentByMaterialIdMinStripe(id)
                .then(function (response) {
                    setDataMaterialMinStripeData(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
            UserSupplierMaterialCloudService.getAllCurrentByMaterialIdFixedStripe(id)
                .then(function (response) {
                    setDataMaterialFixedStripeData(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleSubmitUpdateMainOptions = () => {


            const currentSupplierId = supplierId === null ? data.defaultSupplier.id : supplierId;
            const currentManufacturerId = manufacturerId == null ? data.manufacturer.id : manufacturerId;
            const currentGroupMaterialId = groupMaterialId == null ? data.groupMaterial.id : groupMaterialId;

            AdminMaterialMaterialCloudService.updateMainOptions(data, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
            ).then(function (response) {
                let data = response.data;
                handleUpdate(data)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const handleSubmitUpdateCurrentOptions = () => {


            const currentSupplierId = supplierId === null ? dataMOO.defaultSupplier.id : supplierId;
            const currentManufacturerId = manufacturerId == null ? dataMOO.manufacturer.id : manufacturerId;
            const currentGroupMaterialId = groupMaterialId == null ? dataMOO.groupMaterial.id : groupMaterialId;

            MaterialOrganizationOptionService.updateCurrentOptions(dataMOO, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
            ).then(function (response) {
                let data = response.data;
                handleUpdateMOO(data)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }


        const addMOOByMaterialId = () => {

            MaterialOrganizationOptionService.createByMaterial(id).then(function (response) {
                let data = response.data;
                setIsDataMOO(data !== null && data !== undefined && data !== '');
                groupMaterialList.push(data.groupMaterial);
                setGroupMaterialId([...groupMaterialList]);
                setDataMOO(data)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const loadLists = () => {

            GroupMaterialService.getAllByOrganization().then(function (response) {
                setGroupMaterialList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });
            SupplierService.getAllByOrganization().then(function (response) {
                setSupplierList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });
            ManufacturerService.getAllByOrganization().then(function (response) {
                setManufacturerList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const handleUpdateMOO = (values) => {
            setIsDataMOO(values);
        };

        const handleUpdate = (values) => {
            setData(values);
        };

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const [value, setValue] = useState('0');

        return (
            <div>
                {<Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <Box sx={{flexGrow: 1}}>
                        <TabContext value={value}>
                            <Box sx={{
                                borderBottom: 1, borderColor: 'divider'
                            }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Облако" value='0'/>

                                    {isOrganization && <Tab label="Текущие" value='1'/>}
                                    {isOrganization && <Tab label="Поставщики" value='2'/>}
                                    {isOrganization && <Tab label="Перечень работ" value='3'/>}


                                </Tabs>
                            </Box>
                            <TabPanel value='0'>
                                <CloudOptionsMaterialModule
                                    data={data}
                                    setData={setData}
                                    supplierList={supplierMaterialCloudList}
                                    groupMaterialList={groupMaterialMaterialCloudList}
                                    manufacturerList={manufacturerMaterialCloudList}

                                    handleSubmit={handleSubmitUpdateMainOptions}
                                    setSupplierId={setSupplierId}
                                    setManufacturerId={setManufacturerId}
                                    setGroupMaterialId={setGroupMaterialId}
                                    baseUnit={baseUnit}
                                    changeOptionsDisabled={!isAdmin}
                                />
                            </TabPanel>
                            <TabPanel value='1'>
                                <CurrentOptionsMaterialModule
                                    data={dataMOO}
                                    setData={setDataMOO}
                                    supplierList={supplierList}
                                    groupMaterialList={groupMaterialList}
                                    manufacturerList={manufacturerList}
                                    handleSubmit={handleSubmitUpdateCurrentOptions}
                                    setSupplierId={setSupplierId}
                                    setManufacturerId={setManufacturerId}
                                    setGroupMaterialId={setGroupMaterialId}
                                    baseUnit={baseUnit}
                                    addMaterial={addMOOByMaterialId}
                                    isDataMOO={isDataMOO}
                                />
                            </TabPanel>
                            {/*<TabPanel value='2'>*/}
                            {/*    <SupplierMaterialItemModule*/}
                            {/*        materialId={id}*/}
                            {/*        baseUnit={baseUnit}*/}
                            {/*        typeMaterial={typeMaterial}*/}
                            {/*        dataMaterialWholeData={dataMaterialWholeData}*/}
                            {/*        dataMaterialMinStripeData={dataMaterialMinStripeData}*/}
                            {/*        dataMaterialFixedStripeData={dataMaterialFixedStripeData}*/}
                            {/*        setDataMaterialWholeData={setDataMaterialWholeData}*/}
                            {/*        setDataMaterialMinStripeData={setDataMaterialMinStripeData}*/}
                            {/*        setDataMaterialFixedStripeData={setDataMaterialFixedStripeData}*/}
                            {/*        create={AdminSupplierMaterialService.createByMaterial}*/}
                            {/*        update={AdminSupplierMaterialService.update}*/}
                            {/*        urlArchiveSM={'/supplier-material-archive-item'}*/}
                            {/*        urlArchiveMS={'material-min-archive-item'}*/}
                            {/*        urlArchiveFS={'material-fixed-archive-item'}*/}
                            {/*        addArchive={AdminSupplierMaterialService.sendToArchive}*/}
                            {/*        isFixPrice={false}*/}
                            {/*    />*/}
                            {/*</TabPanel>*/}
                            {/*<TabPanel value='3'>*/}
                            {/*    <JobMetricOrganizationTable*/}
                            {/*        data={dataJobMetricOrganization}*/}
                            {/*        setData={setDataJobMetricOrganization}*/}
                            {/*        change={change}*/}
                            {/*        setChange={setChange}*/}
                            {/*        rowSelection={rowSelection}*/}
                            {/*        setRowSelection={setRowSelection}*/}
                            {/*        rowIndexSelection={rowIndexSelection}*/}
                            {/*        setRowIndexSelection={setRowIndexSelection}*/}
                            {/*        selectedIds={selectedIds}*/}
                            {/*        setSelectedIds={setSelectedIds}*/}
                            {/*        isChooseSignJob={false}*/}
                            {/*    />*/}
                            {/*</TabPanel>*/}
                        </TabContext>
                    </Box>
                </Box>}
            </div>
        );
    }
;

export default AllMaterialItem;