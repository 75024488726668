import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import ButtonExit from "../../../button/ButtonExit";
import ButtonAdd from "../../../button/ButtonAdd";
import MaterialPageItemModule from "../../../modul/MaterialPageItemModule";
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ModalAddCustomerRequestCutting = ({initValue, open, onClose, title, handleSubmit}) => {
    const [material, setMaterial] = useState(initValue)

    useEffect(() => {
        setMaterial(initValue)
    }, [initValue])
    return (<Dialog open={open} PaperProps={{
        style: {
            minWidth: "1080px",
            height: "716px",
            borderRadius: 8,
        }
    }}>
        <DialogContent sx={{padding: "0px 20px 0px 40px"}}>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: 40,
                    height: 40,
                    color: "#06522D",
                })}
            >
                <CloseIcon style={{fontSize: 24}}/>
            </IconButton>
            <Box style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{title}</DialogTitle>
            </Box>
            <MaterialPageItemModule
                isOrganization={false}
                selectMaterialId={material}
                selectMaterial={(el) => setMaterial(el.id)}
                typeMaterial={"SLAB"}
                modal={true}
            />
        </DialogContent>
        <DialogActions style={{padding: 40, display: "flex", justifyContent: "flex-end"}}>

            <ButtonExit text={"Выйти"} onClick={onClose}/>
            <ButtonAdd text={"Выбрать"} onClick={() => handleSubmit(material)}/>

        </DialogActions>
    </Dialog>)
}
export default ModalAddCustomerRequestCutting