import {Paper} from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import {ReactComponent as ArrowCarousel} from "../../Icons/arrowCarousel.svg"

var items = [
    {
        relativeUrl: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        id: '1',
    },
    {
        relativeUrl: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        id: '2',
    },
    {
        relativeUrl: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        id: 'title',
    },
    {
        relativeUrl: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        id: 'title',
    },
];

export const CarouselComponent = ({banners}) => {
    return <Carousel
        sx={{
            height: '584px', minWidth: "1620px",
            '@media (max-width: 1620px)': {
                minWidth: "1450px",
            },
            '@media (max-width: 1500px)': {
                minWidth: "693px"
            },
        }}
        animation="slide"
        autoPlay={true}
        swipe={true}
        // cycleNavigation={false}
        interval={10000}
        timeout={1000}
        navButtonsAlwaysVisible={true}
        NextIcon={<ArrowCarousel/>}
        PrevIcon={<ArrowCarousel style={{transform: "rotate(180deg)"}}/>}
        navButtonsWrapperProps={{
            sx: {
                "&:hover button": {
                    backgroundColor: 'transparent',
                    opacity: `1!important`
                },
                "& button": {
                    backgroundColor: 'transparent',
                    opacity: `1!important`,
                    margin: 0,
                    padding: 0
                },
                "& button:hover": {
                    backgroundColor: 'transparent',
                    opacity: `1!important`,
                }
            }
        }}
        indicatorIconButtonProps={{
            sx: {
                width: 100,
                height: 4,
                borderRadius: "2px",
                "& svg": {
                    display: "none",
                },
                "&:hover": {
                    backgroundColor:
                        (theme) => theme.palette.primary.light,
                },
                backgroundColor:
                    (theme) => theme.palette.primary.stroke,
            }
        }}
        activeIndicatorIconButtonProps={{
            sx: {
                width: 100,
                height: 4,
                borderRadius: "2px",
                "& svg": {
                    display: "none",
                },
                "&:hover": {
                    backgroundColor:
                        (theme) => theme.palette.primary.light,
                },
                backgroundColor:
                    (theme) => theme.palette.primary.dark,
            }
        }}
        indicatorContainerProps={{
            style: {
                marginTop: '4px',
                display: 'flex',
                justifyContent: "center",
                gridGap: "2px"
            }
        }}
    >
        {
            banners.map((item, i) => {
                const item2 = i === banners.length - 1 ? banners[0] : banners[i + 1]
                return (
                    <Paper key={item.id}
                           sx={{
                               display: 'flex',
                               justifyContent: "center",
                               boxShadow: "none",
                               gap: "16px"
                           }}>
                        <Box sx={{
                            height: '576px',
                            width: "801px",
                            borderRadius: "8px",
                            backgroundImage: `url(${item.relativeUrl})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            '@media (max-width: 850px)': {
                                width: "693px",
                            },
                        }}>
                        </Box>

                        <Box sx={{
                            height: '576px',
                            width: "801px",
                            borderRadius: "8px",
                            backgroundImage: `url(${item2.relativeUrl})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            '@media (max-width: 1500px)': {
                                display: "none"
                            },
                        }}>
                        </Box>
                    </Paper>
                )
            })
        }
    </Carousel>
}