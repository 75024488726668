import {Box, Button, Tooltip} from "@mui/material";
import {ReactComponent as HoleIcon} from "../../../../../components/Icons/addHole.svg";
import {ReactComponent as NodeIcon} from "../../../../../components/Icons/node.svg";
import {ReactComponent as ArcIcon} from "../../../../../components/Icons/arc.svg";
import {ReactComponent as RoundIcon} from "../../../../../components/Icons/round.svg";
import {ReactComponent as CutIcon} from "../../../../../components/Icons/cut.svg";
import {ReactComponent as NotchIcon} from "../../../../../components/Icons/notch.svg";
import {ReactComponent as NotchCircleIcon} from "../../../../../components/Icons/notchRound.svg";
import {ReactComponent as GrooveIcon} from "../../../../../components/Icons/groove.svg"
import {ReactComponent as QuarterIcon} from "../../../../../components/Icons/quarter.svg"
import {ReactComponent as ChamferIcon} from "../../../../../components/Icons/chamfer.svg"

const SideBar = ({detailData, processingHandle, cutPossible}) => {
    const pointsForQuarter = cutPossible.filter(el => {
        return ((el.curr.x === el.next.x || el.curr.y === el.next.y) && el.mainContour)
    })

    const pointsForCut = cutPossible.filter(point => {
        const allPointsLieLine = point.next?.x * (point.curr?.y - point.prev?.y) - point.next?.y * (point.curr?.x - point.prev?.x) === point.prev?.x * point.curr?.y - point.curr?.x * point.prev?.y
        return !allPointsLieLine
    })

    return <Box sx={{
        display: "flex",
        width: "48px",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px",
        "& button": {
            minWidth: 'auto',
            border: (theme) => `${theme.palette.primary.stroke} 1px solid`,
            padding: "4px",
            "&:hover": {
                border: (theme) => `${theme.palette.secondary.add} 1px solid`,
                backgroundColor: "white"
            }
        }
    }}>
        <Tooltip arrow placement="right" title="Добавить отверстие">
            <Button onClick={() => processingHandle("addHole", null)}>
                <HoleIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Добавить узел">
            <Button onClick={() => processingHandle("addPoint", null, detailData.detailContour.points[0].id)}>
                <NodeIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="дуга">
            <Button onClick={() => processingHandle("arc", null, detailData.detailContour.points[0].id)}>
                <ArcIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Скруглить угол">
            <Button onClick={() => processingHandle("round", null, pointsForCut[0].curr.id)}
                    sx={{opacity: `${pointsForCut.length === 0 ? 0.2 : 1}`}}
                    disabled={pointsForCut.length === 0}>
                <RoundIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Срез">
            <Button onClick={() => processingHandle("cut", null, pointsForCut[0].curr.id)}
                    sx={{opacity: `${pointsForCut.length === 0 ? 0.2 : 1}`}}
                    disabled={pointsForCut.length === 0}>
                <CutIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Внутрений контур">
            <Button onClick={() => processingHandle("notch", null)}>
                <NotchIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Внутрений контур (круг)">
            <Button onClick={() => processingHandle("notchRound", null)}>
                <NotchCircleIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Паз">
            <Button onClick={() => processingHandle("groove", null)}>
                <GrooveIcon/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Четверь">
            <Button onClick={() => processingHandle("quarter", null, pointsForQuarter[0].curr.id)}
                    sx={{
                        opacity: `${pointsForQuarter.length === 0 ? 0.2 : 1}`
                    }}
                    disabled={pointsForQuarter.length === 0}>
                <QuarterIcon/>
            </Button>
        </Tooltip>
        {/*<Tooltip arrow placement="right" title="Фаска">*/}
        {/*    <Button onClick={() => processingHandle("groove", null)}>*/}
        {/*        <ChamferIcon/>*/}
        {/*    </Button>*/}
        {/*</Tooltip>*/}
    </Box>
}
export default SideBar
