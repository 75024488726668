import React, {useMemo} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {statusesYesOrNo} from "../../../../../data/StatusesYesOrNo";
import {materialReserveState} from "../../../../../data/MaterialReserveState";

const ReservationTableCuttingItem = ({
                                         data,
                                         setData,
                                         isColumnOrder,
                                         pageSize,
                                         isCalculation
                                     }) => {

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'length',
                header: 'Ширина',
                accessorKey: 'length',
                minSize: 15,
                maxSize: 15,
            },
            {
                id: 'width',
                header: 'Высота',
                accessorKey: 'width',
                minSize: 15,
                maxSize: 15,
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'primary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </Box>
                    </>
                ),
            },
            {
                id: 'availabilityQuantity',
                header: 'Наличие',
                accessorKey: 'availabilityQuantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'success.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </Box>
                    </>
                ),

            },
            {
                id: 'materialReserveState',
                header: 'Статус',
                accessorKey: 'materialReserveState',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell}) => materialReserveState[cell.getValue()],
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ];

        if (!(isCalculation === true)) {
            baseColumns.splice(3, 0, {
                id: 'temp',
                header: 'Закупка',
                accessorKey: 'temp',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell}) => statusesYesOrNo[cell.getValue()],
            });
        }

        if (!(isCalculation === true)) {
            baseColumns.splice(5, 0, {
                id: 'needToOrderQuantity',
                header: 'Необходимо заказать',
                accessorKey: 'needToOrderQuantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'error.light',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </Box>
                    </>
                ),
            });
        }

        if (!(isCalculation === true)) {
            baseColumns.splice(6, 0, {
                id: 'orderedQuantity',
                header: 'Заказано',
                accessorKey: 'orderedQuantity',
                minSize: 15,
                maxSize: 15,
                aggregationFn: 'sum',
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </Box>
                    </>
                ),
            });
        }

        return baseColumns;

    }, [data, setData, isColumnOrder]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: pageSize},
                }}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default ReservationTableCuttingItem;