import React, {useState} from "react";
import EstimateItem from "./EstimateItem";
import {useParams} from "react-router-dom";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/material";

const OrganizationEstimate = () => {

    const params = useParams();
    const year = params.year;
    const orderNumber = params.orderNumber;
    const orderId = params.orderId;
    const orderHeaderId = params.orderHeaderId;
    const state = params.state;

    const [currentState, setCurrentState] = useState(state);
    const [isLoading, setIsLoading] = useState(true);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Смета"}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заказы", href: "/organization/orders"},
                    {
                        name: `${orderNumber}`,
                        href: `/organization/orders/${orderId}/${orderNumber}/${orderHeaderId}/${year}`
                    }
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                    <EstimateItem
                        orderId={orderId}
                        isButtonPurchase={true}
                        enableEditing={true}
                        isActual={true}
                        inWork={true}
                        orderCloud={true}
                        isImport={true}
                        detailed={false}
                        orderNumber={orderNumber}
                        orderHeaderId={orderHeaderId}
                        year={year}
                        setIsLoading
                    />
                </Box>
                {/* <ModalAlert data={alertData} onClose={handleCloseAlert}/>*/}
            </Box>
        </Box>
    )

    /*    return (
            <div>

                <Typography variant="h6" component="h6">
                    Статус: {currentState}
                </Typography>
                <EstimateItem
                    orderId={orderId}
                    isButtonPurchase={true}
                    enableEditing={true}
                    isActual={true}
                    inWork={true}
                    orderCloud={true}
                    isImport={true}
                    detailed={false}
                    orderNumber={orderNumber}
                    orderHeaderId={orderHeaderId}
                    year={year}
                />
            </div>
        );*/
};

export default OrganizationEstimate;