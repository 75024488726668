import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import TechnicalMapTable from "../../../components/UI/table/orders/organization/technicalmaptable/TechnicalMapTable";
import JobService from "../../../API/orders/job/JobService";

const TechnicalMap = () => {

    const params = useParams();
    let orderId = params.id;
    const [technicalMapData, setTechnicalMapData] = useState([]);

    useEffect(() => {
        JobService.getJobsByOrderId(orderId).then(function (response) {
            setTechnicalMapData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <h3>Техническая карта заказа: {params.order}</h3>
            <TechnicalMapTable
                data={technicalMapData}
                setData={setTechnicalMapData}
                estimateIsCurrent={true}
            />
        </div>
    );
};

export default TechnicalMap;