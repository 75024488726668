import React, {useState} from "react";
import * as THREE from "three";
import Layer from "./Layer";
import {COLORS} from "../../../../../../Constants";
import LineElement from "./LineElement";
import Cut from "./Cut";
import {withStopPropagation} from "../../../../../../utils/common/StopPropagation";

const Border = ({
                    start,
                    next,
                    prev,
                    editableElement,
                    processingHandle,
                    cutData,
                    prevArc,
                    isGroove,
                    position,
                    scale,
                    edge
                }) => {

    const [hover, setHover] = useState(null)

    const active = (edge?.id === editableElement.id && editableElement.type === "editEdge") || ((editableElement.type === "arc" || editableElement.type === "addEdge") && editableElement.id === start.id)
    const preview = (editableElement.type === "arc" || editableElement.type === "round") && editableElement.pointId === start.id && prevArc

    const calcArcPoints = (arcParams) => {
        return new THREE.EllipseCurve(arcParams?.x, arcParams?.y, arcParams?.radius, arcParams?.radius, arcParams?.startAngle, arcParams?.endAngle, arcParams?.clockwise)
            .getPoints(500)
            .map((p) => new THREE.Vector2(p.x, p.y))
    }

    const points = start.arcParams ? calcArcPoints(start?.arcParams) : [new THREE.Vector2(start.x, start.y), new THREE.Vector2(next.x, next.y)]

    const prevLine = preview && calcArcPoints(prevArc)

    return (
        <Layer layer={2}>
            <mesh
                scale={scale}
                position={position}
                onClick={withStopPropagation(() => !isGroove && (edge ? processingHandle("editEdge", edge.id) : processingHandle("addEdge", start.id)))}
                onPointerOver={() => !isGroove && setHover(true)}
                onPointerOut={() => setHover(false)}>
                {cutData && editableElement.type === "cut" && cutData?.pointId === start.id &&
                    <Cut scale={scale} data={{prev, start, next}} cutData={cutData}/>
                }
                <LineElement width={isGroove ? 2 : 5} points={points}
                             color={active || hover ? "#3B8BEC" : edge ? COLORS[edge.orderEdge.color][400] : "black"}/>
                {preview && <LineElement
                    width={4}
                    points={prevLine}
                    color={COLORS[6][800]}
                    dashSize={0.08 / scale} gapSize={0.08 / scale}/>}
            </mesh>
        </Layer>
    )
}


export {
    Border,
}
