import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link} from 'react-router-dom';
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import AttributeUtil from "../../../../../../utils/orders/AttributeUtil";
import OrderUtil from "../../../../../../utils/orders/OrderUtil";
import HeaderOPT from "../production/HeaderOPT";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip} from "@mui/material";
import TextFieldObjectWithEntityList from "../../../../textfield/TextFieldObjectWithEntityList";
import OrderDatePicker from "../../../../datepicker/OrderDatePicker";
import Box from "@mui/material/Box";
import {Edit} from "@mui/icons-material";
import WorkerService from "../../../../../../API/orders/job/WorkerService";

const OrderConstructorTable = () => {

    const [data, setData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [constructorList, setConstructorList] = useState([]);
    const [technologistList, setTechnologistList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [dateOfConstructorDocumentation, setDateOfConstructorDocumentationOfReceipt] = useState(null);
    const [dateOfTechnicalDocumentation, setDateOfTechnicalDocumentation] = useState(null);

    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [change, setChange] = useState(null);


    useEffect(() => {

        OrderService.getOrdersConstructors().then(function (response) {
            setData(response.data);
            setCustomerList(OrderUtil.getListField(response.data, 'customerName'));
            setConstructorList(OrderUtil.getListFieldObject(response.data, 'constructor'))
            setTechnologistList(OrderUtil.getListFieldObject(response.data, 'technologist'))
            setNameList(OrderUtil.getListField(response.data, 'header'));
            setAttributeList(OrderUtil.getStatuses(response.data, 'attributes'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleUpdate = (row, values) => {
        data[row[currentEditRowId]] = values;
        setData([...data]);
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setDateOfConstructorDocumentationOfReceipt(row.original.dateOfConstructorDocumentation);
            setDateOfTechnicalDocumentation(row.original.dateOfTechnicalDocumentation)
            setCurrentEditRowId(row.index);
            setUpdateModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const columns = useMemo(
        () => [
            {
                header: 'Номер заказа',
                accessorKey: 'number',
                filterFn: 'contains',
                enableEditing: false,
                maxSize: 23,
                minSize: 35,
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/technical-map/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'numberCustomer',
                header: 'Номер заказчика',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
            },
            {
                header: 'Заказчик',
                accessorKey: 'customerName',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                minSize: 15,
                maxSize: 25,
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
            },
            {
                header: 'Найменование',
                accessorKey: 'header',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                enableEditing: false,
                minSize: 15,
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
            },
            {
                id: 'model',
                header: 'Модель',
                accessorKey: 'model',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                enableEditing: false,
                minSize: 300,
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
            },
            {
                id: 'attributes',
                header: 'Атрибуты',
                accessorKey: 'attributes',
                filterVariant: 'multi-select',
                filterSelectOptions: attributeList,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkStatus(row.getValue(id), filterValue)
                },
                minSize: 20,
                enableEditing: false,
                Cell: ({cell, row}) => (
                    <li key={row.id}>
                        <Link
                            to={'/organization/orders/set-attribute/' + data[row.index].id + '/' + data[row.index].number + '/' + data[row.index].header.id}
                        >{data[row.index].attributes.length === 0 ? 'Не устанволен' : AttributeUtil.convertAttributes(cell.getValue())}</Link>
                    </li>
                ),
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,

            },
            {
                accessorFn: (row) => {
                    if (row.dateOfPlannedProduction === null) {
                    } else {
                        return new Date(row.dateOfPlannedProduction)
                    }
                },
                id: 'dateOfPlannedProduction',
                header: 'Плановая дата произодства',
                sortingFn: 'datetime',
                size: 10,
                enableEditing: false,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'Констуктор',
                accessorKey: 'constructor.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: constructorList,
                maxSize: 100,
                minSize: 100,
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfConstructorDocumentation === null) {
                    } else {
                        return new Date(row.dateOfConstructorDocumentation)
                    }
                },
                id: 'dateOfConstructorDocumentation',
                header: 'Дата тех. документации конструктор',
                sortingFn: 'datetime',
                maxSize: 35,
                minSize: 30,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'Технолог',
                accessorKey: 'technologist.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: technologistList,
                maxSize: 20,
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfTechnicalDocumentation === null) {
                    } else {
                        return new Date(row.dateOfTechnicalDocumentation)
                    }
                },
                id: 'dateOfTechnicalDocumentation',
                header: 'Дата тех. документации технолог',
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column} height={140}></HeaderOPT>,
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                enableEditing
                enableColumnFilterModes
                enableGrouping
                enableStickyHeader
                enablePinning
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                    columnPinning: {left: ['number']}
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        <Tooltip arrow placement="left" title="Редактировать">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => handleUpdateModel(row)}>
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                    </Box>
                )}
            />
            <UpdateOrderModal
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                onSubmit={handleUpdate}
                values={currentEditRowData}
                currentEditRowId={currentEditRowId}
                change={change}
                setChange={setChange}
                setValues={setCurrentEditRowData}
                dateOfConstructorDocumentation={dateOfConstructorDocumentation}
                setDateOfConstructorDocumentationOfReceipt={setDateOfConstructorDocumentationOfReceipt}
                dateOfTechnicalDocumentation={dateOfTechnicalDocumentation}
                setDateOfTechnicalDocumentation={setDateOfTechnicalDocumentation}
            />
        </>
    );
};

export const UpdateOrderModal = ({
                                     open, onClose,
                                     onSubmit,
                                     values,
                                     setValues,
                                     change,
                                     setChange,
                                     dateOfConstructorDocumentation,
                                     setDateOfConstructorDocumentationOfReceipt,
                                     dateOfTechnicalDocumentation,
                                     setDateOfTechnicalDocumentation,
                                 }) => {

    const [constructorList, setConstructorList] = useState([]);
    const [constructorId, setConstructorId] = useState(null);
    const [technologistId, setTechnologistId] = useState(null);


    useEffect(() => {

        WorkerService.getAllEnabledAndConstructor().then(function (response) {
            let data = response.data;
            setConstructorList(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleSubmit = () => {

        values.dateOfConstructorDocumentation = dateOfConstructorDocumentation === null ?
            null : DateTimeUtil.dateConvert(dateOfConstructorDocumentation);
        values.dateOfTechnicalDocumentation = dateOfTechnicalDocumentation === null ?
            null : DateTimeUtil.dateConvert(dateOfTechnicalDocumentation);

        OrderService.updateConstructor(values,
            constructorId === null ?
                (constructorList === null ? null : constructorList[0].id) : constructorId,
            technologistId === null ?
                (constructorList === null ? null : constructorList[0].id) : technologistId,
        ).then(function (response) {
            onSubmit(response.data);
            setChange(!change)
            onClose();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Редактировать заказ</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        <TextFieldObjectWithEntityList
                            label="Конструктор"
                            values={values}
                            field={'constructor'}
                            setId={setConstructorId}
                            entityList={constructorList}
                        />
                        <OrderDatePicker
                            label={'Дата тех. документации конструктор'}
                            date={dateOfConstructorDocumentation}
                            setDate={setDateOfConstructorDocumentationOfReceipt}
                        />
                        <TextFieldObjectWithEntityList
                            label="Технолог"
                            values={values}
                            field={'technologist'}
                            setId={setTechnologistId}
                            entityList={constructorList}
                        />
                        <OrderDatePicker
                            label={'Дата тех. документации технолог'}
                            date={dateOfTechnicalDocumentation}
                            setDate={setDateOfTechnicalDocumentation}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выход</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrderConstructorTable;