import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, Button, Typography} from "@mui/material";
import HoleControllerService from "../../../../API/details/HoleControllerService";
import ProcessingTable from "../../../../components/UI/table/customer-detales/processing/ProcessingTable";
import {Canvas} from "@react-three/fiber";
import {Text, PerspectiveCamera, OrbitControls} from "@react-three/drei";
import ModalProcessing from "./Modals/ModalProcessing";
import DetailCanvas from "./Canvas/DetailCanvas";
import PointControllerService from "../../../../API/details/PointController";
import ModalConfirm from "./Modals/ModalConfirm";
import Layer from "./Canvas/CanvasComponents/Layer";
import SideBar from "./SideBar/SideBar";
import DetailControllerService from "../../../../API/details/DetailControllerService";
import NotchControllerService from "../../../../API/details/NotchController";
import {calcCutPossible} from "../../../../utils/processing/calcCutPossible";
import PopoverProcessing from "./Canvas/CanvasComponents/PopoverProcessing";
import GrooveControllerService from "../../../../API/details/GrooveControllerService";
import ModalAddDetail from "../../../../components/UI/table/customer-detales/ModalAddDetail";
import * as THREE from "three";
import {calcNewScale, INIT_SCALE} from "../../../../utils/processing/calcNewScale";
import OrderEdgeController from "../../../../API/details/OrderEdgeController";
import EdgesControllerService from "../../../../API/details/EdgesControllerService";
import QuarterControllerService from "../../../../API/details/QuarterControllerService";
import {calcValueOfStringWithVar} from "./Canvas/CanvasCalcPositions/calcValueOfStringWithVar";
import {calcPrevQuarterXY} from "./Canvas/CanvasCalcPositions/calcPrevQuarterXY";
import ProcessingEdgesTable from "../../../../components/UI/table/customer-detales/processing/ProcessingEdgesTable";
import ButtonBack from "../../../../components/UI/button/ButtonBack";
import {ReactComponent as PencilIcon} from "../../../../components/Icons/pencil.svg"


const MainDimension = React.memo(({detailData, scale}) => {
    // const {detailData, scale} = useProcessingContext()

    return (
        <Layer layer={2}>
            <Text
                color={"black"}
                fontSize={.2}
                position={[0, -(detailData.width / 2 * scale) - 0.55, 0]}
                maxWidth={6}
            >
                ← {detailData.length} →
            </Text>
            <Text
                rotation={[0, 0, 20.4]}
                color={"black"}
                fontSize={.2}
                position={[(detailData.length / 2 * scale) + 0.55, 0, 0]}
                maxWidth={6}
            >
                ← {detailData.width} →
            </Text>
        </Layer>
    )
})

const DetailProcessing = ({
                              setIsError,
                              detailId,
                              setChange,
                              handleError,
                              orderId,
                              detailData,
                              redirect,
                              variables
                          }) => {
    // console.log("DetailProcessing render")
    // const {detailData, startX, startY} = useProcessingContext()
    const refCanvas = useRef()

    const [scale, setScale] = useState(INIT_SCALE);
    const [edgesData, setEdgesData] = useState([])

    useEffect(() => {
        OrderEdgeController.getAll(orderId)
            .then(function (response) {
                const data = response?.data
                setEdgesData(data);
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
    }, []);

    useEffect(() => {
        setScale(calcNewScale(refCanvas.current || {}, detailData))
        const resizeHandler = () => setScale(calcNewScale(refCanvas.current || {}, detailData));

        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler)
    }, [detailData])

    const startX = -(detailData.length / 2 * scale)
    const startY = -(detailData.width / 2 * scale)
    const corePosition = [startX, startY, 0];

    const [processingModal, setProcessingModal] = useState("")
    const [confirmModal, setConfirmModal] = useState("");
    const [popoverActives, setPopoverActives] = useState({pointId: null, x: 0, y: 0});
    const [editDetailModal, setEditDetailModal] = useState(false)

    const [editableElementId, setEditableElementId] = useState(null);
    const [activePointId, setActivePointId] = useState(null)
    const [selectedHole, setSelectedHole] = useState(null)
    const [hoveredHole, setHoveredHole] = useState(null)
    const [hoveredGroove, setHoveredGroove] = useState(null)
    const [prevArc, setPrevArc] = useState(null)
    const [prevInnerContour, setPrevInnerContour] = useState({contour: {}, type: ""})

    const cutPossible = useMemo(() => {
        const possibleArr = []

        detailData.detailContour.points.forEach((el, i, arr) => {
            const possible = calcCutPossible(el, i, arr)
            possible && possibleArr.push({...possible, mainContour: true})
        })
        detailData.notches.forEach(el => el.contour.points.forEach((el, i, arr) => {
            const possible = calcCutPossible(el, i, arr)
            possible && possibleArr.push({...possible, mainContour: false})
        }))
        return possibleArr
    }, [detailData])

    const [cutData, setCutData] = useState({
        pointId: null,
        possible: cutPossible,
        distancePrev: "",
        distanceNext: ""
    })


    const handleCloseDetailModal = (data) => {
        setEditDetailModal(false)
    }

    const calcPrevInnerContour = (enteredValues, position, round, pointForQuarter) => {

        if (pointForQuarter) {
            DetailControllerService.prevAll(orderId, detailId, enteredValues).then(function (response) {
                const calcData = response.data

                const {
                    x,
                    y,
                    horizontal
                } = calcPrevQuarterXY(pointForQuarter, calcData.distToStartPoint, calcData.width, calcData.length, detailData)

                setPrevInnerContour({
                    contour: {
                        position: position,
                        width: horizontal ? calcData.length : calcData.width,
                        length: horizontal ? calcData.width : calcData.length,
                        x: x,
                        y: y,
                        distToStartPoint: calcData.distToStartPoint,
                    },
                    type: "groove"
                })
            })
                .catch(function (error) {
                    handleError(error)
                    setPrevInnerContour({contour: {}, type: ""})
                })
        } else {
            try {
                const calcValues = Object.fromEntries(Object.entries(enteredValues).map(([name, value]) => {
                    return [name, calcValueOfStringWithVar(variables, value.toString())]
                }))
                setPrevInnerContour({contour: {position: position, ...calcValues}, type: round ? "round" : "groove"})
            } catch (error) {
                DetailControllerService.prevAll(orderId, detailId, enteredValues).then(function (response) {
                    setPrevInnerContour({
                        contour: {position: position, ...response.data},
                        type: round ? "round" : "groove"
                    })
                })
                    .catch(function (error) {
                        handleError(error)
                        setPrevInnerContour({contour: {}, type: ""})
                    })
            }
        }
    }

    const handleSubmitDetailModal = (data) => {
        DetailControllerService.update(orderId, detailData.id, data).then(function (response) {
            setChange((change) => !change)
        })
            .catch(function (error) {
                handleError(error)
            })
            .then(() => setEditDetailModal(false))
    }

    const processingHandle = (type, editId, pointId) => {
        type === "cut" && setCutData((value) => {
            return {...value, pointId: editId}
        })
        setEditableElementId(editId)
        setActivePointId(pointId)
        setProcessingModal(type)
    }

    const openConfirmModal = (type, deleteId) => {
        setConfirmModal(type)
        setEditableElementId(deleteId)
    }

    const openPopoverActives = (data) => {
        setPopoverActives(data)
        setActivePointId(data.pointId)
    }
    const closePopoverActives = () => {
        setPopoverActives({pointId: null, x: 0, y: 0})
        setActivePointId(null)
    }

    const onCloseModal = () => {
        setProcessingModal("")
        setEditableElementId(null)
        setPrevArc(null)
        setCutData(values => ({...values, distancePrev: "", distanceNext: ""}))
        setPrevInnerContour({contour: {}, type: ""})
        setActivePointId(null)
    }

    const onCloseConfirmModal = () => {
        setConfirmModal("")
    }

    const onConfirm = () => {
        if (confirmModal === "reset") {
            DetailControllerService.resetContour(orderId, detailData.id)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deletePoint") {
            PointControllerService.delete(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteHole") {
            HoleControllerService.delete(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteNotchRound") {
            NotchControllerService.deleteRound(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteGroove") {
            GrooveControllerService.delete(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteEdge") {
            EdgesControllerService.delete(orderId, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteQuarter") {
            QuarterControllerService.delete(orderId, detailId, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteNotch") {
            NotchControllerService.delete(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        }
    }

    const onSubmitHandle = (data) => {
        if (processingModal === "addPoint") {
            PointControllerService.save(orderId, detailId, data.afterPointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editPoint") {
            PointControllerService.patch(orderId, detailId, activePointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "arc") {
            PointControllerService.patchArc(orderId, detailId, data.pointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "round") {
            PointControllerService.patchRound(orderId, detailId, data.pointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "cut") {
            PointControllerService.patchCut(orderId, detailId, data.pointId, data.params).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "notch") {
            NotchControllerService.add(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editNotch") {
            NotchControllerService.put(orderId, detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "notchRound") {
            NotchControllerService.addRound(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editNotchRound") {
            NotchControllerService.putRound(orderId, detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "addHole") {
            HoleControllerService.save(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editHole") {
            HoleControllerService.put(orderId, detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "addEdge") {
            EdgesControllerService.save(orderId, {
                startPointId: editableElementId,
                orderEdgeId: data,
                edgeEnd: "STANDARD"
            }).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editEdge") {
            EdgesControllerService.edit(orderId, editableElementId, {
                orderEdgeId: data,
                edgeEnd: "STANDARD"
            }).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "groove") {
            GrooveControllerService.add(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editGroove") {
            GrooveControllerService.put(orderId, detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "quarter") {
            QuarterControllerService.add(orderId, detailId, data.pointId, data.params).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editQuarter") {
            QuarterControllerService.put(orderId, detailId, editableElementId, data.pointId, data.params).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        }
    }

    const getPrevContour = (data) => {
        if (!data.params.radius) {
            setPrevArc(null)
        } else if (processingModal === "arc") {
            PointControllerService.previewArc(orderId, detailId, data.pointId, data.params)
                .then((response) => setPrevArc(response.data))
                .catch((err) => {
                    setPrevArc(null)
                    handleError(err)
                })
        } else {
            PointControllerService.previewRound(orderId, detailId, data.pointId, data.params)
                .then((response) => setPrevArc(response.data))
                .catch((err) => {
                    setPrevArc(null)
                    handleError(err)
                })
        }
    }

    return (<Box>
        <ModalAddDetail
            open={editDetailModal}
            onClose={handleCloseDetailModal}
            title={'Редактировать деталь'}
            labelButtonSubmit={'Обновить'}
            handleSubmit={handleSubmitDetailModal}
            initValue={{
                position: detailData.position,
                name: detailData.name,
                length: detailData.length,
                height: "18",
                width: detailData.width
            }}
            uniqueNames={[]}
        />
        <ModalProcessing
            type={processingModal}
            onClose={onCloseModal}
            onSubmit={onSubmitHandle}
            edgesData={edgesData}
            detailData={detailData}
            editableElementId={editableElementId}
            activePointId={activePointId}
            setActivePointId={setActivePointId}
            openConfirmModal={openConfirmModal}
            cutData={cutData}
            setCutData={setCutData}
            calcPrevInnerContour={calcPrevInnerContour}
            getPrevContour={getPrevContour}
            refCanvas={refCanvas}
            variables={variables}
        />
        <ModalConfirm
            confirmModal={confirmModal}
            onClose={onCloseConfirmModal}
            onSubmit={onConfirm}
        />
        {popoverActives.pointId &&
            <PopoverProcessing popoverActives={popoverActives}
                               processingHandle={processingHandle}
                               openConfirmModal={openConfirmModal}
                               confirmModal={confirmModal}
                               processingModal={processingModal}
                               cutData={cutData}
                               onClose={closePopoverActives}
                               detailData={detailData}>
            </PopoverProcessing>
        }

        {detailData.id ? <Box sx={{display: "flex", flexDirection: "column", gap: "16px",}}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "16px",}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        {`Деталь ${detailData.position}`}
                    </Typography>
                    <Box sx={{display: "flex", gap: "8px"}}>
                        <Typography sx={{fontSize: "16px"}}>
                            Размер
                        </Typography>
                        <Typography sx={{fontSize: "16px"}}>
                            {`${detailData.length} мм * ${detailData.width} мм * ${detailData.height} мм`}
                        </Typography>
                        <PencilIcon stroke='#3B8BEC' onClick={() => setEditDetailModal(true)}/>
                    </Box>
                </Box>
                <Box style={{display: "flex", gap: "16px", marginBottom: 10}}>
                    <ButtonBack width={213} height={40} toStartContour={true} text={"К начальному контуру"}
                                onClick={() => openConfirmModal("reset")}>
                    </ButtonBack>
                </Box>

            </Box>

            <Box style={{display: "flex", justifyContent: "space-between", height: "calc(100vh - 410px)",}}>
                <SideBar detailData={detailData} processingHandle={processingHandle} cutPossible={cutPossible}/>
                <Box style={{
                    minWidth: "500px",
                    width: "calc(100% - 840px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <Box
                        ref={refCanvas}
                        style={{
                            minWidth: "500px",
                            height: "100%",
                            display: "flex",
                        }}>
                        <Canvas>
                            <MainDimension detailData={detailData} scale={scale}/>

                            <DetailCanvas
                                editableElement={{
                                    type: processingModal,
                                    pointId: activePointId,
                                    id: editableElementId
                                }}
                                processingHandle={processingHandle}
                                hoveredHoleId={hoveredHole}
                                setHoveredHoleId={setHoveredHole}
                                selectedHole={selectedHole}
                                setSelectedHole={setSelectedHole}
                                hoveredGrooveId={hoveredGroove}
                                setHoveredGrooveId={setHoveredGroove}
                                cutData={cutData}
                                prevInnerContour={prevInnerContour}
                                prevArc={prevArc}
                                openPopoverActives={openPopoverActives}
                                openConfirmModal={openConfirmModal}
                                position={corePosition}
                                detailData={detailData}
                                scale={scale}
                            />
                            <Layer layer={1}>
                                <arrowHelper
                                    args={[new THREE.Vector3(0, 0, 0), new THREE.Vector3(startX - 0.7, startY - 1, 0), 1, "#F87B0E", 0.2, 0.1]}
                                />
                                <arrowHelper
                                    args={[new THREE.Vector3(90, 0, 0), new THREE.Vector3(startX - 0.7, startY - 1, 0), 0.7, "#F87B0E", 0.2, 0.1]}
                                />
                                <Text
                                    color={"#F87B0E"}
                                    fontSize={.15}
                                    position={[startX + 0.1, startY - 0.994, 0]}>X
                                </Text>
                                <Text
                                    color={"#F87B0E"}
                                    fontSize={.15}
                                    position={[startX - 0.7, startY + 0.2, 0]}>Y
                                </Text>
                            </Layer>
                            <ambientLight intensity={Math.PI / 2}/>
                            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0}
                                       intensity={Math.PI}/>
                            <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI}/>
                        </Canvas>
                    </Box>

                </Box>
                <Box sx={{display: "flex", flexDirection: "column", gap: "24px", maxWidth: "800px"}}>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                        <Typography sx={{fontWeight: 700}}>Кромки</Typography>
                        <ProcessingEdgesTable
                            edgesData={edgesData}
                            edges={detailData.edges}>
                        </ProcessingEdgesTable>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "16px"}}>
                        <Typography sx={{fontWeight: 700}}>Отверстия</Typography>
                        <ProcessingTable
                            data={detailData.holes}
                            processingHandle={processingHandle}
                            openConfirmModal={openConfirmModal}
                            hoveredHole={hoveredHole}
                            setHoveredHole={setHoveredHole}/>
                    </Box>
                </Box>
            </Box>
        </Box> : <p style={{color: "red"}}> the detail does not exist </p>}
    </Box>)
}
export default DetailProcessing
