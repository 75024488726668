import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Delete, Edit} from "@mui/icons-material";
import Box from "@mui/material/Box";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import ButtonSettingsOrders from "../../../button/ButtonSettingsOrders";
import UserService from "../../../../../API/common/UserService";
import TextFieldWithSetValue from "../../../textfield/TextFieldWithSetValue";
import OrderDatePicker from "../../../datepicker/OrderDatePicker";
import UserUtil from "../../../../../utils/common/UserUtil";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import CellCheckboxUTUnable from "./CellCheckboxUTUnable";
import {Link} from "react-router-dom";

const UserTable = () => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [change, setChange] = useState(null);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentBirthday, setCurrentBirthday] = useState(null);
    const [currentAttributes, setCurrentAttributes] = useState(null);


    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
        setChange(!change);
    };

    useEffect(() => {

        UserService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleUpdateModel = useCallback(
        (row) => {
            const data = row.original
            setCurrentEditRowData(data);
            setCurrentBirthday(data === null ? null : (data.attributes === null ? null : data.attributes.birthday))
            setCurrentAttributes({
                "middleName": data === null ? null : (data.attributes === null ? null : data.attributes.middleName),
                "phoneNumber": data === null ? null : (data.attributes === null ? null : data.attributes.phoneNumber),
            })
            setUpdateModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleDeleteRow = useCallback(
        (row) => {
            UserService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const columns = useMemo(
        () => [
            {
                id: 'lastName',
                header: 'Фамилия',
                accessorKey: 'lastName',
                minSize: 25,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/users/' + data[row.index].id + '/' + data[row.index].username}>{cell.getValue()}</Link>
                    </li>
                ),
            },

            {
                id: 'firstName',
                header: 'Имя',
                accessorKey: 'firstName',
                minSize: 25,
            },
            {
                id: 'username',
                header: 'Login',
                accessorKey: 'username',
                minSize: 25,
            },
            {
                id: 'middleName',
                header: 'Отчество',
                accessorKey: 'attributes.middleName',
                minSize: 25,
            },
            {
                id: 'phoneNumber',
                header: 'Номер телефона',
                accessorKey: 'attributes.phoneNumber',
                minSize: 25,
            },
            {
                id: 'enabled',
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxUTUnable
                    cell={cell}
                    values={data[row.index]}
                    change={change}
                    setChange={setChange}
                >
                </CellCheckboxUTUnable>,
            },
            {
                id: 'email',
                header: 'Email',
                accessorKey: 'email',
                minSize: 50
            },
            {
                accessorFn: (row) => {
                    if (row.attributes === undefined || row.attributes.birthday === undefined || row.attributes.birthday === null) {
                    } else {
                        return new Date(row.attributes.birthday)
                    }
                },
                id: 'birthday',
                header: 'День рождения',
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'id',
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="left" title="Редактировать">
                            <IconButton onClick={() => handleUpdateModel(row)}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Удалить">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    sorting: [{id: 'lastName', desc: false}],
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            color="secondary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                        >
                            Добавить пользователя
                        </Button>
                        <ButtonSettingsOrders/>
                    </Box>
                )}
            />
            <CreateNewUserModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                change={change}
                setChange={setChange}
            />
            <UpdateUserModal
                open={updateModalOpen}
                values={currentEditRowData}
                setValues={setCurrentEditRowData}
                onClose={() => setUpdateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                currentBirthday={currentBirthday}
                setCurrentBirthday={setCurrentBirthday}
                currentAttributes={currentAttributes}
                setCurrentAttributes={setCurrentAttributes}
            />
        </>
    );
};

export const CreateNewUserModal = ({open, columns, onClose, onSubmit, change, setChange}) => {

    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            if (UserUtil.checkField(column)) {
                acc[column.id ?? ''] = '';
            }
            return acc;
        }, {}),
    );

    const [birthday, setBirthday] = useState(null);
    const [attributes, setAttributes] = useState({"middleName": null, "birthday": null, "phoneNumber": null});


    useEffect(() => {
        values.attributes = attributes
    }, []);

    const handleSubmit = () => {
        attributes.birthday = DateTimeUtil.dateConvert(birthday);
        setAttributes(attributes)
        values.attributes = attributes
        setValues(values)
        values.enabled = true;
        UserService.save(values,
        ).then(function (response) {
            onSubmit(response.data);
            setChange(!change)
            exit()
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const exit = () => {
        values.firstName = null;
        values.email = null;
        values.lastName = null;
        values.username = null;
        values.attributes.birthday = null;
        values.attributes.middleName = null;
        values.attributes.phoneNumber = null;
        setValues(values);
        attributes.birthday = null;
        attributes.middleName = null;
        attributes.phoneNumber = null;
        setAttributes(attributes);
        setBirthday(null);
        onClose()
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Создать пользователя</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack spacing={2}>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextFieldWithSetValue
                            label="Фамилия"
                            values={values}
                            setValues={setValues}
                            field={'lastName'}
                        />
                        <TextFieldWithSetValue
                            label="Имя"
                            values={values}
                            setValues={setValues}
                            field={'firstName'}
                        />
                        <TextFieldWithSetValue
                            label="Отчество"
                            values={attributes}
                            setValues={setAttributes}
                            field={'middleName'}
                        />
                        <TextFieldWithSetValue
                            label="Номер телефона"
                            values={attributes}
                            setValues={setAttributes}
                            field={'phoneNumber'}
                        />
                        <TextFieldWithSetValue
                            label="Login"
                            values={values}
                            setValues={setValues}
                            field={'username'}
                        />
                        <TextFieldWithSetValue
                            label="Email"
                            values={values}
                            setValues={setValues}
                            field={'email'}
                        />
                        <OrderDatePicker
                            label="Дата рождения"
                            date={birthday}
                            setDate={setBirthday}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти и запомнить</Button>
                <Button onClick={exit}>Выход</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Создать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const UpdateUserModal = ({
                                    open,
                                    values,
                                    setValues,
                                    onClose,
                                    onSubmit,
                                    currentBirthday,
                                    setCurrentBirthday,
                                    currentAttributes, setCurrentAttributes
                                }) => {


    const handleSubmit = () => {
        values.attributes.birthday = currentBirthday;
        values.attributes.middleName = currentAttributes.middleName;
        values.attributes.phoneNumber = currentAttributes.phoneNumber

        UserService.update(values,
        ).then(function (response) {
            onSubmit(response.data);
            onClose();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const exit = () => {
        onClose()
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Создать пользователя</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack spacing={2}>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextFieldWithSetValue
                            label="Фамилия"
                            values={values}
                            setValues={setValues}
                            field={'lastName'}
                        />
                        <TextFieldWithSetValue
                            label="Имя"
                            values={values}
                            setValues={setValues}
                            field={'firstName'}
                        />
                        <TextFieldWithSetValue
                            label="Отчество"
                            values={currentAttributes}
                            setValues={setCurrentAttributes}
                            field={'middleName'}
                        />
                        <TextFieldWithSetValue
                            label="Номер телефона"
                            values={currentAttributes}
                            setValues={setCurrentAttributes}
                            field={'phoneNumber'}
                        />
                        <TextFieldWithSetValue
                            label="Login"
                            values={values}
                            setValues={setValues}
                            field={'username'}
                            disabled={true}
                        />
                        <TextFieldWithSetValue
                            label="Email"
                            values={values}
                            setValues={setValues}
                            field={'email'}
                        />

                        <OrderDatePicker
                            label="Дата рождения"
                            date={currentBirthday}
                            setDate={setCurrentBirthday}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={exit}>Выход</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default UserTable;