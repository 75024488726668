import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const orderRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/orders'

export default class OrderService {

    static async getOrders() {
        return axios.get(orderRestUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getOrdersByCloud() {
        return axios.get(orderRestUrl + '/by-cloud', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getOrdersByEconomic() {
        return axios.get(orderRestUrl + '/by-economic', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getOrdersForWriteOff() {
        return axios.get(orderRestUrl + "/for-write-off", {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getOrdersArchive() {
        return axios.get(orderRestUrl + '/archive', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getOrdersProduction() {
        return axios.get(orderRestUrl + '/production', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getOrdersProductionByPlanId(planId) {
        return axios.get(orderRestUrl + '/production/plan' + "?planId=" + planId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getOrdersConstructors() {
        return axios.get(orderRestUrl + '/constructors', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data, customerId, customerName, modelHeaderId, attributeId) {
        return await axios.post(orderRestUrl + "?customerId=" + customerId + '&modelHeaderId=' + modelHeaderId +
            '&attributeId=' + attributeId + '&customerName=' + customerName, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, modelHeaderId) {
        return await axios.patch(orderRestUrl + '/' + data.id
            + '?modelHeaderId=' + modelHeaderId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async sendToArchive(id) {
        return await axios.patch(orderRestUrl + '/' + id + '/send-to-archive',
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async updateConstructor(data, constructorId, technologistId) {
        return await axios.post(orderRestUrl + '/' + data.id + '/constructor'
            + "?constructorId=" + constructorId
            + '&technologistId=' + technologistId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async setModelById(idOrder, idModel) {
        return await axios.post(orderRestUrl + '/' + idOrder + '/set-model-by-id'
            + "?idModel=" + idModel,
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async deleteModelById(idOrder) {
        return await axios.post(orderRestUrl + '/' + idOrder + '/delete-model-by-id',
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async unableAttributeByOrderId(id, attributeId, status) {
        return axios.patch(orderRestUrl + '/' + id + '/unable-attribute' + '?attributeId=' + attributeId + '&status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setPlanByIds(ids, planId) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        await axios.patch(orderRestUrl + '/set-plan-by-ids'
            + '?planId=' + planId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async getAllIssuingEdges() {
        return axios.get(orderRestUrl + '/issuing-edges', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByIssuingEdgesId(issuingEdgesId) {
        return axios.get(orderRestUrl + '/issuing-edges/' + issuingEdgesId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addModel(orderId, modelId) {
        return await axios.post(orderRestUrl + '/' + orderId + '/add-model'
            + "?modelId=" + modelId,
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}