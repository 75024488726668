import React from 'react';
import {TextField} from "@mui/material";
import {materialCut} from "../../../../../../data/MaterialCutDefault";

const TextFieldObjectWithSupplierMaterialList = ({
                                                     label,
                                                     values,
                                                     setId,
                                                     field,
                                                     entityList,
                                                     disabled,
                                                     name,
                                                     nameValue,
                                                     isValueObject,
                                                 }) => {

    const currentName = name ? name : 'name';
    const currentNameValue = nameValue ? nameValue : 'id';

    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                defaultValue={values === null ? entityList[0].id : (values[field] === null ? null : values[field].id)}
                name={field}
                onChange={(e) =>
                    setId(e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {entityList && entityList.map((entity) => {
                    const value = isValueObject ? entity.valueOf() : entity[currentNameValue];
                    const label = `${materialCut[entity[currentName]]} [${entity.length}x`;
                    const heightInfo = entity.typeSupplierMaterial === "BY_MIN_STRIPE"
                        ? `?], высота не более ${2070 - entity.width}мм`
                        : `${entity.width}]`;

                    return (
                        <option key={entity.id} value={value}>
                            {label + heightInfo}
                        </option>
                    );
                })}
            </TextField>
        </div>
    );
};

export default TextFieldObjectWithSupplierMaterialList;