import React, {useEffect, useState} from 'react';
import {Button, Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import PanoramaWideAngleIcon from '@mui/icons-material/PanoramaWideAngle';
import {calcDistance} from "../../../../../../utils/processing/calcDistance";
import {createPointArray} from "../../../../../../utils/processing/createPointArray";
import {calcValueOfStringWithVar} from "../../Canvas/CanvasCalcPositions/calcValueOfStringWithVar";
import {noExponents} from "../../../../../../utils/processing/noExponents";
import {hasOnlyDigitsAndSymbols} from "../../../../../../utils/processing/hasOnlyDigits";
import ButtonExit from "../../../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../../../components/UI/button/ButtonAdd";

const direction = [
    {value: true, label: "по часовой стрелке"},
    {
        value: false, label: "против часовой стрелки"
    }]

const ArcModalContent = ({
                             type,
                             onClose,
                             onSubmit,
                             detailData,
                             cutData,
                             editableElementId,
                             activePointId,
                             setActivePointId,
                             getPrevContour,
                             variables
                         }) => {

    const pointArray = type === "round"
        ? cutData.possible.filter(point => {
            const allPointsLieLine = point.next?.x * (point.curr?.y - point.prev?.y) - point.next?.y * (point.curr?.x - point.prev?.x) === point.prev?.x * point.curr?.y - point.curr?.x * point.prev?.y
            return !allPointsLieLine
        }).reduce((acc, el) => [...acc, el.curr], [])
        : createPointArray(detailData)

    const currEl = editableElementId ? pointArray.find(el => el.id === activePointId) : pointArray[0]

    const initFormState = {
        "radius": currEl?.arcParams ? currEl?.variables.find(el => el.name === "RADIUS")?.pattern || currEl?.arcParams?.radius : "",
        "clockwise": currEl?.arcParams ? currEl?.arcParams?.clockwise : true,
        "pointId": currEl.id
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "radius": ""
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}

        const pointCoordinate = pointArray.find((el) => el.id === formState.pointId)
        const nextPointCoordinate = pointArray.find((el, i, arr) => arr[i - 1]?.id === formState.pointId || arr[0])
        const d = calcDistance(pointCoordinate, nextPointCoordinate)
        const rMin = Math.floor(d / 2)

        Object.entries(formState).forEach(([name, value]) => {
            if (type === "arc" && name == "radius" && hasOnlyDigitsAndSymbols(value)) {
                try {
                    const calcRadius = calcValueOfStringWithVar(variables, value)
                    if (calcRadius < rMin) newErrors.radius = `от ${rMin}`
                } catch (e) {
                    console.log(e)
                }
            }
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                pointId: formState.pointId,
                point: {
                    "radius": formState.radius.toString(),
                    "clockwise": formState.clockwise
                }
            })
        }
    }

    const inputHandler = (getData) => {
        const data = getData()
        const name = Object.keys(data)[0]
        const newState = {...formState}

        if (name === "pointId") {
            const currPoint = pointArray.find(el => el.id === data.pointId)

            newState.pointId = data.pointId
            newState.radius = currPoint.arcParams?.radius || ""
            newState.clockwise = currPoint.arcParams ? currPoint.arcParams?.clockwise : true
            setActivePointId(data.pointId)
        } else if (name === "radius") {
            newState.radius = data.radius

        } else {
            newState.clockwise = data.clockwise
        }

        setFormState(newState)

        getPrevContour({
            pointId: newState.pointId, params: {
                "radius": newState.radius,
                "clockwise": newState.clockwise
            }
        })
    }

    return (<>
        <DialogTitle
            textAlign="center">{type === "round" ? "Скруглить угол" : currEl?.arcParams ? "Изменить дугу" : "Создать дугу"} < /DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                {!editableElementId && <TextFieldValidation
                    select
                    name="pointId"
                    label="Узел"
                    value={formState.pointId}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {pointArray.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            x= {+option.x.toFixed(2)}, y= {+option.y.toFixed(2)}
                        </MenuItem>))}
                </TextFieldValidation>}
                <TextFieldValidation
                    name={"radius"}
                    value={formState?.radius}
                    label="Радиус"
                    setCurrValues={inputHandler}
                    validationError={validationState?.radius}
                    setValidationState={setValidationState}
                    required={false}
                    adornment={type !== "round" && currEl?.arcParams && +parseFloat(noExponents(currEl?.arcParams?.radius)).toFixed(2)}
                />
                <TextFieldValidation
                    select
                    required={false}
                    name={"clockwise"}
                    value={formState?.clockwise}
                    label="Hаправление"
                    setCurrValues={inputHandler}
                    validationError={validationState?.clockwise}
                    setValidationState={setValidationState}
                > <MenuItem value={"true"}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <p style={{display: "inline-block", width: 200, margin: 0}}>Выпуклость</p>
                        <PanoramaWideAngleIcon sx={{fontSize: 23}}/>
                    </div>
                </MenuItem>
                    <MenuItem value={"false"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <p style={{display: "inline-block", width: 200, margin: 0}}>Вогнутость</p>
                            <PanoramaHorizontalIcon sx={{fontSize: 23}}/>
                        </div>
                    </MenuItem>
                </TextFieldValidation>
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Отменить"} onClick={onClose}/>
            <ButtonAdd text={"Выбрать"}  onClick={handleValidationData}/>
        </DialogActions>
    </>)
}
export default ArcModalContent
