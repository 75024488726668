export const calcSidePos = (side, detailData, scale) => {
    const thickness = 0.2
    const indent = 0.3

    let xPos
    let yPos

    const x = side === 'BACK' || side === 'FRONT' ? detailData.length * scale : thickness
    const y = side === 'LEFT' || side === 'RIGHT' ? detailData.width * scale : thickness

    if (side === 'LEFT') {
        xPos = -indent
    } else if (side === 'RIGHT') {
        xPos = detailData.length * scale + indent
    } else {
        xPos = detailData.length / 2 * scale
    }

    if (side === 'FRONT') {
        yPos = -indent
    } else if (side === 'BACK') {
        yPos = detailData.width * scale + indent
    } else {
        yPos = detailData.width / 2 * scale
    }

    return {xPos, yPos, x, y}
}