import React, {useMemo, useState} from "react";
import {Box, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import ModalAlert from "../ModalAlert";
import {COLORS, constantsErrorMessages} from "../../../../../Constants";
import {gluing} from "../../../../../data/Gluing";
import CellShortenedName from "../../orders/organization/estimate/CellShortenedName";

const ProcessingEdgesTable = ({
                                  edgesData,
                                  edges,
                              }) => {


    const usedEdgesIds = new Set(edges.map((el) => el.orderEdge.id))
    const usedEdges = edgesData.filter(el => usedEdgesIds.has(el.id))

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Цвет', accessorKey: 'color', size: 1, grow: false,
                Cell: ({cell}) => <Box style={{
                    width: "18px",
                    height: "18px",
                    borderRadius: "1px",
                    backgroundColor: COLORS[cell.getValue()][400],

                }}/>
            },
            {
                header: 'Артикул', accessorKey: 'materialArticleNumber', size: 150,
                Cell: ({cell}) => <CellShortenedName
                    value={cell.getValue()}
                    numberOfCharacters={20}
                />,
            },
            {
                header: 'Найменование', accessorKey: 'materialName', minSize: 380,
            },
            {
                header: 'Тип поклейки', accessorKey: 'edgeType', size: 120,
                Cell: ({cell}) => gluing[cell.getValue()],
            },
        ];

        return baseColumns;

    }, [usedEdges]);


    return <>
        <MaterialReactTable
            // muiTablePaperProps={{
            //     sx: {
            //         boxShadow: "none"
            //     }
            // }}

            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",

                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem",
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    hover: false,
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}
            columns={columns}
            data={usedEdges}
            rowHeight={13}
            columnSpacing={1}
            initialState={{
                density: "compact",
            }}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}

            localization={MRT_Localization_RU}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            enableColumnActions={false}
            enableSorting={false}
        />
    </>

}
export default ProcessingEdgesTable