import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import ModalCreateIssuingEdge from "./ModalCreateIssuingEdge";
import {Delete, Edit} from "@mui/icons-material";
import ModalUpdateIssuingEdge from "./ModalDeleteOrdersInIssuingEdge";
import IssuingEdgeService from "../../../../../API/orders/prodaction.issueedge/issueedge/IssuingEdgeService";

const IssuingEdgesTable = ({data, setData}) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addOrDelete, setAddOrDelete] = useState(null);

    const [titleModal, setTitleModal] = useState();

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const [change, setChange] = useState(false);

    const createIssuingEdgeOpen = (ids) => {
        IssuingEdgeService.createByOrderIds(ids).then(function (response) {
            handleCreateNewRow(response.data);
            setChange(!change)
            closeCreateModal();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const deleteOrdersInIssuingEdge = (ids) => {
        IssuingEdgeService.deleteOrderByIds(currentEditRowId, ids).then(function (response) {
            handleUpdate(response.data);
            closeUpdateModal();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleUpdate = (values) => {
        data[currentRowIndex] = values;
        setData([...data]);
    };

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: 'Номер',
                accessorKey: 'number',
                minSize: 8,
                maxSize: 8,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/issuing-edges/' + row.original.id}>
                            {cell.getValue() === null ? '' : cell.getValue()}
                        </Link>
                    </li>
                ),
            },
            {
                accessorFn: (row) => {
                    if (row.date === null) {
                    } else {
                        return new Date(row.date)
                    }
                },
                id: 'date',
                header: 'Дата поспупления',
                sortingFn: 'date',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'orders',
                header: 'Заказы',
                accessorKey: 'orders',
                minSize: 1200,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
                maxSize: 2,
            },
        ];

        return baseColumns;
    }, [data, setData]);

    const closeCreateModal = () => {
        setCreateModalOpen(false)
    };

    const closeUpdateModal = () => {
        setCurrentEditRowData(null);
        setCurrentEditRowId(null);
        setUpdateModalOpen(false)
    };

    const createIssuingEdgesTable = () => {
        setTitleModal("Создать выдачу материалов")
        setCreateModalOpen(true)
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setTitleModal("Удалить заказы")
            setCurrentRowIndex(row.index)
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.original.id);
            setUpdateModalOpen(true)
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleDeleteRow = useCallback(
        (row) => {
            IssuingEdgeService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                localization={MRT_Localization_RU}
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        <Tooltip arrow placement="left" title="Удалить заказы">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => handleUpdateModel(row)}>
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Удалить">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={({table}) => {

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Button
                                    size="small"
                                    color="success"
                                    onClick={() => createIssuingEdgesTable()}
                                    variant="contained"
                                >
                                    Создать выдачу материалов
                                </Button>
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalCreateIssuingEdge
                open={createModalOpen}
                onClose={closeCreateModal}
                titleModal={titleModal}
                createIssuingEdgeOpen={createIssuingEdgeOpen}
                change={change}
                setChange={setChange}
            />
            <ModalUpdateIssuingEdge
                open={updateModalOpen}
                onClose={closeUpdateModal}
                setAddOrDelete={setAddOrDelete}
                issuingEdgesId={currentEditRowId}
                titleModal={titleModal}
                deleteOrdersInIssuingEdge={deleteOrdersInIssuingEdge}
            />
        </div>
    );
};

export default IssuingEdgesTable;