import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import ButtonExit from "../../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../../components/UI/button/ButtonAdd";
import React from "react";

const ModalConfirm = ({confirmModal, onClose, onSubmit}) => {
    return (
        <Dialog open={!!confirmModal}>
            <DialogTitle textAlign="center">
                {confirmModal === "reset" ? "Вернутся к начальному контуру?" : "Вы уверены, что хотите удалить?"}
            </DialogTitle>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Да"} onClick={onSubmit}/>
            </DialogActions>
        </Dialog>
    )
}
export default ModalConfirm
