import React, {useEffect, useState} from 'react';
import {Button, Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {hasOnlyDigits, hasOnlyDigitsAndSymbols} from "../../../../../../utils/processing/hasOnlyDigits";
import {SIDE} from "../../../../../../Constants";
import {calcValueOfStringWithVar} from "../../Canvas/CanvasCalcPositions/calcValueOfStringWithVar";
import {noExponents} from "../../../../../../utils/processing/noExponents";
import ButtonExit from "../../../../../../components/UI/button/ButtonExit";
import ButtonAdd from "../../../../../../components/UI/button/ButtonAdd";

const HoleModal = ({
                              onClose,
                              onSubmit,
                              detailData,
                              editableElementId,
                              openConfirmModal,
                              variables,
                              calcPrevInnerContour
                          }) => {
    const initData = detailData.holes.find(el => el.id === editableElementId)

    const initFormState = editableElementId ? {
        "position": initData.contour.position,
        "radius": initData?.variables.find(el => el.name === "RADIUS")?.pattern || initData.radius,
        "depth": initData?.variables.find(el => el.name === "DEPTH")?.pattern || initData.depth,
        "x": initData.center.variables.find(el => el.name === "x")?.pattern || initData.center.x,
        "y": initData.center.variables.find(el => el.name === "y")?.pattern || initData.center.y,
        "z": initData.center.variables.find(el => el.name === "z")?.pattern || initData.center.z,
    } : {
        "position": SIDE[0].value,
        "radius": "",
        "depth": "",
        "x": "",
        "y": "",
        "z": "0",
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "position": "",
        "radius": "",
        "depth": "",
        "x": "",
        "y": "",
        "z": "",
    });

    const handleValidationData = () => {
        const newErrors = {...validationState}
        const calcValues = Object.fromEntries(Object.entries(formState).map(([name, value]) => {
            return name === "position" || !hasOnlyDigitsAndSymbols(value) ? [name, value] : [name, calcValueOfStringWithVar(variables, value.toString())]
        }))

        Object.entries(calcValues).forEach(([name, value]) => {
            if (value.length && !hasOnlyDigits(value)) return

            const maxX = !newErrors.radius && calcValues.radius ? detailData.length - calcValues.radius : detailData.length - 1
            const minX = !newErrors.radius && calcValues.radius ? calcValues.radius : 1
            const maxY = !newErrors.radius && calcValues.radius ? calcValues.width - calcValues.radius : detailData.width - 1
            const minY = !newErrors.radius && calcValues.radius ? calcValues.radius : 1
            const minZ = !newErrors.radius && calcValues.radius ? calcValues.radius : 1
            const maxZ = !newErrors.radius && calcValues.radius ? detailData.height - calcValues.radius : detailData.height - 1

            if (calcValues.position === "TOP" || calcValues.position === "BOTTOM") {
                const maxRadius = detailData.width > detailData.length
                    ? detailData.length / 2 < 30 ? detailData.length / 2 : 30
                    : detailData.width / 2 < 30 ? detailData.width / 2 : 30
                if (name === "radius" && (Number(value) > maxRadius || Number(value) < 1)) {
                    newErrors.radius = `от 1 до ${maxRadius}`
                } else if (name === "depth" && (Number(value) > detailData.height || Number(value) < 0.1)) {
                    newErrors.depth = `от 0.1 до ${detailData.height}`
                } else if (name === "x" && (Number(value) > maxX || Number(value) < minX)) {
                    newErrors.x = `от ${minX} до ${maxX}`
                } else if (name === "y" && (Number(value) > maxY || Number(value) < minY)) {
                    newErrors.y = `от ${minY} до ${maxY}`
                } else if (name === "z" && (Number(value) > detailData.height || Number(value) > calcValues.depth || Number(value) < 0)) {
                    newErrors.z = `от 0 до ${!newErrors.depth && calcValues.depth ? calcValues.depth : detailData.height}`
                }
            } else if (calcValues.position === "LEFT" || calcValues.position === "RIGHT") {
                if (name === "radius" && (Number(value) > detailData.height / 2 || Number(value) < 1)) {
                    newErrors.radius = `от 1 до ${detailData.height / 2}`
                } else if (name === "depth" && (Number(value) > 20 || Number(value) < 0.1)) {
                    newErrors.depth = `от  0.1 до 20`
                } else if (name === "x" && (Number(value) < 0 || Number(value) > detailData.length)) {
                    newErrors.x = `от 0 до ${detailData.length}`
                } else if (name === "y" && (Number(value) > maxY || Number(value) < minY)) {
                    newErrors.y = `от ${minY} до ${maxY}`
                } else if (name === "z" && (Number(value) > maxZ || Number(value) < minZ)) {
                    newErrors.z = `от ${minZ} до ${maxZ}`
                }
            } else {
                if (name === "radius" && (Number(value) > detailData.height / 2 || Number(value) < 1)) {
                    newErrors.radius = `от 1 до ${detailData.height / 2}`
                } else if (name === "depth" && (Number(value) > 20 || Number(value) < 0.1)) {
                    newErrors.depth = `от 0.1 до 20}`
                } else if (name === "x" && (Number(value) > maxX || Number(value) < minX)) {
                    newErrors.x = `от ${minX} до ${maxX}`
                } else if (name === "y" && (Number(value) < 0 || Number(value) > detailData.width)) {
                    newErrors.y = `от 0 до ${detailData.width}`
                } else if (name === "z" && (Number(value) > maxZ || Number(value) < minZ)) {
                    newErrors.z = `от ${minZ} до ${maxZ}`
                }
            }

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                "radius": formState.radius,
                "center": {
                    "x": formState.x,
                    "y": formState.y,
                    "z": formState.z,
                },
                "depth": formState.depth,
                "position": formState.position
            })
        }
    }
    const inputHandler = (getData) => {
        const data = getData()
        const currField = Object.keys(data)[0]

        let xData = currField === "x" ? data.x : formState.x,
            yData = currField === "y" ? data.y : formState.y,
            zData = currField === "z" ? data.z : formState.z

        if (["TOP", "BOTTOM"].includes(data.position)) {
            xData = ""
            yData = ""
            zData = 0
        } else if (data.position === "LEFT") {
            yData = ""
            zData = ""
            xData = 0
        } else if (data.position === "RIGHT") {
            yData = ""
            zData = ""
            xData = detailData.length
        } else if (data.position === "FRONT") {
            xData = ""
            zData = ""
            yData = 0
        } else if (data.position === "BACK") {
            xData = ""
            zData = ""
            yData = detailData.width
        }

        const currState = {...formState, ...data, x: xData, y: yData, z: zData}
        setFormState(currState)

        if ([currState.x, currState.y, currState.radius].every(str => str.toString().trim() !== '')) {
            calcPrevInnerContour({
                "radius": currState.radius,
                "x": currState.x,
                "y": currState.y,
                "z": currState.z,
            }, formState.position, true)
        }
    }

    return (
        <>
            <DialogTitle
                textAlign="center">{editableElementId ? "Изменить отверстие" : "Добавить отверстие"}</DialogTitle>
            <DialogContent style={{paddingBottom: 7}}>
                <FormControl sx={{minWidth: 350}}>

                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Box sx={{
                            width: 170,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}>
                            <TextFieldValidation
                                select
                                name="position"
                                label="Позиция"
                                value={formState.position}
                                setCurrValues={inputHandler}
                                setValidationState={setValidationState}
                            >
                                {SIDE.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextFieldValidation>
                            <TextFieldValidation
                                name={"radius"}
                                value={formState?.radius}
                                label="Радиус"
                                setCurrValues={inputHandler}
                                validationError={validationState?.radius}
                                setValidationState={setValidationState}
                                adornment={editableElementId && +parseFloat(noExponents(initData.radius)).toFixed(2)}
                            />
                            <TextFieldValidation
                                name={"depth"}
                                value={formState?.depth}
                                label="Глубина"
                                setCurrValues={inputHandler}
                                validationError={validationState?.depth}
                                setValidationState={setValidationState}
                                adornment={editableElementId && +parseFloat(noExponents(initData.depth)).toFixed(2)}
                            />

                        </Box>
                        <Box sx={{width: 170}}>
                            <TextFieldValidation
                                label="x"
                                name={"x"}
                                value={formState?.x}
                                setCurrValues={inputHandler}
                                validationError={validationState?.x}
                                setValidationState={setValidationState}
                                disabled={!!["LEFT", "RIGHT"].includes(formState.position)}
                                adornment={editableElementId && +parseFloat(noExponents(initData?.center.x)).toFixed(2)}
                            />
                            <TextFieldValidation
                                label="y"
                                name={"y"}
                                value={formState?.y}
                                setCurrValues={inputHandler}
                                validationError={validationState?.y}
                                setValidationState={setValidationState}
                                disabled={!!["FRONT", "BACK"].includes(formState.position)}
                                adornment={editableElementId && +parseFloat(noExponents(initData?.center.y)).toFixed(2)}
                            />
                            <TextFieldValidation
                                label="z"
                                name={"z"}
                                value={formState?.z}
                                setCurrValues={inputHandler}
                                validationError={validationState?.z}
                                setValidationState={setValidationState}
                                disabled={!!["TOP", "BOTTOM"].includes(formState.position)}
                                adornment={
                                    `${formState.position === "RIGHT" ? "🡸"
                                        : formState.position === "LEFT" ? "🡺"
                                            : formState.position === "FRONT" ? "🡹"
                                                : formState.position === "BACK" ? "🡻"
                                                    : ""} ${editableElementId ? +parseFloat(noExponents(initData?.center.z)).toFixed(2) : ""}`}/>
                        </Box>
                    </Box>
                    {editableElementId && <Button
                        size="small"
                        color="secondary"
                        onClick={() => openConfirmModal("deleteHole", editableElementId)}
                        variant="outlined"
                    >
                        Удалить отверстие
                    </Button>}
                </FormControl>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={onClose}/>
                <ButtonAdd text={"Выбрать"}  onClick={handleValidationData}/>
            </DialogActions>
        </>
    )
}
export default HoleModal
