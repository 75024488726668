import {Button, Popover, Tooltip, Typography} from "@mui/material";
import {ReactComponent as ArcIcon} from "../../../../../../components/Icons/arc.svg";
import {ReactComponent as RoundIcon} from "../../../../../../components/Icons/round.svg";
import {ReactComponent as CutIcon} from "../../../../../../components/Icons/cut.svg";
import {ReactComponent as QuarterIcon} from "../../../../../../components/Icons/quarter.svg"
import {ReactComponent as CircuitIcon} from "../../../../../../components/Icons/circuit.svg"
import {ReactComponent as TrashIcon} from "../../../../../../components/Icons/trash.svg"
import {ReactComponent as PencilIcon} from "../../../../../../components/Icons/pencil.svg"

const PopoverProcessing = ({
                               popoverActives,
                               onClose,
                               processingHandle,
                               confirmModal,
                               processingModal,
                               openConfirmModal,
                               cutData,
                               detailData
                           }) => {
    const open = Boolean(popoverActives.pointId) && !confirmModal && !processingModal;

    const cutPossible = cutData.possible.find(point => {
        const allPointsLieLine = point.next?.x * (point.curr?.y - point.prev?.y) - point.next?.y * (point.curr?.x - point.prev?.x) === point.prev?.x * point.curr?.y - point.curr?.x * point.prev?.y
        return point.curr.id === popoverActives.pointId && !allPointsLieLine
    })?.curr?.id

    const quarterPossible = cutData.possible.find(point => {
        return point.curr.id === popoverActives.pointId && ((point.curr.x === point.next.x || point.curr.y === point.next.y) && point.mainContour)
    })?.curr?.id

    const contour = detailData.notches.find(contour => contour.contour.points.find(point => point.id === popoverActives.pointId))

    return <Popover
        open={open}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={{top: popoverActives?.y + 12, left: popoverActives?.x - 94}}>
        <Typography sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            padding: "4px",
            "& button": {
                minWidth: 'auto',
                border: (theme) => `${theme.palette.primary.stroke} 1px solid`,
                padding: "3px",
                "&:hover": {
                    border: (theme) => `${theme.palette.secondary.add} 1px solid`,
                    backgroundColor: "white"
                }
            }
        }}>
            <Tooltip arrow placement="bottom" title={popoverActives.arc ? "Изменить дугу" : "Создать дугу"}>
                <Button onClick={() => processingHandle("arc", popoverActives.pointId, popoverActives.pointId)}>
                    <ArcIcon style={{width: "30px", height: "30px"}}/>
                </Button>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Скруглить угол">
                <Button onClick={() => processingHandle("round", popoverActives.pointId, popoverActives.pointId)}
                        disabled={!cutPossible}
                        sx={{opacity: `${cutPossible ? 1 : 0.2}`}}>
                    <RoundIcon style={{width: "30px", height: "30px"}}/>
                </Button>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Срез">
                <Button onClick={() => processingHandle("cut", popoverActives.pointId, popoverActives.pointId)}
                        disabled={!cutPossible}
                        sx={{opacity: `${cutPossible ? 1 : 0.2}`}}>
                    <CutIcon style={{width: "30px", height: "30px"}}/>
                </Button>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Четверь">
                <Button onClick={() => processingHandle("quarter", popoverActives.pointId, popoverActives.pointId)}
                        disabled={!quarterPossible}
                        sx={{opacity: `${quarterPossible ? 1 : 0.2}`}}>
                    <QuarterIcon style={{width: "30px", height: "30px"}}/>
                </Button>
            </Tooltip>
            {contour && <Tooltip arrow placement="bottom" title="Контур">
                <Button onClick={() => processingHandle("editNotch", contour.id, contour.id)}>
                    <CircuitIcon style={{width: "30px", height: "30px"}}/>
                </Button>
            </Tooltip>}
            <Tooltip arrow placement="bottom" title="Редактировать">
                <Button onClick={() => processingHandle("editPoint", null, popoverActives.pointId)}>
                    <PencilIcon style={{width: "30px", height: "30px"}}/>
                </Button>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Удалить">
                <Button onClick={() => openConfirmModal("deletePoint", popoverActives.pointId)}>
                    <TrashIcon style={{width: "30px", height: "30px"}}/>
                </Button>
            </Tooltip>
        </Typography>
    </Popover>
}
export default PopoverProcessing
