import React, {useState} from 'react';
import UserTable from "../../components/UI/table/common/user/UserTable";
import {Checkbox, FormControlLabel, Paper, Typography} from "@mui/material";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import ButtonText from "../../components/UI/button/ButtonText";
import {SearchComponent} from "../../components/UI/search/Search";
import {Link} from "react-router-dom";
import {ReactComponent as PayIcon} from "../../components/Icons/pay.svg"
import {ReactComponent as InstagramIcon} from "../../components/Icons/instagram.svg"
import {ReactComponent as WhatsappIcon} from "../../components/Icons/whatsapp.svg"
import {ReactComponent as ViberIcon} from "../../components/Icons/viber.svg"
import {ReactComponent as YoutubeIcon} from "../../components/Icons/youtube.svg"
import {ReactComponent as TelegramIcon} from "../../components/Icons/telegram.svg"

const Footer = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState(false)
    return (
        <Paper sx={{
            width: '100%',
            height: "295px",
            padding: "40px 0",
            display: 'flex',
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.primary.lightGrey,
        }} component="footer">
            <Container sx={{
                "&.MuiContainer-root": {
                    maxWidth: "1668px",
                    height: "100%",
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "space-between",
                },
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            fontWeight: 800,
                            fontSize: "32px",
                            letterSpacing: '.3rem',
                            color: (theme) => theme.palette.primary.main,
                            textDecoration: 'none',
                        }}
                    >
                        UpMe
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "column", gridGap: "16px", width: 358}}>
                        <Typography component="p" sx={{fontSize: "20px", fontWeight: 700}}>
                            Подпишитесь на рассылку и будьте в курсе!
                        </Typography>
                        <SearchComponent buttonText={"Подписаться"}/>
                        <FormControlLabel sx={{
                            "& .MuiTypography-root": {
                                color: (theme) => theme.palette.text.light,
                                fontSize: "12px",
                            },
                            "& a": {
                                color: (theme) => theme.palette.primary.main,
                                textDecoration: "none"
                            }
                        }} control={<Checkbox
                            checked={privacyPolicy}
                            onChange={() => setPrivacyPolicy(!privacyPolicy)}
                        />} label={<Typography>
                            Я соглашаюсь с <Link>
                            Политикой конфиденциальности и Обработкой персональных
                            данных</Link>
                        </Typography>}/>
                    </Box>
                    <Box sx={{
                        width: 136,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: 'flex-start',
                        gridGap: "16px"
                    }}>
                        <ButtonText text={"Помощь"}></ButtonText>
                        <ButtonText text={"Новости"}></ButtonText>
                        <ButtonText text={"Контакты"}></ButtonText>
                        <ButtonText text={"Работа в UpMe"}></ButtonText>
                        <ButtonText text={"Стать партнёром"}></ButtonText>
                    </Box>
                    <Box sx={{width: 230, display: "flex", flexDirection: "column", gridGap: "16px"}}>
                        <Typography component="p" sx={{fontSize: "18px", fontWeight: 500}}>
                            Мы принимаем к оплате:
                        </Typography>
                        <PayIcon/></Box>
                    <Box sx={{width: 211, display: "flex", flexDirection: "column", gridGap: "16px"}}>
                        <Typography component="p" sx={{fontSize: "18px", fontWeight: 500}}>
                            Наши контакты
                        </Typography>
                        <Box>
                            <Typography component="p" sx={{fontSize: "18px", fontWeight: 500}}>
                                +375 29 444 00 65
                            </Typography>
                            <Typography component="p" sx={{fontSize: "14px"}}>
                                Ежедневно, с 8:00 до 18:00
                            </Typography>
                        </Box>
                        <Typography component="p" sx={{
                            fontSize: "16px",
                            "& a": {
                                color: (theme) => theme.palette.primary.main,
                                textDecoration: "none"
                            }
                        }}>
                            email: <a href="mailto:info@upmy.ru">info@upmy.ru</a>
                        </Typography>
                        <Box sx={{display: "flex", alignItems: 'center', gridGap: "16px"}}>
                            <a href="">
                                <InstagramIcon/>
                            </a>
                            <a href="">
                                <WhatsappIcon/>
                            </a>
                            <a href="">
                                <ViberIcon/>
                            </a>
                            <a href="">
                                <YoutubeIcon/>
                            </a>
                            <a href="">
                                <TelegramIcon/>
                            </a>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <hr align="left" width="100%" size="1" color="#EAEBEA"/>
                    <Box sx={{
                        paddingTop: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center'
                    }}>
                        <Typography sx={{
                            color: (theme) => theme.palette.text.main,
                        }}>
                            © 2024 ООО «UpMe» ИНН: 0901051787 КПП 090101001
                        </Typography>
                        <Box sx={{width: 591, display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
                            <Typography sx={{
                                color: (theme) => theme.palette.text.main,
                            }}>
                                Политика конфиденциальности
                            </Typography>
                            <Box>
                                <Typography sx={{
                                    fontSize: "13px",
                                    color: (theme) => theme.palette.text.light,
                                    "& a": {
                                        color: (theme) => theme.palette.text.light,
                                    }
                                }}>
                                    Разработка сайта <a href="">«АйтиДжус»</a>
                                </Typography>
                                <Typography sx={{
                                    fontSize: "13px",
                                    color: (theme) => theme.palette.text.light,
                                    "& a": {
                                        color: (theme) => theme.palette.text.light,
                                    }
                                }}>
                                    Дизайн <a href="https://t.me/HQdesigner">https://t.me/HQdesigner</a>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Paper>
    );
};

export default Footer;