import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const materialReserveRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/material-reserve'

export default class MaterialReserveService {

    static async getAllNotArchivePieceMaterial() {
        return axios.get(materialReserveRestUrl + '/not-archive-piece-material');
    }

    static async getAllByIssuingEdgeId(issuingEdgeId) {
        return axios.get(materialReserveRestUrl + '/get-all-by-issuing-edge-id' + '?issuingEdgeId=' + issuingEdgeId);
    }

    static async getAllNotArchiveSlabMaterial() {
        return axios.get(materialReserveRestUrl + '/not-archive-slab-material');
    }

    static async getAllNotArchiveLinearMaterial() {
        return axios.get(materialReserveRestUrl + '/not-archive-liner-material');
    }

    static async getAllByOrderId(orderId) {
        return axios.get(materialReserveRestUrl + '/by-order-id' + '?orderId=' + orderId);
    }

    static async getAllByWorkingCuttingMapId(workingCuttingMapId) {
        return axios.get(materialReserveRestUrl + '/get-all-by-working-cutting-map-id' + '?workingCuttingMapId=' + workingCuttingMapId);
    }

    static async getByOrdersCuttingMapDataObjectId(ordersCuttingMapDataObjectId) {
        return axios.get(materialReserveRestUrl + '/get-by-orders-cutting-map-data-object-id' + '?ordersCuttingMapDataObjectId=' + ordersCuttingMapDataObjectId);
    }

    static async getAllByMaterialEstimateUnitId(type, materialEstimateUnitId) {
        if (type === 'calculation') {
            return MaterialReserveService.getAllCalculationByMaterialEstimateUnitId(materialEstimateUnitId);
        } else {
            return MaterialReserveService.getAllActualByMaterialEstimateUnitId(materialEstimateUnitId);
        }
    }

    static
    async getAllActualByMaterialEstimateUnitId(materialEstimateUnitId) {
        return axios.get(materialReserveRestUrl + '/get-all-by-material-estimate-unit-id' + '?materialEstimateUnitId=' + materialEstimateUnitId);
    }

    static
    async getAllCalculationByMaterialEstimateUnitId(materialEstimateUnitId) {
        return axios.get(materialReserveRestUrl + '/get-all-calculation-by-material-estimate-unit-id' + '?materialEstimateUnitId=' + materialEstimateUnitId);
    }

    static async issue(id) {
        return await axios.post(materialReserveRestUrl + '/' + id + '/issue', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setPackaging(id, status) {
        return await axios.patch(materialReserveRestUrl + '/' + id + '/set-packaging' + '?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setSupplier(id, supplierId, typeMaterialReserve) {
        return await axios.patch(materialReserveRestUrl + '/' + id + '/set-supplier' + '?supplierId=' + supplierId
            + '&typeMaterialReserve=' + typeMaterialReserve, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id, costMaterial, priceMaterial, costEstimate, priceEstimate) {
        await axios.delete(materialReserveRestUrl + '/' + id
            + '?costMaterial=' + costMaterial
            + '&priceMaterial=' + priceMaterial
            + '&costEstimate=' + costEstimate
            + '&priceEstimate=' + priceEstimate);
    }

    static async save(materialEstimateUnitId, materialWarehouseId, supplierMaterialId,
                      quantity, costMaterial, priceMaterial, costEstimate, priceEstimate, width) {

        const params = new URLSearchParams({
            costMaterial,
            priceMaterial,
            costEstimate,
            priceEstimate,
            materialEstimateUnitId,
            quantity
        });

        if (supplierMaterialId !== null) {
            params.append('supplierMaterialId', supplierMaterialId);
        }

        if (materialWarehouseId !== null) {
            params.append('materialWarehouseId', materialWarehouseId);
        }

        if (width !== null) {
            params.append('width', width);
        }

        return await axios.post(materialReserveRestUrl + '?' + params.toString(), [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}