import React, {useCallback, useMemo, useState} from 'react';
import {Button, Tooltip, Typography} from '@mui/material';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import ModalConfirm from "../../../../modal/ModalConfirm";
import CreateNewOrderModal from "./CreateNewOrderModal";
import CustomerOrderService from "../../../../../../API/orders/orders/customer/CustomerOrderService";
import {constantsErrorMessages} from "../../../../../../Constants";
import ModalAlert from "../../../customer-detales/ModalAlert";
import {customerOrderType} from "../../../../../../data/CustomerOrderType";
import {estimateStatuses} from "../../../../../../data/EstimateState";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";

const CustomerOrderTable = ({
                                archive,
                                data,
                                setData,
                                enableEditing,
                                createType
                            }) => {
    const navigate = useNavigate();
    const [createModalOpen, setCreateModalOpen] = useState(createType);
    const [alertData, setAlertData] = useState({});
    const [sendArchiveModalOpen, setSendArchiveModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState({
        numberCustomer: "",
        orderType: createType || Object.entries(customerOrderType)[0][0]
    });
    const [rowSelection, setRowSelection] = useState({});
    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true, message: constantsErrorMessages[error.response.status] || error.message
        })
    }

    const handleClose = () => {
        createType && navigate('/customer/orders')
        setCreateModalOpen(false)
        setCurrentEditRowId(null)
    }
    const handleUpdateModel = (row) => {
        setCurrentEditRowData({
            numberCustomer: row.numberCustomer,
            orderType: row.orderType
        })
        setCurrentEditRowId(row.id);
        setCreateModalOpen(true)
    }
    const handleCreateModel = () => {
        setCurrentEditRowData({
            numberCustomer: "",
            orderType: Object.entries(customerOrderType)[0][0]
        })
        setCreateModalOpen(true)
    }

    const handleSubmit = (values) => {
        CustomerOrderService.save(values)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    };

    const handleUpdate = (data) => {
        CustomerOrderService.edit(currentEditRowId, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    };

    const sendToArchive = useCallback(
        (row) => {
            setCurrentEditRowData(row);
            setSendArchiveModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleSubmitSendToArchive = () => {
        CustomerOrderService.sendToArchive(currentEditRowData.id)
            .then(function () {
                data.splice(currentEditRowData.index, 1);
                setData([...data]);
                setCurrentEditRowData(null);
                setSendArchiveModalOpen(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseModalSendToArchive = () => {
        setSendArchiveModalOpen(false);
    };

    const handleDeleteOrder = (row) => {
        CustomerOrderService.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'numberCustomer',
                header: 'Номер заказа',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                size: 300,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/customer/orders/' + data[row.index].id + '/' + data[row.index].numberCustomer}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'orderType',
                header: 'Тип заказа',
                accessorKey: 'orderType',
                filterFn: 'contains',
                size: 300,
                Cell: ({cell}) => customerOrderType[cell.getValue()],
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimateState',
                filterFn: 'contains',
                size: 300,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfCreate === null) {
                    } else {
                        return new Date(row.dateOfCreate)
                    }
                },
                id: 'dateOfCreate',
                header: 'Дата',
                size: 300,
                sortingFn: 'datetime',
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        return baseColumns;

    }, [data, setData]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleUpdateModel},
        {type: "archive", text: "Поместить в архив", handler: sendToArchive},
        {type: "delete", text: "Удалить", handler: handleDeleteOrder}
    ]

    return (
        <>
            <Box
                sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "8px"}}
            ><
                Typography sx={{fontSize: "24px", fontWeight: 700}}>Заказы</Typography>
                <Box sx={{display: 'flex', gap: "8px"}}>
                    {!archive && <ButtonCreate
                        onClick={handleCreateModel}
                        width={170}
                        variant="contained"
                        text={"Создать заказ"}
                    />}
                </Box>
            </Box>
            <MaterialReactTable
                enableColumnFilterModes
                enableGrouping={false}
                enableStickyHeader={false}
                enablePinning={false}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "100%",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light,
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light
                                },
                            },
                        })
                    })
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                columns={columns}
                data={data}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection, showGlobalFilter: true}}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                initialState={{
                    columnVisibility: {
                        id: false,
                        model: false,
                        year: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}

                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        disabled={el.type === "archive" && row.original.estimateState !== "SHIPMENT" || el.type === "delete" && row.original.estimateState !== "CALCULATION"}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })
                }
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />
            <ModalAlert data={alertData} onClose={() => setAlertData((alert) => {
                return {...alert, open: false}
            })}/>
            <CreateNewOrderModal
                title={currentEditRowId ? "Изменить номер заказа" : "Создать заказ"}
                initValue={currentEditRowData}
                open={createModalOpen}
                onClose={handleClose}
                onSubmit={currentEditRowId ? handleUpdate : handleSubmit}
            />
            <ModalConfirm
                title={"Подтвердить поместить в архив"}
                modalOpen={sendArchiveModalOpen}
                handleClose={handleCloseModalSendToArchive}
                handleSubmit={handleSubmitSendToArchive}
                action={"Подтвердить"}
            />
        </>
    );
};

export default CustomerOrderTable;