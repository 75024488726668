import React from 'react';
import {Button, Input, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const UploadFilesForm = ({handleUpload, selectedFiles, setSelectedFiles, label, uploadData}) => {

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    return (
        <div>
            <form onSubmit={handleUpload} style={{display: "flex", gap: "10px"}}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "8px",
                            width: 285,
                            borderRadius: "4px",
                            padding: "4px 8px",
                            boxSizing: "border-box",
                            border: (theme) => `${theme.palette.primary.main} 1px solid`,
                            "& label p": {
                                textAlign: "center",
                                width: 133,
                                height: 24,
                                fontSize: "16px",
                                borderRadius: "4px",
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }}>
                        <label htmlFor={`input ${uploadData.id}`}>
                            <Typography>Выбрать файлы</Typography>
                        </label>
                        <Typography
                            sx={{fontSize: "16px"}}>
                            {selectedFiles.length === 0 ? "Файл не выбран" : selectedFiles.length === 1 ? selectedFiles[0].name : `${selectedFiles.length} файла `}
                        </Typography>
                    </Box>
                    <Input id={`input ${uploadData.id}`} sx={{display: "none"}} type="file" onChange={handleFileSelect}
                           inputProps={{multiple: true}}/>
                    <ul style={{
                        display: "flex",
                        gap: "10px",
                        maxHeight: "90px",
                        overflowY: "auto",
                        listStyleType: "none",
                        padding: 0
                    }}>
                        {selectedFiles.map((file, index) => (
                            <li key={index} style={{padding: "5px 0"}}>
                                {file.name},
                            </li>
                        ))}
                    </ul>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedFiles.length === 0}
                    sx={{
                        height: 36,
                        padding: "5px",
                        fontWeight: 500,
                        fontSize: "0.7rem",
                        lineHeight: 1.75,
                        letterSpacing: "0.02857em",
                        borderRadius: "4px",
                        border: '1px solid #3B8BEB',
                        zIndex: 0,
                        bgcolor: (theme) => theme.palette.primary.main,
                        "&:hover": {
                            bgcolor: (theme) => theme.palette.primary.main,
                            boxShadow: "0 0 0 0.2rem #C4DBF6"
                        },
                        '&:disabled': {
                            bgcolor: (theme) => theme.palette.primary.stroke,
                            color: (theme) => theme.palette.text.light,
                            border: "none"
                        }
                    }}
                    type="submit"
                    value={label}
                >
                    {label}
                </Button>
                <Tooltip sx={{height: "36px"}} arrow placement="top"
                         title={uploadData.helpText}>
                    <HelpOutlineIcon/>
                </Tooltip>

            </form>
        </div>
    );
};

export default UploadFilesForm;