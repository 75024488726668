import React, {useEffect, useState} from 'react';
import EstimateTableItem from "../../../../components/UI/table/orders/organization/estimate/EstimateTableItem";
import {useNavigate} from "react-router-dom";
import ProductionCosts from "../../../../components/UI/table/orders/organization/estimate/ProductionCosts";
import CalculationTable from "../../../../components/UI/table/orders/organization/estimate/CalculationTable";
import JobMetricOrderOrganizationTable
    from "../../../../components/UI/table/orders/organization/estimate/JobMetricOrderOrganizationTable";
import MaterialEstimateUnitService from "../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import WarehouseMaterialSettingsService from "../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import MaterialCostTable from "../../../../components/UI/table/orders/organization/estimate/MaterialCostTable";
import SwitchTab from "./SwitchTab";

const EstimateItem = ({
                          estimateId,
                          orderId,
                          orderNumber,
                          orderHeaderId,
                          year,
                          isButtonPurchase,
                          enableEditing,
                          isActual,
                          inWork,
                          orderCloud,
                          isImport,
                          detailed,
                      }) => {

    const [value, setValue] = useState('2');
    const [valueCalculationTable, setValueCalculationTable] = useState('2');

    const [currentEstimateState, setCurrentEstimateState] = useState()
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");
    const [JobCalculationMetricOrderOrganization, setJobCalculationMetricOrderOrganization] = useState([]);
    const [JobWorkMetricOrderOrganization, setJobWorkMetricOrderOrganization] = useState([]);
    const [calculationData, setCalculationData] = useState([]);
    const [workData, setWorkData] = useState([]);
    const [productionCostsCalculation, setProductionCostsCalculation] = useState([]);
    const [productionCostsWork, setProductionCostsWork] = useState([]);

    const [tab, setTab] = useState(1);

    useEffect(() => {

        /*MaterialEstimateUnitService.getAllByOrderId(orderId)
            .then(function (response) {
                const fetchedData = response.data;
                const {calculation, work} = fetchedData.reduce((acc, item) => {
                    if (item.materialEstimateUnitState === 'CALCULATION') {
                        acc.calculation.push(item);
                    } else if (item.materialEstimateUnitState === 'WORK') {
                        acc.work.push(item);
                    }
                    return acc;
                }, {calculation: [], work: []});

                // Создаем объекты для быстрого доступа по имени
                const calcMap = new Map(calculation.map(item => [item.name, item]));
                const workMap = new Map(work.map(item => [item.name, item]));

                // Определяем добавленные и удаленные элементы
                const added = [];
                const removed = [];

                // Проверяем, что есть в WORK, но нет в CALCULATION
                work.forEach(item => {
                    if (!calcMap.has(item.name)) {
                        added.push(item);
                    }
                });

                // Проверяем, что есть в CALCULATION, но нет в WORK
                calculation.forEach(item => {
                    if (!workMap.has(item.name)) {
                        removed.push(item);
                    }
                });

            })
            .catch(function (error) {
                console.log(error);
            });*/

        MaterialEstimateUnitService.getAllByOrderId(orderId)
            .then(function (response) {
                const fetchedData = response.data;
                const {calculation, others} = fetchedData.reduce((acc, item) => {
                    if (item.materialEstimateUnitState === 'CALCULATION') {
                        acc.calculation.push(item);
                    } else {
                        acc.others.push(item);
                    }
                    return acc;
                }, {calculation: [], others: []});
                setCalculationData(calculation.length > 0 ? calculation : []);
                setWorkData(others.length > 0 ? others : []);

                const calculationWithJobMetric = [];
                const prCostsCalculation = [];
                calculation.forEach(item => {
                    let jobMetricOrderOrganizations = item.jobMetricOrderOrganizations;
                    if (jobMetricOrderOrganizations) {
                        calculationWithJobMetric.push(...item.jobMetricOrderOrganizations);
                        jobMetricOrderOrganizations.forEach(item => {
                            let productionCosts = item.productionCosts
                            if (Array.isArray(productionCosts)) {
                                prCostsCalculation.push(...productionCosts);
                            }
                        });
                    }
                });
                const othersWithJobMetric = [];
                const prCostsWork = [];
                others.forEach(item => {
                    let jobMetricOrderOrganizations = item.jobMetricOrderOrganizations;
                    if (jobMetricOrderOrganizations) {
                        calculationWithJobMetric.push(...item.jobMetricOrderOrganizations);
                        jobMetricOrderOrganizations.forEach(item => {
                            let productionCosts = item.productionCosts
                            if (Array.isArray(productionCosts)) {
                                prCostsWork.push(...productionCosts);
                            }
                        });
                    }
                });

                setJobCalculationMetricOrderOrganization(calculationWithJobMetric);
                setJobWorkMetricOrderOrganization(othersWithJobMetric)
                setProductionCostsCalculation(prCostsCalculation)
                setProductionCostsWork(prCostsWork);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [orderId]);

    useEffect(() => {
        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const navigate = useNavigate();

    const redirectOrder = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year);
    };

    const tabCalculation = [
        {
            tabIndex: 1, label: "Расчетная", component: <CalculationTable
                data={calculationData}
                setData={setCalculationData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isActual={true}
                inWork={currentEstimateState === 'IN_WORK'}
                isFact={false}
                redirectOrder={redirectOrder}
            />
        },
        {
            tabIndex: 2, label: "Фактическая", component: <CalculationTable
                data={workData}
                setData={setWorkData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isActual={true}
                inWork={currentEstimateState === 'IN_WORK'}
                isFact={true}
            />
        },
    ];

    const tabCost = [
        {
            tabIndex: 1, label: "Расчетная", component: <EstimateTableItem
                data={calculationData}
                setData={setCalculationData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isButtonPurchase={true}
                enableEditing={true}
                isActual={true}
                inWork={currentEstimateState === 'IN_WORK'}
                orderCloud={false}
                isFact={false}
            />
        },
        {
            tabIndex: 2, label: "Фактическая", component: <EstimateTableItem
                data={workData}
                setData={setWorkData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isButtonPurchase={false}
                enableEditing={true}
                isActual={true}
                inWork={currentEstimateState === 'IN_WORK'}
                orderCloud={false}
                isFact={true}
            />
        },
    ];

    const tabCostJob = [
        {
            tabIndex: 1, label: "Расчетная", component: <ProductionCosts
                data={productionCostsCalculation}
                setData={setProductionCostsCalculation}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
            />
        },
        {
            tabIndex: 2, label: "Фактическая", component: <ProductionCosts
                data={productionCostsWork}
                setData={setProductionCostsWork}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
            />
        },
    ];

    const tabPrice = [
        {
            tabIndex: 1, label: "Расчетная", component: <MaterialCostTable
                materialEstimateUnits={calculationData}
                setMaterialEstimateUnits={setCalculationData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isButtonPurchase={true}
                enableEditing={true}
                isActual={true}
            />
        },
        {
            tabIndex: 2, label: "Фактическая", component: <MaterialCostTable
                materialEstimateUnits={workData}
                setMaterialEstimateUnits={setWorkData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isButtonPurchase={true}
                enableEditing={true}
                isActual={true}
            />
        },
    ];

    const tabPriceJob = [
        {
            tabIndex: 1, label: "Расчетная", component: <JobMetricOrderOrganizationTable
                data={JobCalculationMetricOrderOrganization}
                setData={setJobCalculationMetricOrderOrganization}
                orderId={orderId}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
            />
        },
        {
            tabIndex: 2, label: "Фактическая", component: <JobMetricOrderOrganizationTable
                data={JobWorkMetricOrderOrganization}
                setData={setJobWorkMetricOrderOrganization}
                orderId={orderId}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
            />
        },
    ];

    const generalTabComponents = [
        {
            tabIndex: 1, label: "Итоги", component: <SwitchTab
                tabComponents={tabCalculation}
                margin={"20px"}
            ></SwitchTab>
        },
        {
            tabIndex: 2, label: "Себестоимость материалов", component: <SwitchTab
                tabComponents={tabCost}
                margin={"20px"}
            ></SwitchTab>
        },
        {
            tabIndex: 3, label: "Себестоимость работ", component: <SwitchTab
                tabComponents={tabCostJob}
                margin={"20px"}
            ></SwitchTab>
        },
        {
            tabIndex: 4, label: "Стоимость материалов", component: <SwitchTab
                tabComponents={tabPrice}
                margin={"20px"}
            ></SwitchTab>
        },
        {
            tabIndex: 5, label: "Стоимость работ", component: <SwitchTab
                tabComponents={tabPriceJob}
                margin={"20px"}
            ></SwitchTab>
        },
    ];

    return (
        <SwitchTab tabComponents={generalTabComponents} margin={"20px"}></SwitchTab>
    );
};

export default EstimateItem;