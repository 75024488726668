import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ArrowRight} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AttributesService from "../../../../../../API/orders/orders/organization/AttributesService";
import ButtonSettingsOrders from "../../../../button/ButtonSettingsOrders";
import CellCheckboxATUnable from "./CellCheckboxATUnable";
import {useNavigate} from "react-router-dom";
import CellCheckboxCurrent from "../../../../сheckbox/CellCheckboxCurrent";

const AttributesTable = () => {

    const [data, setData] = useState([]);
    const [currentPackagingSetId, setCurrentPackagingSetId] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const navigate = useNavigate();

    const redirectModels = () => {
        navigate('/organization/models/');
    };

    useEffect(() => {
        AttributesService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [currentPackagingSetId]);


    const setPackagingSet = useCallback(
        (row) => {
            const idPackagingSet = row.original.id;
            AttributesService.unableDefault(idPackagingSet)
                .then(function () {
                    setCurrentPackagingSetId(idPackagingSet)
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [],
    );

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'name',
                accessorKey: 'name',
                minSize: 25,
            },
            {
                id: 'enabledDefault',
                header: 'По умочанию',
                accessorKey: 'enabledDefault',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Cell: ({cell, row}) => <CellCheckboxCurrent
                    cell={cell}

                >
                </CellCheckboxCurrent>,
            },
            {
                id: 'enabled',
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Cell: ({cell, row}) => <CellCheckboxATUnable
                    cell={cell}
                    id={data[row.index].id}
                ></CellCheckboxATUnable>,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="right" title="Установить">
                            <IconButton color="success" onClick={() => setPackagingSet(row)}>
                                <ArrowRight/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <ButtonSettingsOrders/>
                    </Box>
                )}
            />
        </>
    );
};

export default AttributesTable;