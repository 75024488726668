import React, {useState} from "react";
import UploadFilesForm from "../../../../components/form/UploadFilesForm";
import {Tooltip} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const UploadFilesRequestsForm = ({orderId, change, setChange, calculationRequestsCutting, upload, detail, uploadData}) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {
        event.preventDefault();
        upload(selectedFiles, calculationRequestsCutting, orderId)
            .then(function () {
                setSelectedFiles([]);
                setChange(!change)
                event.target.reset();
            })
            .catch(function (error) {
                console.log(error);
                event.target.reset();
            })
    }

    return (
        <div>
            <UploadFilesForm
                uploadData={uploadData}
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={detail ? "Загрузить делали" : "Загрузить заявки на раскрой"}
            />
        </div>
    );
};

export default UploadFilesRequestsForm;