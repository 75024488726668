import {Button, Divider, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import {Container} from "@mui/system";
import React, {useEffect, useState} from "react";
import ButtonAdd from "../../components/UI/button/ButtonAdd";
import {ReactComponent as Advantages1} from "../../components/Icons/advantages1.svg"
import {ReactComponent as Advantages2} from "../../components/Icons/advantages2.svg"
import {ReactComponent as Advantages3} from "../../components/Icons/advantages3.svg"
import {ReactComponent as Advantages4} from "../../components/Icons/advantages4.svg"
import {CarouselComponent} from "../../components/UI/carousel/CarouselComponent";
import kitchen from "../../components/Icons/kitchen.png";
import steps from "../../components/Icons/steps.png";
import services1 from "../../components/Icons/services1.png";
import services2 from "../../components/Icons/services2.png";
import services3 from "../../components/Icons/services3.png";
import ButtonArrow from "../../components/UI/button/ButtonArrow";
import {useNavigate} from "react-router-dom";
import {authorization, authorizedOrganization} from "../../Constants";
import UtilityService from "../../API/common/UtilityService";

const advantages = [
    {text: "Удобная форма заявки", icon: <Advantages1/>},
    {text: "Огромный выбор производителей", icon: <Advantages2/>},
    {text: "Самые выгодные предложения на рынке", icon: <Advantages3/>},
    {text: "Скидки и Акции", icon: <Advantages4/>}]

const popularServices = [
    {createType: "CUT", text: "Заказать распил", img: services1},
    {createType: "CUT_PROCESSING", text: "Заказать распил с обработкой", img: services2},
    {createType: "CUT_PROCESSING_ACCESSORIES", text: "Заказать распил с обработкой и фурнитурой", img: services3}]

const Home = () => {
    const navigate = useNavigate();
    const auth = authorization + '/oauth2/authorization/up-me-client-authorization-code';
    const [profile, setProfile] = useState(null)
    const [banners, seBanners] = useState([])

    const isOrganization = profile?.userRoles.some(role => authorizedOrganization.includes(role.name))

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem("profile"))
        setProfile(profile)

        UtilityService.getById(1).then(function (response) {
            seBanners(response.data.pictures)
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const createRequest = () => {
        profile ? navigate('/customer/orders') : window.location.href = auth
    }
    const orderCutting = (createType) => {
        profile ? navigate(`/customer/orders?create=${createType}`) : window.location.href = auth
    }

    return (
        <>
            <Paper sx={{
                width: '100%',
                padding: "40px 0",
                display: 'flex',
                justifyContent: "space-between",

            }}>
                <Container sx={{
                    "&.MuiContainer-root": {
                        maxWidth: "1668px",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: "center",
                        gridGap: "100px",
                    },
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: "column",
                        alignItems: "center", gridGap: "40px",

                    }}>
                        <CarouselComponent banners={banners}/>
                        <Box sx={{
                            display: "flex", gap: "16px", flexWrap: "wrap", justifyContent: "center",

                        }}>
                            {advantages.map((el, i) => {
                                return <Box key={i} sx={{
                                    boxSizing: "border-box",
                                    display: 'flex',
                                    alignItems: 'center',
                                    gridGap: "16px",
                                    padding: "20px",
                                    backgroundColor: (theme) => theme.palette.background.grey,
                                    width: '393px',
                                    height: '84px',
                                    borderRadius: "8px",
                                    '@media (max-width: 1620px)': {
                                        width: "351px",
                                    },

                                }}>
                                    <Box sx={{width: 44, height: 44}}>{el.icon}</Box>
                                    <Typography sx={{width: 200}}>
                                        {el.text}
                                    </Typography>
                                </Box>
                            })}
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: "column",
                        gridGap: "40px",
                        justifyContent: "center",
                        width: "100%",


                    }}>
                        <Typography sx={{
                            fontSize: "40px", fontWeight: 700, lineHeight: 1,
                            '@media (max-width: 1450px)': {
                                fontSize: "28px",
                            }
                        }}>
                            Популярные услуги
                        </Typography>
                        <Box sx={{
                            display: "flex", gap: "16px",
                        }}>
                            {popularServices.map((el, i) => {
                                return <Box
                                    key={i}
                                    onClick={!isOrganization ? () => orderCutting(el.createType) : undefined}
                                    sx={{
                                        cursor: !isOrganization && "pointer",
                                        display: 'flex',
                                        backgroundColor: (theme) => theme.palette.background.grey,
                                        minHeight: '220px',
                                        width: 531,
                                        borderRadius: "16px",
                                        border: (theme) => `transparent 1px solid`,
                                        padding: "24px",
                                        justifyContent: "space-between",
                                        boxSizing: "border-box",
                                        position: "relative",

                                        "&:hover": !isOrganization && {
                                            backgroundColor: "white",
                                            border: (theme) => `${theme.palette.primary.main} 1px solid`,
                                            "& .MuiButton-root": {
                                                backgroundColor: "white",
                                                boxShadow: "12px 12px 22px 0px rgba(9, 88, 146, 0.12),0px 12px 24px 0px rgba(44, 112, 163, 0.22)",
                                                "& svg": {
                                                    fill: "#3B8BEC"
                                                }
                                            }
                                        }
                                    }}>
                                    <Box sx={{
                                        paddingRight: "8px",
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}>
                                        <Typography sx={{
                                            textWrap: "wrap",
                                            fontSize: "24px",
                                            fontWeight: 700,
                                            lineHeight: 1.2,
                                            color: (theme) => theme.palette.text.main,
                                            '@media (max-width: 1450px)': {
                                                fontSize: "18px"
                                            }
                                        }}>
                                            {el.text}
                                        </Typography>
                                        <Box
                                            sx={{
                                                '@media (max-width: 1450px)': {
                                                    position: "absolute",
                                                    bottom: 24,
                                                    right: 24
                                                }
                                            }}>
                                            {!isOrganization && <ButtonArrow/>}
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        borderRadius: "8px",
                                        height: '172px',
                                        minWidth: "227px",
                                        backgroundImage: `url(${el.img})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",

                                    }}/>
                                </Box>
                            })}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: "center",
                        boxSizing: "border-box",
                        backgroundColor: (theme) => theme.palette.background.grey,
                        borderRadius: "16px",
                        padding: "70px",
                        gap: "70px",
                        width: "100%",

                    }}>
                        <Box sx={{
                            height: '587px',
                            minWidth: "678px",
                            backgroundColor: "grey",
                            borderRadius: "8px",
                            backgroundImage: `url(${kitchen})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",

                        }}/>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "550px",
                            gap: "40px",

                        }}>
                            <Typography sx={{
                                fontSize: "40px", fontWeight: 700, lineHeight: 1.2,

                            }}>
                                Добро пожаловать в UpMe!
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "column",
                                gap: "16px",
                            }}>
                                <Typography sx={{fontSize: 16, lineHeight: 1.2}}>
                                    Ваш онлайн-конструктор производственных заказов из ДСП.
                                </Typography>
                                <Typography sx={{textWrap: "wrap", fontSize: 16, lineHeight: 1.2}}>
                                    Уже второе десятилетие мы готовы воплотить в реальность Вашу мечту о красивой,
                                    удобной
                                    мебели из ДСП, помочь реализовать любые дизайнерские фантазии с минимальными
                                    затратами
                                    времени и денежных средств.
                                </Typography>
                                <Typography sx={{textWrap: "wrap", fontSize: 16, lineHeight: 1.2}}>
                                    Создайте заявку на изготовление мебели — выберите своего мастера — получите новую
                                    мебель
                                    и наслаждайтесь уютом!
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                gap: "8px",
                            }}>
                                <Box sx={{
                                    boxSizing: "border-box",
                                    width: 270,
                                    height: 132,
                                    borderRadius: "8px",
                                    padding: "16px",
                                    border: (theme) => `${theme.palette.primary.main} 2px dashed`,
                                }}>
                                    <Typography sx={{
                                        fontSize: "28px",
                                        fontWeight: 700,
                                        color: (theme) => theme.palette.primary.main
                                    }}>
                                        24/7
                                    </Typography>
                                    <Typography sx={{fontSize: 16, lineHeight: 1.2,}}>
                                        Оформляйте заказ самостоятельно из любой точки мира
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    boxSizing: "border-box",
                                    width: 131,
                                    height: 132,
                                    borderRadius: "8px",
                                    padding: "16px",
                                    border: (theme) => `${theme.palette.primary.main} 2px dashed`,

                                }}>
                                    <Typography sx={{
                                        fontSize: "28px",
                                        fontWeight: 700,
                                        color: (theme) => theme.palette.primary.main
                                    }}>
                                        2 500+
                                    </Typography>
                                    <Typography sx={{fontSize: 16, lineHeight: 1.2,}}>
                                        постоянных клиентов
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    boxSizing: "border-box",
                                    width: 131,
                                    height: 132,
                                    borderRadius: "8px",
                                    padding: "16px",
                                    border: (theme) => `${theme.palette.primary.main} 2px dashed`,

                                }}>
                                    <Typography sx={{
                                        fontSize: "28px",
                                        fontWeight: 700,
                                        color: (theme) => theme.palette.primary.main
                                    }}>
                                        440
                                    </Typography>
                                    <Typography sx={{fontSize: 16, lineHeight: 1.2,}}>
                                        опытных сотрудников
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <ButtonAdd text={"Читать подробнее"} width={226} minWidth={184} sx={{}}/>
                                <Divider sx={{bgcolor: (theme) => theme.palette.secondary.add, width: "80px"}}/>
                                <Box sx={{
                                    bgcolor: (theme) => theme.palette.secondary.add,
                                    width: "7px",
                                    height: "7px",
                                    borderRadius: "7px",
                                }}></Box>
                            </Box>
                        </Box>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: "column",
                        gridGap: "40px",
                        width: "100%",
                        '@media (max-width: 1450px)': {
                            gridGap: "24px",
                            // height: "527px",
                        }
                    }}>
                        <Typography sx={{
                            fontSize: "40px", fontWeight: 700,
                            '@media (max-width: 1450px)': {
                                fontSize: "28px",
                            }
                        }}>
                            Как работает сервис заказа мебели UpMe
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            {!isOrganization &&
                                <>
                                    <ButtonAdd text={"Создать заявку"} onClick={createRequest} minWidth={184}
                                               width={226}/>
                                    <Divider sx={{bgcolor: (theme) => theme.palette.secondary.add, width: "80px"}}/>
                                    <Box sx={{
                                        bgcolor: (theme) => theme.palette.secondary.add,
                                        width: "7px",
                                        height: "7px",
                                        borderRadius: "7px",
                                    }}/></>}
                        </Box>

                        <Box sx={{
                            minHeight: '331px',
                            width: "100%",
                            backgroundImage: `url(${steps})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            '@media (max-width: 1620px)': {
                                minHeight: "300px",
                            },
                        }}/>
                    </Box>
                </Container>
            </Paper>
            <Footer></Footer>
        </>
    )
        ;
};

export default Home;