import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const ButtonCreate = ({onClick, text, width = 210, height = 38, disabled = false, icon = true}) => {
    return <Button
        onClick={onClick}
        variant="outlined"
        disabled={disabled}
        sx={{
            width: width,
            height: height,
            borderRadius: "4px",
            color: (theme) => theme.palette.text.main,
            border: (theme) => `${theme.palette.primary.main} 1px solid`,
            padding: "8px",
            display: 'flex',
            gap: "8px",
            justifyContent: "flex-start",
            textTransform: 'none',
        }}
    >
        {icon && <AddIcon/>}
        {text}
    </Button>
}
export default ButtonCreate