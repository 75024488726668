import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const packagingControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/details/packaging/orders/'

export default class PackagingControllerService {
    static async add(orderId, data) {
        return axios.patch(`${packagingControllerRestUrl}${orderId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async remove(orderId, data) {
        return axios.patch(`${packagingControllerRestUrl}${orderId}/remove`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}