import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class NotchControllerService {
    static async add(orderId, detailId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/${detailId}/notches/rect`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderId, detailId, notchId) {
        return await axios.delete(`${detailControllerRestUrl}${orderId}/details/${detailId}/notches/${notchId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async put(orderId, detailId, notchId, data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/details/${detailId}/notches/${notchId}/update-params`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addRound(orderId, detailId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/${detailId}/round-notches`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteRound(orderId, detailId, notchId) {
        return await axios.delete(`${detailControllerRestUrl}${orderId}/details/${detailId}/round-notches/${notchId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async putRound(orderId, detailId, notchId, data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/details/${detailId}/round-notches/${notchId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
