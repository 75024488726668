import React, {useEffect, useState} from 'react';
import CustomerOrderService from "../../../API/orders/orders/customer/CustomerOrderService";
import CustomerOrderTable from "../../../components/UI/table/orders/customer/order/CustomerOrderTable";
import {useLocation} from 'react-router-dom';
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";

const CustomerOrdersArchive = () => {
    const location = useLocation();
    const createType = new URLSearchParams(location.search).get('create');

    const [data, setData] = useState([]);

    useEffect(() => {
        CustomerOrderService.getOrdersArchive().then(function (response) {
            let data = response.data;
            setData(data);

        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent page={"Архив"} path={[{name: "Главная", href: "/"}]}/>
            <CustomerOrderTable
                archive={true}
                createType={createType}
                data={data}
                setData={setData}
                enableEditing={true}
            />
        </Box>
    );
};

export default CustomerOrdersArchive;