import React, {useState} from 'react';
import AllMaterialPageItemModule from "../../../components/UI/modul/AllMaterialPageItemModule";
import MaterialPageItemModule from "../../../components/UI/modul/MaterialPageItemModule";
import {Breadcrumbs, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";

const AllMaterials = () => {

    const [pageSize, setPageSize] = useState(10);

    return <Box sx={{padding: "0 30px"}}>
        <BreadcrumbsComponent page={"Материалы"} path={[{name: "Главная", href: "/"}]}/>
        <MaterialPageItemModule
            allMaterial={true}
            refMaterial={true}
            pageSize={pageSize}
            setPageSize={setPageSize}
        />
    </Box>
};

export default AllMaterials;