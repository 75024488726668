import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import MaterialTree from "../tree/MaterialTree";
import MaterialTable from "../table/warehouse/material/material/MaterialTable";
import MaterialOrganizationOptionService
    from "../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import GroupMaterialService from "../../../API/orders/orders/organization/GroupMaterialService";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";

import {useLocation} from "react-router-dom";
import UserMaterialMaterialCloudService from "../../../API/material-cloud/user/UserMaterialMaterialCloudService";
import UserGroupMaterialMaterialCloudService
    from "../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import {Paper, Typography} from "@mui/material";
import ButtonCreate from "../button/ButtonCreate";
import ButtonSelectAction from "../button/ButtonSelectAction";
import {ModalSelectAction} from "../modal/ModalSelectAction";
import {authorizedAdmin, authorizedOrganization, constantsErrorMessages} from "../../../Constants";
import MaterialService from "../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";
import ModalAddSignJob from "../table/warehouse/material/material/ModalAddSignJob";
import ModalAlert from "../table/customer-detales/ModalAlert";
import SignJobService from "../../../API/orders/job/SignJobService";

const modalActions = [
    {name: "Перместить в архив", type: "setToArchive"},
    {name: "Переместить в группу", type: "setGroup"},
]

const MaterialPageItemModule = ({
                                    refMaterial,
                                    pageSize,
                                    addMaterialInArrival,
                                    enableEditing,
                                    isAddMaterial,
                                    isSelectInArrival,
                                    isSelectInEstimate,
                                    selectMaterialId,
                                    selectMaterial,
                                    typeMaterial,
                                    subTypeMaterial,
                                    modal,
                                    allMaterial
                                }) => {
    const pathname = useLocation().pathname
    const profile = JSON.parse(localStorage.getItem("profile"))
    const isOrganization = profile?.userRoles.some(role => authorizedOrganization.includes(role.name))
    const isAdmin = profile?.userRoles.some(role => authorizedAdmin.includes(role.name))

    const [open, setOpen] = useState(false)

    const [filterGroup, setFilterGroup] = useState(new Map());
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);
    const [dataTree, setDataTree] = useState([]);
    const [groupMap, setGroupMap] = useState(null);

    const [rowSelection, setRowSelection] = useState([]);
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const [openModalAddSignJob, setOpenModalAddSignJob] = useState(false);
    const [groupNames, setGroupNames] = useState("");
    const [groupIds, setGroupIds] = useState([]);
    const [disabledAddMaterial, setDisabledAddMaterial] = useState(true);
    const [currentGroup, setCurrentGroup] = useState(null);

    const [tableOptions, setTableOptions] = useState({pageIndex: 0, pageSize: 20, totalElements: 0, searchData: ""})

    const [alertData, setAlertData] = useState({});
    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleCloseModalAddSignJob = () => {
        setOpenModalAddSignJob(false)
        setRowSelection([]);
        setRowIndexSelection([]);
        setSelectedIds([]);
    };

    const handleSubmitAddSignJobInMaterial = () => {
        MaterialOrganizationOptionService.addSigJobInMaterialsByMaterialGroup(selectedIds, groupIds).then(function () {
            handleCloseModalAddSignJob();
        })
            .catch(function (error) {
                handleError(error)
            });
    }

    const [change, setChange] = useState(false);
    const [dataSignJob, setDataSignJob] = useState([]);

    useEffect(() => {
        isOrganization && !allMaterial && SignJobService.getAll().then(function (response) {
            let data = response.data;
            setDataSignJob(data);
        })
            .catch(function (error) {
                handleError(error)
            });
    }, [change]);

    useEffect(() => {
        if (groupIds.length === 0) {
            setDisabledAddMaterial(true)
        } else {
            setDisabledAddMaterial(false)
        }
    }, [groupIds]);

    useEffect(() => {
        const api = isOrganization && !allMaterial ? MaterialOrganizationOptionService.getAllFilter : UserMaterialMaterialCloudService.getAllFilter

        if (currentGroup) {
            api(currentGroup, tableOptions.pageIndex, tableOptions.pageSize,
                null, typeMaterial, subTypeMaterial, tableOptions.searchData, null).then(function (response) {
                let data = response.data;
                setCurrentData(data.content)
                setTableOptions((prev) => ({...prev, totalElements: data.total}))
            })
                .catch(function (error) {
                    handleError(error)
                });
        }
    }, [currentGroup, tableOptions.searchData, tableOptions.pageIndex, tableOptions.pageSize]);

    useEffect(() => {
        getStartPageSize()
        const api = isOrganization && !allMaterial ? GroupMaterialService.getAllByOrganization : UserGroupMaterialMaterialCloudService.getAll

        api(typeMaterial, subTypeMaterial).then((response) => {
            const buildTreeGroups = GroupMaterialUtil.buildTreeGroups(response.data);
            setDataTree(buildTreeGroups);
            const groupMap = new Map();
            GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
            setGroupMap(groupMap);
            setCurrentGroup({})
        }).catch(function (error) {
            handleError(error)
        });
    }, []);

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response?.status] || error.response.data.message || error.message
        })
    }

    const handleAddMaterial = () => {
    }

    const handleSelectAction = (action, groupId) => {
        if (action === "setGroup") {
            MaterialService.setGroup(groupId, {"ids": rowSelection}).then(() => {
                setRowSelection([])
            }).catch(function (error) {
                handleError(error)
            })
        } else {
            MaterialService.setToArchive({"ids": rowSelection}).then(() => {
                setRowSelection([])
            }).catch(function (error) {
                handleError(error)
            })
        }
        setOpen(false)
    }
    const handleOpenModalAddSignJob = () => {
        setOpenModalAddSignJob(true)
    };

    return (
        <>
            <ModalAlert
                data={alertData}
                onClose={() => setAlertData((alert) => ({...alert, open: false}))}
            />
            <Box sx={{
                display: 'flex',
                flexDirection: "column",
                maxWidth: isAdmin ? "1550px" : "1465px",
            }}>
                {!modal && <Box
                    sx={{display: 'flex', justifyContent: "space-between", paddingBottom: "8px"}}
                ><
                    Typography sx={{fontSize: "24px", fontWeight: 700}}>Материалы</Typography>
                    <Box sx={{display: 'flex', gap: "8px"}}>
                        {isAddMaterial && <ButtonCreate
                            width={245}
                            onClick={handleOpenModalAddSignJob}
                            disabled={disabledAddMaterial}
                            variant="contained"
                            text={"Добавить группе работы"}
                        />}
                        {(isAddMaterial || isAdmin) && <ButtonCreate
                            onClick={handleAddMaterial}
                            variant="contained"
                            text={"Cоздать материал"}
                        />}

                    </Box>

                </Box>}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}>
                    <Box sx={{
                        flexBasis: '50%',
                        maxWidth: '225px',
                        paddingTop: Object.keys(rowSelection).length !== 0 ? "108px" : "54px"
                    }}>
                        <MaterialTree
                            filterGroup={filterGroup}
                            setFilterGroup={setFilterGroup}
                            changeFilterGroup={changeFilterGroup}
                            setChangeFilterGroup={setChangeFilterGroup}
                            data={dataTree}
                            groupMap={groupMap}
                            currentGroup={currentGroup}
                            setCurrentGroup={setCurrentGroup}
                        />
                    </Box>
                    <Box sx={{
                        flexBasis: 'auto',
                        marginLeft: '16px',
                        overflow: 'auto',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px'
                    }}>
                        <MaterialTable
                            currentData={currentData}
                            refMaterial={refMaterial}
                            pageSize={pageSize}
                            addMaterialInArrival={addMaterialInArrival}
                            enableEditing={enableEditing}
                            isSelectInArrival={isSelectInArrival}
                            isSelectInEstimate={isSelectInEstimate}
                            openModalAddSignJob={openModalAddSignJob}
                            setOpenModalAddSignJob={setOpenModalAddSignJob}
                            handleCloseModalAddSignJob={handleCloseModalAddSignJob}
                            handleSubmitAddSignJobInMaterial={handleSubmitAddSignJobInMaterial}
                            groupNames={groupNames}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                            rowIndexSelection={rowIndexSelection}
                            setRowIndexSelection={setRowIndexSelection}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            disabledAddMaterial={disabledAddMaterial}
                            dataSignJob={dataSignJob}
                            setDataSignJob={setDataSignJob}
                            change={change}
                            setChange={setChange}
                            selectMaterialId={selectMaterialId}
                            selectMaterial={selectMaterial}
                            tableOptionsHandle={tableOptionsHandle}
                            tableOptions={tableOptions}
                            modal={modal}
                            allMaterial={allMaterial}
                            enableRowSelection={isAdmin}
                            sx={{
                                borderBottomLeftRadius: '0px',
                                borderBottomRightRadius: '0px',
                                maxWidth: '100%',
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {Object.keys(rowSelection).length !== 0 && <Paper sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: '100%',
                height: "88px",
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.primary.lightGrey,
            }} component="footer">
                <ButtonSelectAction text={"Выбрать действие"} onClick={() => setOpen(true)}/>
            </Paper>}
            <ModalSelectAction
                open={open}
                reset={() => setOpen(false)}
                submit={handleSelectAction}
                modalActions={modalActions}
                dataTree={dataTree}
            />
            <ModalAddSignJob
                title={"Добавить работы на группу " + groupNames}
                open={openModalAddSignJob}
                handleClose={handleCloseModalAddSignJob}
                handleSubmit={handleSubmitAddSignJobInMaterial}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                rowIndexSelection={rowIndexSelection}
                setRowIndexSelection={setRowIndexSelection}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                data={dataSignJob}
                setData={setDataSignJob}
                change={change}
                setChange={setChange}
            />

        </>
    );
};

export default MaterialPageItemModule;
