import axios from "axios";
import {apiVersionAuthorizationServer, utility} from "../../Constants";

export default class UtilityService {

    static async getById(id) {
        return axios.get(`${utility}${apiVersionAuthorizationServer}/banners/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}