import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import ButtonAdd from "../button/ButtonAdd";
import ButtonCancel from "../button/ButtonCancel";
import React, {useEffect, useState} from "react";
import MaterialTree from "../tree/MaterialTree";
import TextFieldValidation from "../table/customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import UserJobMetricPackingMaterial from "../../../API/material-cloud/user/UserJobMetricPackingMaterial";


export const ModalSelectAction = ({open, reset, submit, modalActions, dataTree, handleError, materialId}) => {
    const [selectedType, setSelectedType] = useState(modalActions[0].type)
    const [currentGroup, setCurrentGroup] = useState(null)
    const [packings, setPackings] = useState([])
    const [formState, setFormState] = useState({packing: ""})
    useEffect(() => {
        materialId && UserJobMetricPackingMaterial.getById(materialId)
            .then(function (response) {
                setPackings(response.data)
                setFormState({packing: response.data[0]?.id})
            })
            .catch(function (error) {
                handleError(error)
            })
    }, [])
    const handleCancel = () => {
        setCurrentGroup(null)
        setSelectedType(modalActions[0].type)
        reset()
    }
    const handleAdd = () => {
        setCurrentGroup(null)
        setSelectedType(modalActions[0].type)
        selectedType === "addPackaging" ? submit(selectedType, formState.packing) : submit(selectedType, currentGroup)
    }
    return <Dialog open={open} PaperProps={{
        style: {
            width: "400px",
            borderRadius: 8,
            padding: 16,
            paddingTop: 0,
            boxSizing: "border-box"
        }
    }}>
        <DialogContent style={{display: "flex", flexDirection: "column", padding: "24px 0"}}>
            <RadioGroup
                defaultValue="female"
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                value={selectedType}
                onChange={(event) => setSelectedType(event.target.value)}
            >
                {modalActions.map((option) => (
                        <FormControlLabel
                            key={option.type}
                            value={option.type}
                            control={<Radio/>}
                            label={option.name}
                            sx={{
                                margin: 0,
                                height: "36px",
                                "&:not(:last-of-type)": {
                                    marginBottom: "4px",
                                },
                                borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light
                                }
                            }
                            }
                        />
                    )
                )}
            </RadioGroup>
            {selectedType === "setGroup" && <MaterialTree
                data={dataTree}
                setCurrentGroup={setCurrentGroup}
                readOnly={true}
            />}
            {selectedType === "addPackaging" && <TextFieldValidation
                select
                name="packing"
                label="Упаковка"
                value={formState.packing}
                setCurrValues={setFormState}
            >
                {packings.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextFieldValidation>}
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "space-between", padding: 0}}>
            <ButtonCancel text={"Отменить"} onClick={handleCancel}/>
            <ButtonAdd text={"Применить"} disabled={!currentGroup && selectedType === "setGroup"} width={176}
                       height={56} onClick={handleAdd}/>
        </DialogActions>
    </Dialog>
}